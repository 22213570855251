const processAttributes = (attributes) => {
  const attrObj = {};
  for (let i = 0; i < attributes.length; i++) {
    const attribute = attributes.item(i);
    attrObj[attribute.nodeName] = attribute.nodeValue;
  }
  return attrObj;
};

const processChildNodes = (childNodes) => {
  const result = {};
  for (let i = 0; i < childNodes.length; i++) {
    const item = childNodes.item(i);
    const nodeName = item.nodeName;

    if (!result[nodeName]) {
      result[nodeName] =
        item.childNodes?.length && item.childNodes[0].nodeName === "#text"
          ? item.childNodes[0].nodeValue
          : convertXmlToJson(item);
    } else {
      if (!Array.isArray(result[nodeName])) {
        result[nodeName] = [result[nodeName]];
      }
      result[nodeName].push(convertXmlToJson(item));
    }
  }
  return result;
};

const convertXmlToJson = (xml) => {
  const obj = {};
  if (xml.nodeType === 1 && xml.attributes.length > 0) {
    Object.assign(obj, processAttributes(xml.attributes));
  }

  if (xml.hasChildNodes()) {
    Object.assign(obj, processChildNodes(xml.childNodes));
  }

  return obj;
};

export default convertXmlToJson;
