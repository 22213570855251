import "./not-found.css";

const NotFoundView = () => {
  const images = "https://public.siaflite.com.br/notfoundview.svg";

  return (
    <body id="not-found">
      <a href="/dashboard" id="button-not-found">
        <i className="ph-fill ph-arrow-circle-left"></i> Voltar
      </a>
      <div id="container-not-found">
        <img src={images} alt="Página não encontrada" />
      </div>
    </body>
  );
};

export default NotFoundView;
