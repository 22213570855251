import { format } from "date-fns";
import { TablePrintResumidoComponent } from "../../../../components";
import { formatDate, formatNomes, formatPrice } from "../../../../utils";

const PrintCustoMercadoriaVendida = ({
  tituloRelatorio,
  relatoriosTotais,
  filtrosAplicadosImpressao,
  print,
}) => {
  const columns = [
    {
      header: "Cod.",
      field: "codigo",
    },
    {
      header: "Produto",
      field: "descricao",
      width: "50%",
      render: ({ descricao }) =>
        descricao && formatNomes(descricao.substring(0, 50)),
    },
    {
      header: "Data Venda",
      align: "center",
      hide: Object.keys(filtrosAplicadosImpressao || {}).some((key) => {
        if (key) {
          return key.toLowerCase().includes("agrupar");
        }
      }),
      render: ({ data }) => format(formatDate.received(data), "dd/MM/yyyy"),
    },
    {
      header: "Quantidade",
      field: "quantidadeVendida",
      fieldTotal: "totalQuantidadeVendida",
      align: "center",
    },
    {
      header: "Preço Custo",
      field: "precoCusto",
      render: ({ precoCusto }) => formatPrice(precoCusto, false),
      align: "end",
      fieldTotal: "totalCusto",
    },
    {
      header: "Custo Merc. Vend.",
      field: "cmv",
      render: ({ cmv }) => formatPrice(cmv, false),
      fieldTotal: "totalCmv",
      align: "end",
    },
  ];

  return (
    <TablePrintResumidoComponent
      filtrosAplicadosImpressao={filtrosAplicadosImpressao}
      relatoriosTotais={relatoriosTotais.movimentacao}
      totais={relatoriosTotais.totais}
      tituloRelatorio={tituloRelatorio}
      print={print}
      columns={columns}
      itensPorPagina={31}
    />
  );
};

export default PrintCustoMercadoriaVendida;
