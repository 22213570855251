import { useState } from "react";
import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Loading,
  MaskedInput,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "../../components";
import { InputErros, value } from "../../helpers";
import {
  CadastrosService,
  EnderecoService,
  history,
  notification,
} from "../../services";
import { cadastrarEmpresaValidator } from "./middlewares";
import PasswordStrengthBar from "react-password-strength-bar";
import "./cadastro-style.scss";
import { responseErros, encrypt } from "../../utils";
import { CadastroEmpresa, estados } from "./entities";

const CadastrarEmpresaView = () => {
  const [loading, setLoading] = useState(false);
  const [empresa, setEmpresa] = useState((prevState) => ({
    ...prevState,
    ativado: true,
    inscricaoEstadualIsento: false,
    contadorAutorizarDadosXmlNfe: false,
    contadorAutorizarDownloadXmlNfe: false,
    suspenderAtividade: 1,
    producao: true,
    imagemLogo: null,
    naturezaSocial: "juridica",
    regimeTributario: "SimplesNacional",
  }));
  const [uf, setUf] = useState(null);
  const [cidade, setCidade] = useState(null);
  const [cidades, setCidades] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [senhaScore, setSenhaScore] = useState();
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const cadastrosService = new CadastrosService();
  const enderecoService = new EnderecoService();
  const images = {
    logo: require("../../assets/images/icon-lite.svg"),
    line: require("../../assets/images/line.svg"),
    lineElipse: require("../../assets/images/line-elipse.svg"),
  };

  const handleInputChange = (e) => {
    setInputErro([]);
    const empresaTemp = {
      ...empresa,
      [e.target.name]: e.target.value,
    };
    setEmpresa(empresaTemp);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleCompletarEndereco = async (e) => {
    e.preventDefault();
    const data = await enderecoService.buscarEndereco(empresa?.enderecoCep);
    if (!data) {
      return notification.alertaGenericos("CEP não encontrado.");
    }
    handleEstadoUf(
      estados.find((est) => est.sigla === data.uf),
      data?.cidade
    );
    setEmpresa((prevState) => ({
      ...prevState,
      cidade: data?.cidade,
      enderecoBairro: data?.bairro,
      enderecoLogradouro: data?.logradouro,
    }));
  };

  const handleUserInputChange = (e, name) => {
    const value = e.target.value;
    const usuarioTemp = {
      ...empresa,
      [e.target.name]: value,
    };
    if (e.target.name == "senha" || e.target.name == "confirmarSenha") {
      usuarioTemp[name] = String(value).trim();
    }
    setEmpresa(usuarioTemp);
  };

  const handleUserInputBlur = async (event) => {
    event.persist();
    const valueNomeUser = document.getElementById("nomeUsuario")?.value;
    const value = event.target.value;
    const usuarioTemp = {
      ...empresa,
      [event.target.name]: value,
    };
    if (usuarioTemp.fantasia && valueNomeUser) {
      const valueFantasia = usuarioTemp.fantasia
        .replace(/\s+/g, "")
        .toLowerCase();
      const valueNome = valueNomeUser.replace(/\s+/g, "").toLowerCase();
      const valorFormated = `${valueNome}@${valueFantasia}.com.br`;
      const teste = await buscarUsuarioCadastrado(valorFormated);
      usuarioTemp.emailUsuario = !teste ? valorFormated : "";
    }
    setEmpresa(usuarioTemp);
  };

  const handleEstadoUf = async (estado, nomeCidade) => {
    setCidades([]);
    setCidade(null);
    setUf(estado);
    await buscarCidades(estado.id, nomeCidade);
  };

  const handleCidade = (cidade) => {
    setCidade(cidade);
    setEmpresa((prevState) => ({
      ...prevState,
      cidade: cidade?.nome,
    }));
  };

  const buscarCidades = async (ufId, nomeCidade) => {
    cadastrosService.getCidades(ufId).then((result) => {
      if (!result.isAxiosError) {
        setCidades(result.data);
        if (nomeCidade) {
          handleCidade(
            result.data.find((cidade) => cidade.nome === nomeCidade)
          );
        }
      } else {
        responseErros(result);
      }
    });
  };

  const buscarCnpjCadastrado = async (cnpj) => {
    if (cnpj && cnpj?.length === 14) {
      const result = await cadastrosService.validarEmpresaCadastrada(cnpj);
      if (!result.isAxiosError) {
        if (result.data) {
          inputErros.gerar("cnpjCpf");
          notification.alertaGenericos("Cnpj já cadastrado");
          setEmpresa((prevState) => ({
            ...prevState,
            cnpjCpf: "",
          }));
        } else inputErros.set(null);
      } else responseErros(result);
    }
  };

  const buscarUsuarioCadastrado = async (emailUsuario) => {
    if (emailUsuario && emailUsuario?.length > 8) {
      const result = await cadastrosService.validarUsuarioCadastrado(
        emailUsuario
      );
      if (!result.isAxiosError) {
        if (result.data) {
          inputErros.gerar("emailUsuario");
          notification.alertaGenericos("Usuário já cadastrado");
          setEmpresa((prevState) => ({
            ...prevState,
            emailUsuario: "",
          }));
          return true;
        } else {
          inputErros.set(null);
        }
      } else responseErros(result);
    }
  };

  const validarSenha = (cadastroEmpresa, empresa) => {
    const isStrong = cadastroEmpresa?.verificarSenhaForte(empresa, senhaScore);
    if (!isStrong) {
      inputErros.gerar("senha");
      notification.alertaGenericos("Senha fraca");
    }
    return isStrong;
  };

  const handleSubmit = () => {
    const body = {
      ...empresa,
      usuarioPadrao: {
        nome: empresa.nomeUsuario,
        email: empresa.emailUsuario,
        senha: empresa.senha,
        confirmarSenha: empresa.confirmarSenha,
      },
    };
    const cadastroEmpresa = new CadastroEmpresa(body);
    const isPasswordValid = validarSenha(cadastroEmpresa, body);
    if (!isPasswordValid) return;
    cadastrarEmpresaValidator
      .validate(body, { abortEarly: false })
      .then(async () => {
        inputErros.set(null);
        setLoading(true);
        const result = await cadastrosService.cadastrarEmpresa({
          ...cadastroEmpresa,
          usuarioPadrao: {
            ...body.usuarioPadrao,
            senha: encrypt(body.usuarioPadrao?.senha),
            confirmarSenha: encrypt(body.usuarioPadrao?.confirmarSenha),
          },
        });
        if (!result.isAxiosError) {
          notification.cadastroSucesso();
          history.push("/login");
        } else {
          responseErros(result);
        }
        setLoading(false);
      })
      .catch((err) => {
        inputErros.set(err);
      });
  };

  return (
    <Grid container className="bg-cadastro-empresa">
      <img className="position-fixed line" src={images.line.default} />
      <img
        className="position-fixed line-elipse"
        src={images.lineElipse.default}
      />
      <Container container className="h-100-vh p-3 zindex-modal">
        <Card className="p-2 h-100">
          <CardHeader
            title="Cadastro de Empresa"
            action={<img src={images.logo.default} width={"25px"} />}
          />
          <CardContent
            style={{
              overflowY: "scroll",
              height: "calc(100vh - 166px)",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <Select
                  label="Natureza Social"
                  name="naturezaSocial"
                  id="naturezaSocial"
                  variant="outlined"
                  margin="normal"
                  onChange={handleInputChange}
                  value={empresa?.naturezaSocial}
                  InputLabelProps={{ shrink: true }}
                  error={inputErros.get("naturezaSocial")}
                  required
                >
                  <MenuItem value={"fisica"}>Física</MenuItem>
                  <MenuItem value={"juridica"}>Jurídica</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  id="fantasia"
                  name="fantasia"
                  label="Nome Fantasia"
                  variant="outlined"
                  margin="normal"
                  value={empresa?.fantasia || ""}
                  onChange={handleInputChange}
                  onBlur={handleUserInputBlur}
                  fullWidth
                  required
                  error={inputErros.get("fantasia")}
                />
              </Grid>
              <Grid item xs={7}>
                <TextField
                  id="razao-social"
                  name="razaoSocial"
                  label="Razão Social"
                  variant="outlined"
                  margin="normal"
                  value={empresa?.razaoSocial || ""}
                  onChange={handleInputChange}
                  fullWidth
                  required
                  error={inputErros.get("razaoSocial")}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id="incricao-estadual"
                  name="inscricaoEstadual"
                  label={
                    empresa.naturezaSocial === "juridica"
                      ? "Inscrição Estadual (IE)"
                      : "Registro Geral (RG)"
                  }
                  variant={
                    empresa?.inscricaoEstadualIsento ? "filled" : "outlined"
                  }
                  margin="normal"
                  value={
                    empresa?.inscricaoEstadual &&
                    !empresa?.inscricaoEstadualIsento
                      ? empresa.inscricaoEstadual
                      : ""
                  }
                  onChange={handleInputChange}
                  fullWidth
                  required={!empresa?.inscricaoEstadualIsento}
                  disabled={empresa?.inscricaoEstadualIsento}
                  error={inputErros.get("inscricaoEstadual")}
                />
              </Grid>
              <Grid item xs={3}>
                <MaskedInput
                  id="cnpjCpf"
                  label={empresa.naturezaSocial === "juridica" ? "CNPJ" : "CPF"}
                  name="cnpjCpf"
                  variant="outlined"
                  margin="normal"
                  mask={
                    empresa.naturezaSocial === "juridica"
                      ? "99.999.999/9999-99"
                      : "999.999.999-99"
                  }
                  onChange={handleInputChange}
                  value={empresa?.cnpjCpf || ""}
                  onBlur={() => buscarCnpjCadastrado(empresa?.cnpjCpf)}
                  required
                  error={inputErros.get("cnpjCpf")}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="cnae"
                  name="cnae"
                  label="CNAE Principal"
                  variant="outlined"
                  margin="normal"
                  value={empresa?.cnae || ""}
                  onChange={handleInputChange}
                  InputProps={{
                    inputProps: { min: 0, maxLength: 7 },
                  }}
                  fullWidth
                  required
                  error={inputErros.get("cnae")}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="email"
                  name="email"
                  label="E-mail"
                  variant="outlined"
                  margin="normal"
                  value={empresa?.email || ""}
                  onChange={handleInputChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <MaskedInput
                  id="celular"
                  name="celular"
                  label="Celular"
                  mask="(99) 9 9999-9999"
                  value={empresa?.celular || ""}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <Select
                  id="regime-tributario"
                  name="regimeTributario"
                  label="Regime Tributário"
                  margin="normal"
                  value={empresa?.regimeTributario || "mei"}
                  onChange={handleInputChange}
                  required
                >
                  <MenuItem value={"SimplesNacional"}>
                    Simples Nacional
                  </MenuItem>
                  <MenuItem value={"SimplesNacionalExcessoSublimite"}>
                    Simples Nacional Excesso Sublimite
                  </MenuItem>
                  <MenuItem value={"RegimeNormal"}>Regime Normal</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <hr className="my-4" />
            <Grid item xs={12} className="mb-4">
              <span className="fw-bold fw-bolder title-color">ENDEREÇOS</span>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <form onSubmit={(e) => handleCompletarEndereco(e)}>
                  <MaskedInput
                    id="cep"
                    name="enderecoCep"
                    label="CEP"
                    mask="99999-999"
                    variant="outlined"
                    value={empresa?.enderecoCep || ""}
                    onChange={handleInputChange}
                    required
                    error={inputErros.get("enderecoCep")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Pesquisar CEP"
                            onClick={handleCompletarEndereco}
                          >
                            <i
                              className="ph-bold ph-magnifying-glass"
                              style={{ color: "#494C62", fontSize: 18 }}
                            ></i>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </form>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  id="logradouro"
                  name="enderecoLogradouro"
                  label="Logradouro"
                  variant="outlined"
                  margin="normal"
                  value={empresa?.enderecoLogradouro || ""}
                  onChange={handleInputChange}
                  fullWidth
                  required
                  error={inputErros.get("enderecoLogradouro")}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <TextField
                  id="numero"
                  name="enderecoLogradouroNumero"
                  label="Número"
                  variant="outlined"
                  margin="normal"
                  value={empresa?.enderecoLogradouroNumero || ""}
                  onChange={handleInputChange}
                  fullWidth
                  required
                  error={inputErros.get("enderecoLogradouroNumero")}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="bairro"
                  name="enderecoBairro"
                  label="Bairro"
                  variant="outlined"
                  margin="normal"
                  value={empresa?.enderecoBairro || ""}
                  onChange={handleInputChange}
                  fullWidth
                  required
                  error={inputErros.get("enderecoBairro")}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="complemento"
                  name="enderecoComplemento"
                  label="Complemento"
                  variant="outlined"
                  margin="normal"
                  value={empresa?.enderecoComplemento || ""}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormControl variant="outlined" margin="normal" fullWidth>
                  <Autocomplete
                    required
                    id="estado"
                    options={estados}
                    noOptionsText="Sem opções"
                    autoHighlight
                    getOptionLabel={(option) => option?.nome || ""}
                    renderInput={(params) => (
                      <TextField {...params} label="UF" variant="outlined" />
                    )}
                    onChange={(_event, newValue) => {
                      handleEstadoUf(newValue);
                    }}
                    value={estados ? value.autoComplete(estados, uf?.id) : ""}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={5}>
                <FormControl variant="outlined" margin="normal" fullWidth>
                  <Autocomplete
                    required
                    id="cidadeEmpresa"
                    name="cidadeEmpresa"
                    options={cidades}
                    noOptionsText="Insira um estado"
                    autoHighlight
                    getOptionLabel={(option) => option?.nome || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cidade"
                        variant="outlined"
                        required
                      />
                    )}
                    onChange={(_event, newValue) => {
                      handleCidade(newValue);
                    }}
                    value={cidade}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <hr className="my-4" />
            <Grid item xs={12} className="mb-4">
              <span className="fw-bold fw-bolder title-color">USUÁRIO</span>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <TextField
                  id="nomeUsuario"
                  name="nomeUsuario"
                  label="Nome"
                  variant="outlined"
                  value={empresa?.nomeUsuario || ""}
                  onChange={handleUserInputChange}
                  onBlur={handleUserInputBlur}
                  fullWidth
                  required
                  error={inputErros.get("nome")}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  id="emailUsuario"
                  name="emailUsuario"
                  label="E-mail"
                  variant="outlined"
                  value={empresa?.emailUsuario}
                  onChange={(e) => handleUserInputChange(e, "emailUsuario")}
                  onBlur={() => buscarUsuarioCadastrado(empresa?.emailUsuario)}
                  fullWidth
                  required
                  error={inputErros.get("emailUsuario")}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  id="senha"
                  name="senha"
                  label="Senha"
                  variant="outlined"
                  margin="normal"
                  onChange={(e) => handleUserInputChange(e, "senha")}
                  fullWidth
                  required
                  autoComplete="new-password"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickShowPassword}>
                          {showPassword ? (
                            <i
                              className="ph-fill ph-eye-slash"
                              style={{ color: "#494C62", fontSize: 18 }}
                            ></i>
                          ) : (
                            <i
                              className="ph-fill ph-eye"
                              style={{ color: "#494C62", fontSize: 18 }}
                            ></i>
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={empresa?.senha || ""}
                  error={inputErros.get("senha")}
                />
                {senhaScore < 2 ? (
                  <Typography className="passwordErrorInput">
                    Deve ter de 6 a 20 caracteres e no mínimo razoável
                  </Typography>
                ) : (
                  ""
                )}
                <PasswordStrengthBar
                  scoreWords={[
                    "senha fraca",
                    "senha fraca",
                    "senha razoável",
                    "senha boa",
                    "senha forte",
                  ]}
                  shortScoreWord={"senha curta"}
                  password={empresa?.senha}
                  onChangeScore={(score) => {
                    setSenhaScore(score);
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  id="confirmarSenha"
                  name="confirmarSenha"
                  label="Confirmar senha"
                  variant="outlined"
                  margin="normal"
                  onChange={(e) => handleUserInputChange(e, "confirmarSenha")}
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  autocomplete="new-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickShowPassword}>
                          {showPassword ? (
                            <i
                              className="ph-fill ph-eye-slash"
                              style={{ color: "#494C62", fontSize: 18 }}
                            ></i>
                          ) : (
                            <i
                              className="ph-fill ph-eye"
                              style={{ color: "#494C62", fontSize: 18 }}
                            ></i>
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={empresa?.confirmarSenha || ""}
                  error={inputErros.get("confirmarSenha")}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              onClick={() => handleSubmit()}
              variant="contained"
              color="primary"
            >
              Cadastrar
            </Button>
          </CardActions>
        </Card>
        <Loading loading={loading} />
      </Container>
    </Grid>
  );
};

export default CadastrarEmpresaView;
