import { formatCep } from "../../utils";

export default function RenderEnderecoPrint({ enderecoCliente }) {
  if (!enderecoCliente || Object.keys(enderecoCliente).length === 0) {
    return "Não Informado";
  }

  const { logradouro, numero, cidade, cep } = enderecoCliente;
  const enderecoFormatado = [
    logradouro ? `${logradouro},` : "",
    numero ? `${numero},` : "",
    cidade || "",
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <>
      {enderecoFormatado}
      <br />
      <b className="me-2 fs-7">CEP:</b>
      <span className="fs-7 fw-normal">
        {formatCep(String(cep ?? "Não Informado"))}
      </span>
    </>
  );
}
