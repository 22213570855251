import { format } from "date-fns";
import { TablePrintResumidoComponent } from "../../../../components";
import { formatNomes } from "../../../../utils";

const PrintAniversario = ({
  tituloRelatorio,
  relatoriosTotais,
  filtrosAplicadosImpressao,
  print,
}) => {
  const columns = [
    {
      header: "Cod.",
      field: "codigo",
    },
    {
      header: "Nome",
      field: "nomeRazaoSocial",
      render: (value) =>
        value?.nomeRazaoSocial &&
        formatNomes(value.nomeRazaoSocial.substring(0, 50)),
    },
    {
      header: "Apelido",
      field: "apelidoFantasia",
      render: (value) =>
        value?.apelidoFantasia &&
        formatNomes(value.apelidoFantasia.substring(0, 50)),
    },
    {
      header: "Data Nascimento",
      field: "dataNascimento",
      align: "end",
      render: (value) =>
        value?.dataNascimento &&
        format(new Date(Date.parse(value.dataNascimento)), "dd/MM/yyyy"),
    },
  ];
  return (
    <TablePrintResumidoComponent
      filtrosAplicadosImpressao={filtrosAplicadosImpressao}
      relatoriosTotais={relatoriosTotais}
      tituloRelatorio={tituloRelatorio}
      print={print}
      columns={columns}
      itensPorPagina={31}
    />
  );
};

export default PrintAniversario;
