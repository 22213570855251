import { DatePicker, Grid } from "../../../../components";
import { formatDate } from "../../../../utils";
import { format } from "date-fns";

const FormFiltroAvancadoAniversario = ({
  filtrosAvancados,
  setFiltrosAvancados,
  filtrosAplicadosImpressao,
  setFiltrosAplicadosImpressao,
}) => {
  const valueDate = (valueName) => {
    return filtrosAvancados[valueName] ? filtrosAvancados[valueName] : null;
  };

  const onChangeDate = (id, date, name) => {
    const isValidDate = (date) => date instanceof Date && !isNaN(date);
    if (!date || !isValidDate(date)) {
      date = "";
    }
    if (
      date &&
      date.getDate() !== undefined &&
      date.getMonth() !== undefined &&
      date.getFullYear() > 2000
    ) {
      const filtroAvancado = {
        ...filtrosAvancados,
        [id]: formatDate.toSend(date),
      };
      const filtroImpressao = {
        ...filtrosAplicadosImpressao,
        [name]: format(date, "dd/MM/yyyy"),
      };
      setFiltrosAvancados(filtroAvancado);
      setFiltrosAplicadosImpressao(filtroImpressao);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DatePicker
            id="dataReferencia"
            name="Data"
            label="Mês"
            margin
            fullWidth
            format="MM"
            views={["month"]}
            value={valueDate("dataReferencia")}
            onChange={(date) => onChangeDate("dataReferencia", date, "Data")}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FormFiltroAvancadoAniversario;
