import { useState } from "react";
import shortid from "shortid";
import {
  Grid,
  Button,
  DialogContent,
  DialogActions,
  TextField,
  Autocomplete,
  DatePicker,
  DataGrid,
  Loading,
} from "../../../../components";
import { ProdutosService, notification } from "../../../../services";
import { InputErros, value } from "../../../../helpers";
import { formatPrice } from "../../../../utils";

const OrcamentoStepperContent = ({
  sendServer,
  activeStep,
  setActiveStep,
  handleImportarOrcamento,
  handleCancelarImportacao,
  handleDateChange,
  filtrosDialog,
  setFiltrosDialog,
  colunas,
  orcamentos,
  setOrcamentosSelecionados,
  itensSelecionados,
  setItensSelecionados,
  buscarOrcamentosAvancado,
  listaClientes,
  handleInputChange,
  itemSelectionModel,
  setItemSelectionModel,
  orcamentoSelectionModel,
  setOrcamentoSelectionModel,
}) => {
  const produtosService = new ProdutosService();
  const [loading, setLoading] = useState(false);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const [itensQuantidade, setItensQuantidade] = useState([]);

  const colunasItens = [
    {
      field: "produto",
      headerName: "Produto",
      flex: 500,
    },
    {
      field: "valorUnitario",
      headerName: "Valor (R$)",
      flex: 300,
      valueGetter: (params) => formatPrice(parseFloat(params.value)),
    },
    {
      field: "saldoExportar",
      headerName: "Qtd.",
      flex: 200,
      headerAlign: "start",
      align: "start",
      type: "number",
      editable: true,
      valueParser: (newValue, params) => {
        const maxValue = itensQuantidade?.length
          ? itensQuantidade.find((item) => item.id === params.row?.id)
              ?.saldoExportar
          : params.value;
        const parsedValue = parseFloat(newValue);
        if (parsedValue > maxValue) {
          return maxValue;
        } else return parsedValue;
      },
    },
    {
      field: "totalItem",
      headerName: "Total Ítem (R$)",
      flex: 300,
      valueGetter: (params) => {
        const total = params.row.valorUnitario * params.row.saldoExportar;
        return formatPrice(total);
      },
    },
  ];

  function filtrarOrcamentosSelecionados(orcamentosIds) {
    let orcamentoImportacao = null;
    let itensImportacao = [];
    const orcamentosModificados = orcamentos.filter((orcamento) => {
      if (orcamentosIds.indexOf(orcamento.id) > -1) {
        if (orcamentoImportacao === null) {
          orcamentoImportacao = {
            ...orcamento,
          };
        }
        for (const key in orcamento) {
          if (
            orcamentoImportacao[key] != orcamento[key] &&
            key != "id" &&
            key != "itens"
          ) {
            orcamento[key] = null;
          }
        }
        if (orcamentoImportacao?.clienteId == orcamento.clienteId) {
          itensImportacao.push(...orcamento.itens);
          return orcamento;
        } else {
          setActiveStep(-1);
          return notification.alertaGenericos(
            "Orçamentos devem ser do mesmo cliente"
          );
        }
      }
    });
    setOrcamentosSelecionados(orcamentosModificados);
    buscarRelacionamentoProdutos(itensImportacao);
  }

  const handleAvancarStep = () => {
    if (!orcamentoSelectionModel?.length)
      return notification.alertaGenericos(
        "Selecione ao menos um orçamento para importar"
      );
    filtrarOrcamentosSelecionados(orcamentoSelectionModel);
    setActiveStep(activeStep + 1);
  };

  const handleVoltarStep = () => {
    setActiveStep(activeStep - 1);
  };

  const buscarRelacionamentoProdutos = async (listaItens) => {
    setLoading(true);
    const idsProdutos = listaItens.map((item) => item.produtoId);
    const filtros = {
      id: idsProdutos.length > 0 ? idsProdutos : undefined,
    };
    const res = await produtosService.getAllFiltroAvancado(filtros);
    if (!res.isAxiosError) {
      if (res.data.rows.length) {
        const itensSelecionadosTemp = listaItens
          .filter((produto) => produto.saldoExportar > 0)
          .map((produto) => {
            const produtoEncontrado = res.data.rows.find(
              (item) => item.id == produto.produtoId
            );
            return {
              ...produto,
              ...produtoEncontrado,
              id: shortid.generate(),
              produto: produtoEncontrado?.descricao ?? "",
            };
          });
        setItemSelectionModel(itensSelecionadosTemp.map(({ id }) => id));
        setItensQuantidade(itensSelecionadosTemp);
        setItensSelecionados(itensSelecionadosTemp);
      }
    }
    setLoading(false);
  };

  const onChangeAutocomplete = (name, value) => {
    setFiltrosDialog({
      ...filtrosDialog,
      [name]: value ? value.id : undefined,
    });
  };

  const onCellKeyDown = (params, event) => {
    if (event.key === "Enter") {
      const value = Number(event.target.value);
      const itensTemp = itensSelecionados.map((item) => {
        if (params.row.id == item.id) {
          if (params.field == "saldoExportar")
            return {
              ...item,
              [params.field]: value ?? null,
              quantidade: value,
            };
        }
        return item;
      });
      setItensSelecionados(itensTemp);
    }
  };

  switch (activeStep) {
    case 1:
      return (
        <>
          <DialogContent>
            <DataGrid
              rows={itensSelecionados}
              columns={colunasItens}
              rowCount={itensSelecionados.length}
              checkboxSelection
              selectionModel={itemSelectionModel}
              onRowSelectionModelChange={(selectedIds) => {
                setItemSelectionModel(selectedIds);
              }}
              onCellKeyDown={onCellKeyDown}
              disableAcoes
            />
          </DialogContent>
          <DialogActions className="m-3">
            <Button
              onClick={handleCancelarImportacao}
              color="padrao"
              variant="contained"
            >
              Cancelar
            </Button>
            <Button
              onClick={handleVoltarStep}
              color="primary"
              variant="contained"
            >
              <i
                className="ph-fill ph-caret-left"
                style={{ color: "#fff", fontSize: 18 }}
              ></i>
              Anterior
            </Button>
            <Button
              onClick={handleImportarOrcamento}
              color="primary"
              variant="contained"
            >
              Importar
            </Button>
          </DialogActions>
          <Loading loading={loading} />
        </>
      );
    default:
      return (
        <>
          <DialogContent>
            <Grid container spacing={2} className="mt-1 mb-4">
              <Grid item xs={2}>
                <DatePicker
                  id="dataInicial"
                  name="dataInicial"
                  label="Data Inicial"
                  format="dd/MM/yyyy"
                  value={
                    filtrosDialog?.dataInicial
                      ? filtrosDialog.dataInicial
                      : null
                  }
                  onChange={(date) => handleDateChange(date, "dataInicial")}
                />
              </Grid>
              <Grid item xs={2}>
                <DatePicker
                  id="dataFinal"
                  name="dataFinal"
                  label="Data Final"
                  format="dd/MM/yyyy"
                  value={
                    filtrosDialog?.dataFinal ? filtrosDialog.dataFinal : null
                  }
                  onChange={(date) => handleDateChange(date, "dataFinal")}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  id="pessoaClienteId"
                  name="pessoaClienteId"
                  options={listaClientes}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.nomeRazaoSocial
                      ? `${option.id}` + ` - ` + `${option.nomeRazaoSocial}`
                      : ""
                  }
                  getOptionSelected={(option, value) =>
                    option?.nomeRazaoSocial === value?.nomeRazaoSocial
                  }
                  noOptionsText="Sem opções"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cliente"
                      variant={"outlined"}
                      error={inputErros.get("pessoaClienteId")}
                    />
                  )}
                  onChange={(e, value) =>
                    onChangeAutocomplete("pessoaClienteId", value)
                  }
                  value={value.autoComplete(
                    listaClientes,
                    filtrosDialog?.pessoaClienteId
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id="numeroDocumento"
                  name="numeroDocumento"
                  label="Nº do documento"
                  variant="outlined"
                  value={
                    filtrosDialog.numeroDocumento
                      ? filtrosDialog.numeroDocumento
                      : undefined
                  }
                  onChange={handleInputChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={2} className="d-flex align-items-center">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  sx={{ height: "51px" }}
                  onClick={() => buscarOrcamentosAvancado(filtrosDialog)}
                >
                  <i
                    className="ph-bold ph-magnifying-glass"
                    style={{ color: "#fff", fontSize: 18, marginRight: 10 }}
                  ></i>
                  Filtrar
                </Button>
              </Grid>
            </Grid>
            <DataGrid
              rows={orcamentos}
              columns={colunas}
              checkboxSelection
              selectionModel={orcamentoSelectionModel}
              onRowSelectionModelChange={(selectedIds) => {
                setOrcamentoSelectionModel(selectedIds);
              }}
              paginationMode={"server"}
              sendServer={sendServer}
              disableAcoes
              noFilter
            />
          </DialogContent>
          <DialogActions className="m-3">
            <Button
              onClick={handleCancelarImportacao}
              color="padrao"
              variant="contained"
            >
              Cancelar
            </Button>
            <Button
              onClick={() => handleAvancarStep()}
              color="primary"
              variant="contained"
            >
              Próximo
              <i
                className="ph-fill ph-caret-right"
                style={{ color: "#fff", fontSize: 18 }}
              ></i>
            </Button>
          </DialogActions>
          <Loading loading={loading} />
        </>
      );
  }
};

export default OrcamentoStepperContent;
