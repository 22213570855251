import { useEffect } from "react";
import {
  formatPrecisaoFloat,
  formatPrice,
  unformatPrice,
} from "../../../../utils";
import {
  CurrencyInput,
  Grid,
  TextFielDecimalNumbers,
  Typography,
} from "../../../../components/";
import {
  calculoDesconto,
  totalItensLista,
  totalOrcamento,
} from "../addOrcamentos";

const ResumoTotal = ({
  abaValue,
  orcamento,
  setOrcamento,
  listaOrcamentosProdutos,
}) => {
  useEffect(() => {
    setOrcamento((prevState) => ({
      ...prevState,
      descontoPorcentagem: calculoDesconto(
        listaOrcamentosProdutos,
        prevState,
        false
      ),
    }));
  }, [listaOrcamentosProdutos]);

  const handleInputChange = (event) => {
    const orcamentoTemp = {
      ...orcamento,
      [event.target.name]: unformatPrice(event.target.value) || null,
    };
    setOrcamento(orcamentoTemp);
  };

  const handleInputDesconto = (event) => {
    const propriedade = event.target.name;
    const valor = unformatPrice(event.target.value);
    const orcamentoTemp = {
      ...orcamento,
      [propriedade]: valor ? parseFloat(valor) : 0,
    };
    if (
      parseFloat((orcamento[propriedade] || 0).toFixed(2)) !==
      formatPrecisaoFloat(parseFloat(valor))
    ) {
      if (propriedade === "desconto") {
        orcamentoTemp.descontoPorcentagem = calculoDesconto(
          listaOrcamentosProdutos,
          orcamentoTemp,
          false
        );
        setOrcamento(orcamentoTemp);
      } else {
        orcamentoTemp.desconto = calculoDesconto(
          listaOrcamentosProdutos,
          orcamentoTemp
        );
        setOrcamento(orcamentoTemp);
      }
    }
  };

  const nextFocusInput = (event) => {
    if (event.keyCode === 13) {
      const { form } = event.target;
      const arrayInput = Array.from(form.elements).filter(
        (element) => element.tagName == "INPUT"
      );
      const index = arrayInput.indexOf(event.target);
      let element = arrayInput[index + 1];
      if (element?.disabled) {
        element = arrayInput[index + 2];
      }
      if (element) {
        element.focus();
      } else {
        arrayInput[index].blur();
      }
      event.preventDefault();
    }
  };

  return (
    <form>
      <Grid container spacing={2} justifyContent="center" className="mx-3">
        <Grid
          item
          xs={2}
          className="d-flex align-items-center justify-content-end"
        >
          <span className="me-2">Subtotal:</span>
          <span className="badge bg-primary bg-gradient bg-gradient text-wrap p-2">
            <Typography color="white" style={{ fontWeight: 700 }}>
              {formatPrice(totalItensLista(listaOrcamentosProdutos)) ||
                "R$ 0,00"}
            </Typography>
          </span>
        </Grid>
        <Grid
          item
          xs={4}
          className="d-flex align-items-center justify-content-end"
        >
          <span className="me-2">Desconto:</span>
          <TextFielDecimalNumbers
            id="descontoPorcentagem"
            name="descontoPorcentagem"
            disabled={
              orcamento.status === "CANCELADO" ||
              orcamento.status === "BAIXADO" ||
              abaValue !== 0
            }
            variant="outlined"
            margin="none"
            size="small"
            value={orcamento?.descontoPorcentagem ?? 0}
            onBlur={handleInputDesconto}
            onKeyDown={nextFocusInput}
          />
          <CurrencyInput
            id="desconto"
            name="desconto"
            disabled={
              orcamento.status === "CANCELADO" ||
              orcamento.status === "BAIXADO" ||
              abaValue !== 0
            }
            className="px-1"
            variant="outlined"
            margin="none"
            size="small"
            value={orcamento?.desconto}
            onBlur={handleInputDesconto}
            onKeyDown={nextFocusInput}
          />
        </Grid>
        <Grid
          item
          xs={2}
          className="d-flex align-items-center justify-content-end"
        >
          <span className="me-2">Acréscimo:</span>
          <CurrencyInput
            id="acrescimo"
            name="acrescimo"
            disabled={
              orcamento.status === "CANCELADO" ||
              orcamento.status === "BAIXADO" ||
              abaValue !== 0
            }
            variant="outlined"
            margin="none"
            size="small"
            value={orcamento?.acrescimo ?? 0}
            onBlur={handleInputChange}
            onKeyDown={nextFocusInput}
          />
        </Grid>
        <Grid
          item
          xs={2}
          className="d-flex align-items-center justify-content-end"
        >
          <span className="me-2">Despesas:</span>
          <CurrencyInput
            id="despesas"
            name="despesas"
            disabled={
              orcamento.status === "CANCELADO" ||
              orcamento.status === "BAIXADO" ||
              abaValue !== 0
            }
            variant="outlined"
            margin="none"
            value={orcamento?.despesas ?? 0}
            size="small"
            onBlur={handleInputChange}
            onKeyDown={nextFocusInput}
          />
        </Grid>
        <Grid item xs={2} className="d-flex align-items-center">
          <span className="me-2">Total:</span>
          <span className="badge bg-primary bg-gradient bg-gradient text-wrap p-2">
            <Typography color="white" style={{ fontWeight: 700 }}>
              {formatPrice(
                totalOrcamento(orcamento, listaOrcamentosProdutos)
              ) || "R$ 0,00"}
            </Typography>
          </span>
        </Grid>
      </Grid>
    </form>
  );
};

export default ResumoTotal;
