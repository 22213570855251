import {
  calcularPercentualComissaoVendedor,
  calcularPrecoCompra,
  calcularPrecoCusto,
  calcularValorComissaoVendedor,
  validarLucro,
  validarPrecoVenda,
} from "../addProduto";
import { ProdutoFornecedores } from "./index";

export class Produto {
  insertUserId = null;
  ativado = null;
  imagem = null;
  codigo = null;
  codigoBarra = null;
  grupoProdutoId = null;
  tipo = null;
  descricao = null;
  unidadeComercialCompraId = null;
  unidadeComercialVendaId = null;
  conversorEntrada = null;
  estoqueAtual = null;
  fracionavel = null;
  lerPesoPorBalanca = null;
  precoCompra = null;
  precoCusto = null;
  precoMedio = null;
  lucroDesejado = null;
  precoVenda = null;
  grupoTributacaoId = null;
  tipoItemId = null;
  ncmId = null;
  ncmCodigo = null;
  cestId = null;
  origemMercadoriaId = null;
  aliquotaMva = null;
  informacaoAdicionalNfe = null;
  informacaoComplementar = null;
  produtosSimilaresIds = null;
  fornecedores = null;
  produtosKitIds = null;
  camposPersonalizados = null;
  etiquetasProdutos = null;
  produtosGrade = null;
  gradeId = null;
  modeloGradeId = null;
  eixoX = null;
  eixoY = null;
  marcaId = null;
  colecaoId = null;

  constructor(produto) {
    this.insertUserId = produto.insertUserId;
    this.ativado = produto.ativado;
    this.imagem = produto.imagem;
    this.codigo = produto.codigo;
    this.codigoBarra = produto?.codigoBarra?.length
      ? produto.codigoBarra.replace(/[^\w\s]/gi, "")
      : null;
    this.grupoProdutoId = produto.grupoProdutoId;
    this.tipo = produto.tipo;
    this.descricao = produto.descricao;
    this.unidadeComercialCompraId = produto.unidadeComercialCompraId;
    this.unidadeComercialVendaId = produto.unidadeComercialVendaId;
    this.conversorEntrada = produto.conversorEntrada;
    this.estoqueAtual = produto.estoqueAtual;
    this.fracionavel = produto.fracionavel;
    this.lerPesoPorBalanca = produto.lerPesoPorBalanca;
    this.precoCompra = isNaN(produto.precoCompra) ? null : produto.precoCompra;
    this.precoCusto = isNaN(produto.precoCusto) ? null : produto.precoCusto;
    this.precoMedio = isNaN(produto.precoMedio)
      ? null
      : produto.precoMedio || 0;
    this.precoVenda = isNaN(produto.precoVenda) ? null : produto.precoVenda;
    this.lucroDesejado = produto.lucroDesejado;
    this.grupoTributacaoId = produto.grupoTributacaoId;
    this.tipoItemId = produto.tipoItemId;
    this.ncmId = produto.ncmId;
    this.ncmCodigo = produto.ncmCodigo;
    this.cestId = produto.cestId;
    this.marcaId = produto.marcaId;
    this.colecaoId = produto.colecaoId;
    this.origemMercadoriaId = produto.origemMercadoriaId;
    this.aliquotaMva = produto.aliquotaMva;
    this.etiquetasProdutos = produto.etiquetasProdutos;
    this.informacaoAdicionalNfe = produto.informacaoAdicionalNfe;
    this.informacaoComplementar = produto.informacaoComplementar;
    this.produtosSimilaresIds = produto.produtosSimilaresIds;
    this.fornecedores = produto.fornecedores?.length
      ? produto.fornecedores.map(
          (fornecedor) => new ProdutoFornecedores(fornecedor)
        )
      : [];
    this.produtosKitIds = produto.produtosKitIds;
    this.camposPersonalizados = produto.camposPersonalizados;
    this.gradeId = produto.gradeId;
    this.modeloGradeId = produto.modeloGradeId;
    this.produtosGrade = produto.produtosGrade?.length
      ? produto.produtosGrade.map((produtoGrade) => {
          const grade = new Produto({ ...this });
          grade.setGrade(produtoGrade);
          return grade;
        })
      : [];
  }

  setProdutoImagem(imagem) {
    this.imagem = imagem;
  }

  setProdutoTipo(tipo) {
    this.tipo = tipo;
  }

  setProdutosGrade(produtosGradeList) {
    this.produtosGrade = produtosGradeList?.length
      ? produtosGradeList.map((produtoGrade) => {
          const grade = new Produto({ ...this });
          grade.setGrade(produtoGrade);
          return grade;
        })
      : [];
  }

  setProdutoNcm(ncm) {
    this.ncmId = ncm.id;
    this.ncmCodigo = ncm.codigo;
  }

  setCodigoBarra(codigoBarra) {
    this.codigoBarra = codigoBarra.replace(/[^\w\s]/gi, "");
  }

  setProdutoGrupoTributacao(gruposTributacaoId) {
    this.grupoTributacaoId = gruposTributacaoId;
  }

  setProdutoEtiquetas(newValue) {
    this.etiquetasProdutos = newValue;
  }

  setProdutoEtiquetasIds(listEtiquetas = this.etiquetasProdutos) {
    if (listEtiquetas?.length) {
      this.etiquetasProdutos = listEtiquetas.map((item) => item.id);
    }
  }

  setProdutosKitIds(produtoKitId, quantidade) {
    if (this.produtosKitIds && this.produtosKitIds?.length) {
      this.produtosKitIds = [
        ...this.produtosKitIds,
        {
          idItemKit: produtoKitId,
          quantidade,
        },
      ];
    } else {
      this.produtosKitIds = [
        {
          idItemKit: produtoKitId,
          quantidade,
        },
      ];
    }
  }

  setFornecedores(fornecedoresList) {
    this.fornecedores = fornecedoresList?.length
      ? fornecedoresList.map(
          (fornecedor) => new ProdutoFornecedores(fornecedor)
        )
      : [];
  }

  setProdutosSimilaresIds(produtosSimilares) {
    this.produtosSimilaresIds = produtosSimilares.length
      ? produtosSimilares.map((produtoSimilar) => produtoSimilar.id)
      : [];
  }

  setGrade(grade) {
    this.descricao = grade.descricao;
    this.codigoBarra = grade.codigoBarra;
    this.tipo = grade.tipo;
    this.eixoX = grade.eixoX;
    this.eixoY = grade.eixoY;
  }

  setHandleChangeSwitch(name, checked) {
    this[name] = checked;
  }

  setHandleChangeInput(name, value) {
    this[name] = value;

    if (name === "conversorEntrada" && value === "") {
      this[name] = null;
    }
    if (name === "codigoBarra" && value !== "") {
      this[name] = value.replace(/[^\w\s]/gi, "");
    }
  }

  setHandleChangeInputVenda = (name, value, id, configuracoes) => {
    let produtoTemp = { ...this };
    switch (name) {
      case "precoVenda":
        produtoTemp.precoVenda = value;
        Object.assign(
          produtoTemp,
          validarPrecoVenda(produtoTemp, configuracoes, this)
        );
        break;

      case "lucroDesejado":
        produtoTemp.lucroDesejado = value;
        produtoTemp = validarLucro(produtoTemp, configuracoes);
        break;

      case "precoCompra":
        produtoTemp = calcularPrecoCompra(produtoTemp, value);
        break;

      case "precoCusto":
        produtoTemp = calcularPrecoCusto(produtoTemp, value, configuracoes);
        break;

      case "valorComissaoVendedor":
        produtoTemp = calcularPercentualComissaoVendedor(produtoTemp, value);
        break;

      case "percentualComissaoVendedor":
        produtoTemp = calcularValorComissaoVendedor(produtoTemp, value);
        break;

      default:
        produtoTemp[name] = value;
        break;
    }

    if (name === "precoVenda" || name === "lucroDesejado") {
      if (id && produtoTemp.precoVenda < produtoTemp.precoCusto) {
        produtoTemp.lucroDesejado = undefined;
      }
    }

    if (name === "precoCusto") {
      if (
        parseFloat(produtoTemp.precoCusto || 0) <
        parseFloat(produtoTemp.precoCompra)
      ) {
        produtoTemp.precoCusto = produtoTemp.precoCompra;
        if (produtoTemp.precoCusto) {
          produtoTemp.precoVenda = validarLucro(produtoTemp, configuracoes);
        }
      }
    }
    Object.assign(this, produtoTemp);
  };

  setHandleChangeAutoComplete(name, value, tabelaCest) {
    if (name == "ncmId") {
      if (tabelaCest && tabelaCest?.length) {
        this.cestId = tabelaCest[0]?.id;
      }
      this.ncmCodigo = value?.codigo;
    }
    this[name] = value ? value.id : null;
  }
}
