import { useState, useEffect, useRef } from "react";
import {
  CardContent,
  Grid,
  Paper,
  Tabs,
  Tab,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Autocomplete,
  TabPanel,
  MaskedInput,
  CurrencyInput,
  DatePicker,
  ModalPrecificacaoNfEntrada,
} from "../../../components";
import { AddLayout } from "../../../layouts";
import {
  notification,
  NfEntradaService,
  PessoaService,
  OperacaoFiscalService,
  MeioPagamentoService,
  CondicoesPagamentoService,
  CfopService,
  SessionService,
} from "../../../services";
import { InputErros, value, PermisoesHelper } from "../../../helpers";
import {
  responseErros,
  formatDate,
  userLogs,
  formatPrice,
  voltar,
  timeoutBuscaAutocomplete,
} from "../../../utils";
import nfEntradaValidator from "./middlewares/nf-entrada.validator";
import {
  NfEntradaItens,
  NfEntradaTributos,
  NfEntradaPagamentos,
  NfEntradaInformacoes,
  NfEntradaFrete,
  MenuAction,
  ResumoTotal,
} from "./components";
import { tabProps, totalCompra } from "./add-nf-entrada";
import { useImportContext } from "../../../contexts/import.context";
import { NfEntrada } from "../entities/nf-entrada.entity";
import { constantes } from "./resources";

const nfEntradaService = new NfEntradaService();
const pessoaService = new PessoaService();
const operacaoFiscalService = new OperacaoFiscalService();
const meioPagamentoService = new MeioPagamentoService();
const condicoesPagamentoService = new CondicoesPagamentoService();
const cfopService = new CfopService();

const AddNfEntrada = ({ match }) => {
  const permissoesHelper = new PermisoesHelper();
  const sessionService = new SessionService();
  permissoesHelper.validarPermisao("nf-entrada-visualizar");
  const { nfEntradaDto, setNfEntradaDto, initialCollapses } =
    useImportContext();
  const { id } = match.params;
  const inputRef = useRef();
  const dadosEmpresa = sessionService.getEmpresa();
  const [loading, setLoading] = useState(false);
  const [loadingAutoCompleteFornecedor, setLoadingAutoCompleteFornecedor] =
    useState(false);
  const [abaValue, setAbaValue] = useState(0);
  const [userLog, setUserLog] = useState({});
  const [nfEntrada, setNfEntrada] = useState({
    dataChegada: formatDate.toSend(new Date()),
    modalidadeFrete: "mfSemFrete",
  });
  const nfEntradaValidatorService = nfEntradaValidator();
  const [frete, setFrete] = useState(null);
  const [fornecedorList, setFornecedorList] = useState([]);
  const [transportadoraList, setTransportadoraList] = useState([]);
  const [operacaoFiscalList, setOperacaoFiscalList] = useState([]);
  const [meioPagamentoList, setMeioPagamentoList] = useState([]);
  const [meioPagamentoSelecionado, setMeioPagamentoSelecionado] = useState({});
  const [condicaoPagamentoList, setCondicaoPagamentoList] = useState([]);
  const [nfEntradaItens, setNfEntradaItens] = useState([]);
  const [initialNfEntradaItensState, setInitialNfEntradaItensState] =
    useState(null);
  const [nfEntradaTributos, setNfEntradaTributos] = useState({});
  const [nfEntradaPagamentos, setNfEntradaPagamentos] = useState([]);
  const [configuracoes, setConfiguracoes] = useState(null);
  const [openPrecificacaoItens, setOpenPrecificacaoItens] = useState(false);
  const [gerarPagamentos, setGerarPagamentos] = useState({});
  const [resumoTotalizadores, setResumoTotalizadores] = useState({});
  const [unidadeList, setUnidadeList] = useState([]);
  const [cfop, setCfop] = useState(null);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);

  async function buscarDadosIniciais() {
    await buscarNfEntrada();
    buscarConfiguracao();
    buscarMeiosPagamento();
    buscarCondicoesPagamento();
    buscarOperacoesFiscais();
  }

  async function buscarDadosIniciaisDto() {
    if (
      !id &&
      (nfEntradaDto.documentoImportado || nfEntradaDto.usoConsumoImportado)
    ) {
      buscarFornecedores({ id: nfEntradaDto.fornecedorId });
      if (nfEntradaDto.transportadoraId) {
        buscarTransportadora({
          id: nfEntradaDto.transportadoraId,
        });
      }
      buscarOperacoesFiscais(nfEntradaDto.operacaoFiscalId);
      const dadosNfEntradaDto = new NfEntrada(nfEntradaDto);
      setNfEntrada(dadosNfEntradaDto);
      setNfEntradaItens(dadosNfEntradaDto.nfEntradaItens);
      setNfEntradaPagamentos(dadosNfEntradaDto.nfEntradaPagamentos);
      setNfEntradaTributos(dadosNfEntradaDto.nfEntradaTributos);
      setFrete(dadosNfEntradaDto.frete);
      setGerarPagamentos({
        valorTitulo:
          dadosNfEntradaDto.totalCompra - dadosNfEntradaDto.totalPagamentos,
      });
      setResumoTotalizadores({
        subtotalDesconto: dadosNfEntradaDto.subtotalDesconto,
        subtotalAcrescimo: dadosNfEntradaDto.subtotalAcrescimo,
        subtotalDespesas: dadosNfEntradaDto.subtotalDespesas,
      });
    }
    if (!id && nfEntradaDto.usoConsumoManual) {
      setNfEntrada(nfEntradaDto);
    }
  }

  useEffect(() => {
    buscarDadosIniciais();
    buscarDadosIniciaisDto();
  }, []);

  const buscarConfiguracao = () => {
    if (dadosEmpresa.configuracoes) {
      const data = dadosEmpresa.configuracoes;
      if (data.operacaoFiscalComprasPadraoId) {
        if (!id) buscarOperacoesFiscais(data.operacaoFiscalComprasPadraoId);
      }
      if (data.cfopComprasPadraoId) {
        if (!id) buscaCfopById(data.cfopComprasPadraoId);
      }
      setConfiguracoes(data);
    }
  };

  const buscarNfEntrada = async () => {
    if (!id) return;
    setLoading(true);
    const response = await nfEntradaService.getById(id);
    if (!response.isAxiosError) {
      if (!response.data) {
        initialCollapses();
        voltar();
        return;
      }
      const dadosNfEntrada = new NfEntrada(response.data);
      buscarFornecedores({ id: dadosNfEntrada.fornecedorId });
      if (dadosNfEntrada.transportadoraId) {
        buscarTransportadora({
          id: dadosNfEntrada.transportadoraId,
        });
      }
      setNfEntrada(dadosNfEntrada);
      buscarOperacoesFiscais(dadosNfEntrada.operacaoFiscalId);
      setFrete(dadosNfEntrada?.frete);
      setNfEntradaItens(dadosNfEntrada?.nfEntradaItens);
      setNfEntradaTributos(dadosNfEntrada?.nfEntradaTributos ?? {});
      setNfEntradaPagamentos(dadosNfEntrada?.nfEntradaPagamentos);
    } else {
      responseErros(response);
      initialCollapses();
      voltar();
    }
    setLoading(false);
  };

  const buscarFornecedores = (filtros) => {
    const filtro = {
      ...filtros,
      tiposPessoaId: 2,
      nonPaginated: true,
      ativado: true,
    };
    pessoaService.getAll(filtro).then((result) => {
      setLoadingAutoCompleteFornecedor(false);
      if (!result.isAxiosError) {
        setFornecedorList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  async function onClickLog() {
    if ("userCreatedLog" in userLog) return;
    const logs = await userLogs(nfEntrada);
    setUserLog(logs);
  }

  const buscarTransportadora = async (filtros) => {
    const filtro = {
      ...filtros,
      tiposPessoaId: 3,
      nonPaginated: true,
      ativado: true,
    };
    const result = await pessoaService.getAll(filtro);
    if (!result.isAxiosError) {
      setTransportadoraList(result.data);
    } else {
      responseErros(result);
    }
  };

  const buscarOperacoesFiscais = (id) => {
    const filtros = {
      ativado: true,
      tipoOperacao: "ENTRADA",
      restritiva: true,
      finalidade: ["NAO_SE_APLICA", "NFE_NORMAL"],
    };
    operacaoFiscalService.getAllFiltroAvancado(filtros).then((result) => {
      if (!result.isAxiosError) {
        setOperacaoFiscalList(result.data.rows);
        if (id && result.data.rows?.length) {
          const operacaoPadrao = result.data.rows.find(
            (operacao) => operacao.id === id
          );
          setNfEntrada((prevState) => ({
            ...prevState,
            operacaoFiscal: operacaoPadrao,
            modelo: operacaoPadrao?.modeloDocumento,
            operacaoFiscalId: operacaoPadrao?.id,
            finalidade: operacaoPadrao?.finalidade,
          }));
        }
      } else {
        responseErros(result);
      }
    });
  };

  const buscarMeiosPagamento = async () => {
    const filtros = {
      nonPaginated: true,
      ativado: true,
    };
    const result = await meioPagamentoService.getAll(filtros);
    if (!result.isAxiosError) {
      setMeioPagamentoList(result.data);
    } else {
      responseErros(result);
    }
  };

  const buscarCondicoesPagamento = async () => {
    const filtros = {
      nonPaginated: true,
      ativado: true,
    };
    const result = await condicoesPagamentoService.getAll(filtros);
    if (!result.isAxiosError) {
      setCondicaoPagamentoList(result.data);
    } else {
      responseErros(result);
    }
  };

  const buscaCfopById = async (id) => {
    const result = await cfopService.getById(id);
    if (!result.isAxiosError) {
      setCfop(result.data);
    } else {
      responseErros(result);
    }
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    setNfEntrada((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeFrete = (e) => {
    const { name, value } = e.target;
    const nfEntradaTemp = {
      ...nfEntrada,
      [name]: value,
    };
    if (frete && value && nfEntradaPagamentos.length) {
      setNfEntradaPagamentos([]);
      nfEntradaTemp.totalPagamentos = 0;
      nfEntradaTemp.totalCompra = totalCompra(
        nfEntradaTemp,
        nfEntradaItens,
        nfEntradaTributos
      );
      setGerarPagamentos({
        valorTitulo: nfEntradaTemp.totalCompra,
      });
    }
    setNfEntrada(nfEntradaTemp);
  };

  const handleDateChange = (fieldName, date) => {
    if (!date) {
      date = "Invalid Date";
    }

    setNfEntrada((prevState) => ({
      ...prevState,
      [fieldName]: date != "Invalid Date" ? formatDate.toSend(date) : undefined,
    }));
  };

  function cadastrarNfEntrada(nfEntrada) {
    nfEntradaService.cadastrar(nfEntrada).then((result) => {
      if (!result.isAxiosError) {
        setLoading(false);
        notification.cadastroSucesso();
        initialCollapses();
        voltar();
        return;
      } else {
        responseErros(result);
      }
    });
  }

  function atualizarNfEntrada(id, nfEntrada) {
    nfEntradaService.atualizar(id, nfEntrada).then((result) => {
      if (!result.isAxiosError) {
        setLoading(false);
        notification.alteracaoSucesso();
        initialCollapses();
        voltar();
        return;
      } else {
        buscarNfEntrada();
        responseErros(result);
      }
    });
  }

  const buscarNfEntradaExistente = async (nfEntrada) => {
    setLoading(true);
    const result = await nfEntradaService.getAllFiltroAvancado({
      fornecedorId: nfEntrada?.fornecedorId,
      numeroNota: nfEntrada?.numeroNota,
      serie: nfEntrada?.serie,
      restritiva: true,
    });
    if (!result.isAxiosError) {
      if (result.data.rows.length) {
        return notification.alertaGenericos(
          "Uma nota de entrada com mesmo número/série e fornecedor já foi lançada."
        );
      }
    } else responseErros(result);
  };

  const handleSubmit = async () => {
    const dadosNfEntrada = new NfEntrada(
      nfEntrada,
      nfEntradaItens,
      nfEntradaTributos,
      nfEntradaPagamentos,
      frete
    );
    setLoading(true);
    nfEntradaValidatorService
      .validate(dadosNfEntrada, { abortEarly: false })
      .then(async () => {
        if (id) {
          atualizarNfEntrada(id, dadosNfEntrada);
        } else {
          await buscarNfEntradaExistente(dadosNfEntrada);
          if (
            !nfEntradaDto?.usoConsumo &&
            configuracoes.precificacaoNfEntrada
          ) {
            const confirmacaoAlerta = await notification.confirmacaoGenericos({
              title: "Precificação",
              text: "Deseja relizar precificação dos itens",
              icon: "success",
              confirmButtonText: "PRECIFICAR",
              cancelButtonText: "FECHAR",
              showCancelButton: true,
            });
            if (confirmacaoAlerta.isConfirmed) {
              setLoading(false);
              setOpenPrecificacaoItens(true);
              setInitialNfEntradaItensState(nfEntradaItens);
              return;
            }
          }
          cadastrarNfEntrada(dadosNfEntrada);
        }
        setNfEntradaDto({
          nfEntradaItens: [],
          nfEntradaTributos: {},
        });
      })
      .catch((err) => {
        setLoading(false);
        if (err?.inner[0].path == "nfEntradaItens") setAbaValue(0);
        if (
          err?.inner[0].path == "nfEntradaPagamentos" ||
          err?.message === "É necessário atualizar valor da(s) parcela(s)"
        )
          setAbaValue(2);
        inputErros.set(err);
      });
  };

  const TotalCompraComponent = () => {
    return (
      <>
        <span className="me-2">Total:</span>
        <span className="badge bg-primary bg-gradient bg-gradient text-wrap p-2">
          {nfEntrada.totalCompra
            ? formatPrice(
                totalCompra(nfEntrada, nfEntradaItens, nfEntradaTributos)
              )
            : "R$ 0,00"}
        </span>
      </>
    );
  };

  const onChangeAutocomplete = (name, value) => {
    setNfEntrada({
      ...nfEntrada,
      [name]: value ? value.id : undefined,
    });
    if (name == "operacaoFiscalId") {
      buscarFinalidade(value.id);
    }
  };

  const buscarFinalidade = (operacaoFiscalId) => {
    const operacaoEncontrada = operacaoFiscalList.find(
      (operacao) => operacao.id === operacaoFiscalId
    );
    if (operacaoEncontrada) {
      setNfEntrada((prevState) => ({
        ...prevState,
        finalidade: operacaoEncontrada.finalidade,
      }));
    }
  };

  const onInputChangeFornecedor = (event) => {
    if (event.key === "Enter" && inputRef.current === document.activeElement) {
      const eventValue = event.target?.value;
      if (eventValue?.length >= 2) {
        const filtros = {
          nomeRazaoSocial: eventValue,
          apelidoFantasia: eventValue,
        };
        setLoadingAutoCompleteFornecedor(true);
        timeoutBuscaAutocomplete(buscarFornecedores(filtros));
      }
    }
  };

  return (
    <AddLayout
      id={id}
      title={id ? "Editar NF de Entrada" : "Cadastrar NF de Entrada"}
      onClickSalvar={handleSubmit}
      ButtonMenu={<MenuAction />}
      loading={loading}
      codigo={
        id && nfEntrada?.numeroNota ? `Nº Nota ${nfEntrada?.numeroNota}` : ""
      }
      showUpdate={permissoesHelper.temPermisao("nf-entrada-editar")}
      userLog={id && userLog}
      onClickLog={onClickLog}
      actions={<TotalCompraComponent />}
      actionsTotalizadores={
        <ResumoTotal
          nfEntradaId={id}
          nfEntrada={nfEntrada}
          setNfEntrada={setNfEntrada}
          nfEntradaItens={nfEntradaItens}
          setNfEntradaItens={setNfEntradaItens}
          nfEntradaTributos={nfEntradaTributos}
          abaValue={abaValue}
          setGerarPagamentos={setGerarPagamentos}
          resumoTotalizadores={resumoTotalizadores}
          setResumoTotalizadores={setResumoTotalizadores}
        />
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              required
              id="operacaoFiscalId"
              name="operacaoFiscalId"
              options={operacaoFiscalList}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => (option ? option.descricao : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Operação Fiscal"
                  variant="outlined"
                  error={inputErros.get("operacaoFiscalId")}
                  required
                />
              )}
              onChange={(_, newValue) => {
                setInputErro([]);
                onChangeAutocomplete("operacaoFiscalId", newValue);
                setNfEntrada((prevState) => ({
                  ...prevState,
                  operacaoFiscal: newValue,
                  modelo: newValue?.modeloDocumento,
                }));
              }}
              value={value.autoComplete(
                operacaoFiscalList,
                nfEntrada?.operacaoFiscalId
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              required
              id="fornecedorId"
              name="fornecedorId"
              options={fornecedorList}
              autoHighlight
              getOptionLabel={(option) => option.nomeRazaoSocial ?? ""}
              loading={loadingAutoCompleteFornecedor}
              loadingText="Carregando"
              noOptionsText="Digite e pressione Enter para buscar"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Fornecedor"
                  variant="outlined"
                  onKeyDown={onInputChangeFornecedor}
                  inputRef={inputRef}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  error={inputErros.get("fornecedorId")}
                  required
                />
              )}
              onChange={(_, newValue) => {
                setInputErro([]);
                onChangeAutocomplete("fornecedorId", newValue);
                setNfEntrada((prevState) => ({
                  ...prevState,
                  cnpj: newValue?.cnpjCpf,
                }));
              }}
              value={value.autoComplete(
                fornecedorList,
                nfEntrada?.fornecedorId
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <MaskedInput
            id="cnpj"
            name="cnpj"
            label={nfEntrada.cnpj?.length <= 11 ? "CPF" : "CNPJ"}
            margin="normal"
            mask={
              nfEntrada.cnpj?.length <= 11
                ? "999.999.999-99"
                : "99.999.999/9999-99"
            }
            variant="filled"
            disabled
            value={nfEntrada?.cnpj ?? ""}
            onChange={(e) => {
              handleInputChange(e);
            }}
            required
            error={inputErros.get("cnpj")}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="modelo"
            name="modelo"
            label="Modelo NF"
            margin="normal"
            variant="filled"
            disabled
            value={nfEntrada?.modelo ?? ""}
            fullWidth
            required
            error={inputErros.get("modelo")}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="numeroNota"
            name="numeroNota"
            type="number"
            label="Número NF"
            variant="outlined"
            margin="normal"
            value={nfEntrada.numeroNota ?? ""}
            onChange={handleInputChange}
            InputProps={{ inputProps: { min: 0 } }}
            fullWidth
            required
            error={inputErros.get("numeroNota")}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="serie"
            name="serie"
            label="Série"
            type="number"
            variant="outlined"
            margin="normal"
            value={nfEntrada?.serie ? nfEntrada.serie : ""}
            onChange={handleInputChange}
            fullWidth
            required
            error={inputErros.get("serie")}
          />
        </Grid>
        <Grid item xs={3}>
          <DatePicker
            id="dataEmissao"
            name="dataEmissao"
            label="Data Emissão"
            format="dd/MM/yyyy"
            variant={id ? "filled" : "outlined"}
            disabled={id ? true : false}
            required
            value={nfEntrada?.dataEmissao ? nfEntrada.dataEmissao : null}
            onChange={(date) => {
              setInputErro([]);
              handleDateChange("dataEmissao", date);
            }}
            error={inputErros.get("dataEmissao")}
          />
        </Grid>
        <Grid item xs={3}>
          <DatePicker
            id="dataChegada"
            name="dataChegada"
            label="Data Chegada"
            format="dd/MM/yyyy"
            minDate={new Date(nfEntrada?.dataEmissao)}
            variant={id ? "filled" : "outlined"}
            disabled={
              (nfEntrada?.dataEmissao ? false : true) ||
              (id && nfEntrada?.dataChegada ? true : false)
            }
            required
            value={nfEntrada?.dataChegada ? nfEntrada.dataChegada : null}
            onChange={(date) => {
              setInputErro([]);
              handleDateChange("dataChegada", date);
            }}
            error={inputErros.get("dataChegada")}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            label="Finalidade"
            id="finalidade"
            name="finalidade"
            error={inputErros.get("finalidade")}
            value={value.text(nfEntrada?.finalidade)}
            onChange={(event) => handleInputChange(event)}
            variant="filled"
            disabled
          >
            <MenuItem value={"NAO_SE_APLICA"}>0 - NÃO SE APLICA</MenuItem>
            <MenuItem value={"NFE_NORMAL"}>1 - NFE NORMAL</MenuItem>
            <MenuItem value={"NFE_COMPLEMENTAR"}>2 - NFE COMPLEMENTAR</MenuItem>
            <MenuItem value={"NFE_AJUSTE"}>3 - NFE DE AJUSTE</MenuItem>
            <MenuItem value={"DEVOLUCAO"}>4 - DEVOLUÇÃO DE MERCADORIA</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Select
            id="modalidadeFrete"
            name="modalidadeFrete"
            margin="normal"
            label="Modalidade Frete"
            error={inputErros.get("modalidadeFrete")}
            variant={"outlined"}
            disabled={id || !nfEntrada?.operacaoFiscalId ? true : false}
            value={nfEntrada?.modalidadeFrete ?? "mfSemFrete"}
            onChange={(e) => {
              setInputErro([]);
              setNfEntrada((prevState) => ({
                ...prevState,
                modalidadeFrete: e.target.value,
              }));
            }}
            required
          >
            {constantes.modalidadeFrete.map((modalidade) => (
              <MenuItem key={modalidade.value} value={modalidade.value}>
                {modalidade.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={3}>
          <CurrencyInput
            id="frete"
            name="frete"
            label="Frete"
            value={frete ?? 0}
            onBlur={(e) => {
              const { value } = e.target;
              setFrete(value !== "" ? parseFloat(value) : 0);
              handleInputChange(e);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Select
            label="Frete Compõe Total da Nota?"
            id="freteCompoeNota"
            name="freteCompoeNota"
            margin="normal"
            value={nfEntrada.freteCompoeNota ?? false}
            required={frete}
            error={inputErros.get("freteCompoeNota")}
            onChange={handleChangeFrete}
          >
            <MenuItem value={true}>Sim</MenuItem>
            <MenuItem value={false}>Não</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="nfeChave"
            name="nfeChave"
            label="NF-e Chave"
            type="number"
            variant="outlined"
            margin="normal"
            InputProps={{ inputProps: { min: 0, maxLength: 44 } }}
            value={nfEntrada?.nfeChave ? nfEntrada.nfeChave : ""}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
      </Grid>
      <Paper className="mt-4">
        <Tabs
          value={abaValue}
          onChange={(e, value) => {
            e.persist();
            setAbaValue(value);
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="ITENS" {...tabProps(0)} />
          <Tab label="TRIBUTOS" {...tabProps(1)} />
          <Tab label="PAGAMENTOS" {...tabProps(2)} />
          <Tab label="FRETE" {...tabProps(3)} />
          <Tab label="INFORMAÇÕES ADICIONAIS" {...tabProps(4)} />
        </Tabs>
      </Paper>
      <CardContent>
        <TabPanel value={abaValue} index={0}>
          <NfEntradaItens
            nfEntradaId={id}
            setNfEntrada={setNfEntrada}
            nfEntradaItens={nfEntradaItens}
            setNfEntradaItens={setNfEntradaItens}
            nfEntradaTributos={nfEntradaTributos}
            setNfEntradaPagamentos={setNfEntradaPagamentos}
            gerarPagamentos={gerarPagamentos}
            setGerarPagamentos={setGerarPagamentos}
            inputErros={inputErros}
            cfop={cfop}
            setCfop={setCfop}
            unidadeList={unidadeList}
            setUnidadeList={setUnidadeList}
          />
        </TabPanel>
        <TabPanel value={abaValue} index={1}>
          <NfEntradaTributos
            nfEntradaId={id}
            nfEntradaTributos={nfEntradaTributos}
            setNfEntradaTributos={setNfEntradaTributos}
            nfEntradaDto={nfEntradaDto}
          />
        </TabPanel>
        <TabPanel value={abaValue} index={2}>
          <NfEntradaPagamentos
            nfEntradaId={id}
            nfEntrada={nfEntrada}
            setNfEntrada={setNfEntrada}
            nfEntradaItens={nfEntradaItens}
            nfEntradaPagamentos={nfEntradaPagamentos}
            setNfEntradaPagamentos={setNfEntradaPagamentos}
            meioPagamentoList={meioPagamentoList}
            condicaoPagamentoList={condicaoPagamentoList}
            meioPagamentoSelecionado={meioPagamentoSelecionado}
            setMeioPagamentoSelecionado={setMeioPagamentoSelecionado}
            gerarPagamentos={gerarPagamentos}
            setGerarPagamentos={setGerarPagamentos}
          />
        </TabPanel>
        <TabPanel value={abaValue} index={3}>
          <NfEntradaFrete
            nfEntrada={nfEntrada}
            transportadoraList={transportadoraList}
          />
        </TabPanel>
        <TabPanel value={abaValue} index={4}>
          <NfEntradaInformacoes
            nfEntrada={nfEntrada}
            handleInputChange={handleInputChange}
          />
        </TabPanel>
      </CardContent>
      <ModalPrecificacaoNfEntrada
        open={openPrecificacaoItens}
        setOpen={setOpenPrecificacaoItens}
        //inputErros={inputErros}
        setLoading={setLoading}
        //nfEntradaValidator={nfEntradaValidator}
        //cadastrarNfEntrada={cadastrarNfEntrada}
        nfEntrada={nfEntrada}
        nfEntradaItens={nfEntradaItens}
        setNfEntradaItens={setNfEntradaItens}
        initialNfEntradaItensState={initialNfEntradaItensState}
        margemCalculo={configuracoes?.margemCalculo}
        handleSubmit={handleSubmit}
      />
    </AddLayout>
  );
};

export default AddNfEntrada;
