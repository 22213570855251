import { useState, useEffect } from "react";
import {
  Grid,
  Button,
  DialogContent,
  DialogActions,
  TextField,
  DataGrid,
  Autocomplete,
  DatePicker,
} from "../../../../components";
import { InputErros, value } from "../../../../helpers";
import { notification } from "../../../../services";
import { formatPrice } from "../../../../utils";

const ImportarDocumentoStepperContent = ({
  sendServer,
  activeStep,
  setActiveStep,
  handleImportarMercadorias,
  handleCancelarImportacao,
  handleDateChange,
  filtrosDialog,
  setFiltrosDialog,
  operacaoFiscal,
  setOperacaoFiscal,
  listaOperacoesFiscais,
  listaClientes,
  colunas,
  nfSaida,
  documentoSelecionado,
  setDocumentoSelecionado,
  setItensSelecionados,
  buscarNfSaidaAvancado,
  itemSelectionModel,
  setItemSelectionModel,
  documentoSelectionModel,
  setDocumentoSelectionModel,
  handleInputChange,
}) => {
  const [listaItens, setListaItens] = useState([]);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const colunasItens = [
    {
      field: "descricao",
      headerName: "Produto",
      flex: 500,
    },
    {
      field: "valor",
      headerName: "Valor (R$)",
      flex: 300,
      valueGetter: (params) => formatPrice(parseFloat(params.value)),
    },
    {
      field: "quantidade",
      headerName: "Qtd.",
      flex: 200,
      headerAlign: "start",
      align: "start",
      type: "number",
      editable: true,
    },
    {
      field: "subtotal",
      headerName: "Total Ítem (R$)",
      flex: 300,
      valueGetter: (params) => {
        const total = params.row.valor * params.row.quantidade;
        return formatPrice(total);
      },
    },
  ];

  useEffect(() => {
    if (documentoSelecionado && documentoSelecionado.nfSaidaItens) {
      const listaIdItens = documentoSelecionado.nfSaidaItens.map(
        (item) => item.id
      );
      setItemSelectionModel(listaIdItens);
      setItensSelecionados(documentoSelecionado.nfSaidaItens);
      setListaItens(documentoSelecionado.nfSaidaItens);
    }
  }, [documentoSelecionado]);

  const filtrarDocumentoSelecionado = (nfSaidaIds) => {
    let documentoImportacao = null;
    let itensImportacao = [];
    const documentoFiltrado = nfSaida.filter((documento) => {
      if (nfSaidaIds.indexOf(documento.id) > -1) {
        if (documentoImportacao === null) {
          documentoImportacao = {
            ...documento,
          };
        }
        for (const key in documento) {
          if (
            documentoImportacao[key] != documento[key] &&
            key != "id" &&
            key != "nfSaidaItens"
          ) {
            documento[key] = null;
          }
        }
        if (documentoImportacao?.clienteId == documento.clienteId) {
          itensImportacao.push(...documento.nfSaidaItens);
          return documento;
        } else {
          setActiveStep(-1);
          return notification.alertaGenericos(
            "Documentos devem ser do mesmo cliente"
          );
        }
      }
    });
    const chaveAcessoReferencia = documentoFiltrado[0].chaveAcesso;
    delete documentoFiltrado[0].chaveAcesso;
    delete documentoFiltrado[0].status;
    setDocumentoSelecionado(() => ({
      ...documentoFiltrado[0],
      chaveAcessoReferencia,
      nfSaidaItens: itensImportacao,
      nfSaidaPagamentos: [],
    }));
  };

  const handleAvancarStep = () => {
    if (activeStep == 0) {
      if (!documentoSelectionModel?.length)
        return notification.alertaGenericos(
          "Selecione ao menos um documento para importar"
        );
      filtrarDocumentoSelecionado(documentoSelectionModel);
    }
    if (!itemSelectionModel?.length && activeStep == 1)
      return notification.alertaGenericos(
        "Selecione ao menos um produto para importar"
      );
    setActiveStep(activeStep + 1);
  };

  const handleVoltarStep = () => {
    setActiveStep(activeStep - 1);
  };

  const filtrarItensSelecionados = (itemIds) => {
    let selecionados = [];
    for (let itemId of itemIds) {
      const itemEncontrado = listaItens.find((item) => item.id === itemId);
      selecionados.push(itemEncontrado);
    }
    setItensSelecionados(selecionados);
  };

  const onChangeAutocomplete = (name, value) => {
    setFiltrosDialog({
      ...filtrosDialog,
      [name]: value ? value.id : undefined,
    });
  };

  switch (activeStep) {
    case 2:
      return (
        <>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  id="operacaoFiscalId"
                  name="operacaoFiscalId"
                  required
                  options={listaOperacoesFiscais}
                  noOptionsText="Sem opções"
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.descricao ? `${option.descricao}` : ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Operação Fiscal"
                      variant="outlined"
                      margin="normal"
                      error={inputErros.get("operacaoFiscalId")}
                      required
                    />
                  )}
                  onChange={(e, value) => {
                    setOperacaoFiscal(value);
                  }}
                  value={value.autoComplete(
                    listaOperacoesFiscais,
                    operacaoFiscal?.id
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="m-3">
            <Button
              onClick={handleCancelarImportacao}
              color="padrao"
              variant="contained"
            >
              Cancelar
            </Button>
            <Button
              onClick={handleVoltarStep}
              color="primary"
              variant="contained"
            >
              <i
                className="ph-fill ph-caret-left"
                style={{ color: "#fff", fontSize: 18 }}
              ></i>
              Anterior
            </Button>
            <Button
              onClick={handleImportarMercadorias}
              color="primary"
              variant="contained"
            >
              Importar
            </Button>
          </DialogActions>
        </>
      );
    case 1:
      return (
        <>
          <DialogContent>
            <DataGrid
              rows={listaItens}
              columns={colunasItens}
              rowCount={listaItens.length}
              checkboxSelection
              selectionModel={itemSelectionModel}
              onRowSelectionModelChange={(selectedIds) => {
                setItemSelectionModel(selectedIds);
                filtrarItensSelecionados(selectedIds);
              }}
              disableAcoes
            />
          </DialogContent>
          <DialogActions className="m-3">
            <Button
              onClick={handleVoltarStep}
              color="primary"
              variant="contained"
            >
              <i
                className="ph-fill ph-caret-left"
                style={{ color: "#fff", fontSize: 18 }}
              ></i>
              Anterior
            </Button>
            <Button
              onClick={handleAvancarStep}
              color="primary"
              variant="contained"
            >
              Próximo
              <i
                className="ph-fill ph-caret-right"
                style={{ color: "#fff", fontSize: 18 }}
              ></i>
            </Button>
          </DialogActions>
        </>
      );
    default:
      return (
        <>
          <DialogContent>
            <Grid container spacing={2} className="mt-1 mb-4">
              <Grid item xs={2}>
                <DatePicker
                  id="dataEmissaoStart"
                  name="dataEmissaoStart"
                  label="Data Emissão De"
                  format="dd/MM/yyyy"
                  value={
                    filtrosDialog?.dataEmissaoStart
                      ? filtrosDialog.dataEmissaoStart
                      : null
                  }
                  onChange={(date) =>
                    handleDateChange(date, "dataEmissaoStart")
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <DatePicker
                  id="dataEmissaoEnd"
                  name="dataEmissaoEnd"
                  label="Data Emissão Até"
                  format="dd/MM/yyyy"
                  value={
                    filtrosDialog?.dataEmissaoEnd
                      ? filtrosDialog.dataEmissaoEnd
                      : null
                  }
                  onChange={(date) => handleDateChange(date, "dataEmissaoEnd")}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  id="clienteId"
                  name="clienteId"
                  options={listaClientes}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.nomeRazaoSocial
                      ? `${option.id}` + ` - ` + `${option.nomeRazaoSocial}`
                      : ""
                  }
                  getOptionSelected={(option, value) =>
                    option?.nomeRazaoSocial === value?.nomeRazaoSocial
                  }
                  noOptionsText="Sem opções"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cliente"
                      variant={"outlined"}
                      error={inputErros.get("clienteId")}
                    />
                  )}
                  onChange={(e, value) =>
                    onChangeAutocomplete("clienteId", value)
                  }
                  value={value.autoComplete(
                    listaClientes,
                    filtrosDialog?.clienteId
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id="numeroDocumento"
                  name="numeroDocumento"
                  label="Nº Documento"
                  variant="outlined"
                  fullWidth
                  onChange={handleInputChange}
                  value={filtrosDialog?.numeroDocumento}
                />
              </Grid>
              <Grid item xs={2} className="d-flex align-items-center">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  sx={{ height: "51px" }}
                  onClick={() => buscarNfSaidaAvancado(filtrosDialog)}
                >
                  <i
                    className="ph-bold ph-magnifying-glass"
                    style={{ color: "#fff", fontSize: 18, marginRight: 10 }}
                  ></i>
                  Filtrar
                </Button>
              </Grid>
            </Grid>
            <DataGrid
              rows={nfSaida}
              columns={colunas}
              checkboxSelection
              selectionModel={documentoSelectionModel}
              onRowSelectionModelChange={(selectedIds) => {
                setDocumentoSelectionModel(selectedIds);
              }}
              paginationMode={"server"}
              sendServer={sendServer}
              disableAcoes
              noFilter
            />
          </DialogContent>
          <DialogActions className="m-3">
            <Button
              onClick={handleCancelarImportacao}
              color="padrao"
              variant="contained"
            >
              Cancelar
            </Button>
            <Button
              onClick={handleAvancarStep}
              color="primary"
              variant="contained"
            >
              Próximo
              <i
                className="ph-fill ph-caret-right"
                style={{ color: "#fff", fontSize: 18 }}
              ></i>
            </Button>
          </DialogActions>
        </>
      );
  }
};

export default ImportarDocumentoStepperContent;
