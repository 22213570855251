import { formatPrecisaoFloat } from "../../../utils";

export function validarLucro(produtoTemp, configuracoes) {
  if (
    !produtoTemp.precoCompra &&
    !produtoTemp.lucroDesejado &&
    !produtoTemp.precoCusto &&
    produtoTemp.precoVenda
  ) {
    produtoTemp.lucroDesejado = "0";
    produtoTemp.precoCompra = produtoTemp.precoVenda;
    produtoTemp.precoCusto = produtoTemp.precoVenda;
  }
  if (
    produtoTemp.precoCompra &&
    produtoTemp.precoCusto &&
    !produtoTemp.lucroDesejado
  ) {
    produtoTemp.lucroDesejado = "0";
    produtoTemp.precoVenda = produtoTemp.precoCusto;
  }
  if (produtoTemp.precoCompra && produtoTemp.precoCusto) {
    produtoTemp.precoVenda = calculoPrecoVenda(produtoTemp, configuracoes);
  }
  return produtoTemp;
}

export function validarPrecoVenda(produtoTemp, configuracoes, produto) {
  if (
    produtoTemp.precoCompra &&
    produtoTemp.precoCusto &&
    !produtoTemp.lucroDesejado &&
    !produtoTemp.precoVenda
  ) {
    produtoTemp.lucroDesejado = "0";
    produtoTemp.precoVenda = produtoTemp.precoCusto;
  }
  if (
    produtoTemp.precoCompra &&
    produtoTemp.precoCusto &&
    produtoTemp.precoVenda
  ) {
    produtoTemp.lucroDesejado = calculoLucro(
      produtoTemp,
      configuracoes,
      produto
    );
  }
  return produtoTemp;
}

export function calculoPrecoVenda(produtoTemp, configuracoes) {
  if (!produtoTemp?.precoCusto) return 0;
  const precoCusto = parseFloat(produtoTemp.precoCusto);
  let lucroDesejado = parseFloat(produtoTemp.lucroDesejado) || 0;

  if (configuracoes?.margemCalculo === "INVERSA") {
    if (lucroDesejado > 100) lucroDesejado = 99.99;
    const valor = precoCusto / (1 - lucroDesejado / 100);
    return formatPrecisaoFloat(valor);
  } else {
    const valor = precoCusto * (1 + lucroDesejado / 100);
    return formatPrecisaoFloat(valor);
  }
}

export function calculoLucro(produtoTemp, configuracoes, produto) {
  if (!produto?.precoCusto || !produtoTemp?.precoVenda) return 0;

  const precoCusto = parseFloat(produtoTemp.precoCusto);
  const precoVenda = parseFloat(produtoTemp.precoVenda);

  if (precoVenda <= 0 || precoCusto <= 0) return 0;
  if (configuracoes?.margemCalculo === "INVERSA") {
    const calculoLucroInverso = (1 - precoCusto / precoVenda) * 100;
    const arredondado = Math.ceil(calculoLucroInverso * 100) / 100;
    return formatPrecisaoFloat(arredondado);
  } else {
    const calculoLucroConvencional =
      ((precoVenda - precoCusto) / precoCusto) * 100;
    const arredondado = Math.ceil(calculoLucroConvencional * 100) / 100;
    return formatPrecisaoFloat(arredondado);
  }
}

export const calcularValorComissaoVendedor = (produto, percentual) => {
  const valorComissaoVendedor = (produto?.precoVenda || 0) * (percentual / 100);
  return {
    ...produto,
    valorComissaoVendedor,
  };
};

export const calcularPercentualComissaoVendedor = (produto, valor) => {
  const percentualComissaoVendedor =
    produto.precoVenda > 0 ? (valor * 100) / produto.precoVenda : 0;
  return {
    ...produto,
    percentualComissaoVendedor,
  };
};

export const calcularPrecoCusto = (produto, valueCompra, configuracoes) => {
  const produtoTemp = {
    ...produto,
    precoVenda: valueCompra,
    precoCusto: valueCompra,
  };
  if (valueCompra && valueCompra !== "") {
    produtoTemp.precoVenda = calculoPrecoVenda(produtoTemp, configuracoes);
    return produtoTemp;
  } else return produto;
};

export const calcularPrecoCompra = (produto, valueCompra) => {
  if (
    parseFloat(valueCompra) > parseFloat(produto.precoCusto) ||
    produto.precoCusto === "" ||
    produto.precoCusto === produto.precoCompra
  ) {
    return {
      ...produto,
      precoCompra: valueCompra,
      precoCusto: valueCompra,
    };
  } else {
    return produto;
  }
};
