import {
  DatePicker,
  Grid,
  TextField,
  Select,
  MenuItem,
} from "../../../../components";
import { formatDate } from "../../../../utils";
import { format } from "date-fns";

const FormFiltroAvancadoContador = ({
  filtrosAvancados,
  setFiltrosAvancados,
  filtrosAplicadosImpressao,
  setFiltrosAplicadosImpressao,
}) => {
  const listaStatusNf = [
    {
      label: "Pendente",
      value: "PENDENTE",
      cor: "#EA9D2B",
    },
    {
      label: "Faturada",
      value: "FATURADA",
      cor: "#004780",
    },
    {
      label: "Enviada",
      value: "ENVIADA",
      cor: "#4FBB53",
    },
    {
      label: "Em Contingência",
      value: "CONTINGENCIA",
      cor: "#BFBFBF",
    },
    {
      label: "Cancelada",
      value: "CANCELADA",
      cor: "#DC3545",
    },
    {
      label: "Denegada",
      value: "DENEGADA",
      cor: "#70309F",
    },
  ];

  const valueInput = (valueName) => {
    return filtrosAvancados[valueName] ? filtrosAvancados[valueName] : "";
  };

  const valueDate = (valueName) => {
    return filtrosAvancados[valueName] ? filtrosAvancados[valueName] : null;
  };

  const onChangeInput = (event) => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      [event.target.id]: event.target.value || null,
    });
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value || null,
    }));
  };

  const onChangeSelect = (event, name) => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      [event.target.name]: event.target.value ?? null,
    });
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      [name]: event.target.value ?? null,
    }));
  };

  const onChangeDate = (id, date, name) => {
    const isValidDate = (date) => date instanceof Date && !isNaN(date);
    if (!date || !isValidDate(date)) {
      date = "";
    }
    if (
      date &&
      date.getDate() !== undefined &&
      date.getMonth() !== undefined &&
      date.getFullYear() > 2000
    ) {
      const filtroAvancado = {
        ...filtrosAvancados,
        [id]: formatDate.toSend(date),
      };
      const filtroImpressao = {
        ...filtrosAplicadosImpressao,
        [name]: format(date, "dd/MM/yyyy"),
      };
      if (
        id.includes("Start") &&
        (!filtroAvancado[id.replace("Start", "End")] ||
          filtroAvancado[id.replace("Start", "End")] === "")
      ) {
        filtroAvancado[id.replace("Start", "End")] = formatDate.toSend(date);
        filtroImpressao[name.replace("De", "Até")] = format(date, "dd/MM/yyyy");
      }
      if (
        id.includes("End") &&
        (!filtroAvancado[id.replace("End", "Start")] ||
          filtroAvancado[id.replace("End", "Start")] === "")
      ) {
        filtroAvancado[id.replace("End", "Start")] = formatDate.toSend(date);
        filtroImpressao[name.replace("Até", "De")] = format(date, "dd/MM/yyyy");
      }
      filtroAvancado.porIntervalo = [
        {
          coluna: "dataLancamento",
          de: filtroAvancado.dataLancamentoStart,
          ate: filtroAvancado.dataLancamentoEnd,
        },
      ];
      setFiltrosAvancados(filtroAvancado);
      setFiltrosAplicadosImpressao(filtroImpressao);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="numeroDocumento"
            name="Número-Documento"
            label="Nº Documento"
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={onChangeInput}
            value={valueInput("numeroDocumento")}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="serieDocumento"
            name="Série-Documento"
            label="Série Documento"
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={onChangeInput}
            value={valueInput("serieDocumento")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            id="dataLancamentoStart"
            name="Data-Lançamento-De"
            label="Data Lançamento De"
            margin
            format="dd/MM/yyyy"
            value={valueDate("dataLancamentoStart")}
            onChange={(date) =>
              onChangeDate("dataLancamentoStart", date, "Data-Lançamento-De")
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            id="dataLancamentoEnd"
            name="Data-Lançamento-Até"
            label="Data Lançamento Até"
            margin
            format="dd/MM/yyyy"
            value={valueDate("dataLancamentoEnd")}
            onChange={(date) =>
              onChangeDate("dataLancamentoEnd", date, "Data-Lançamento-Até")
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Select
            label="Modelo Doc."
            id="modeloDocumento"
            name="modeloDocumento"
            margin="normal"
            required
            onChange={(event) => onChangeSelect(event, "Modelo Documento")}
            value={valueInput("modeloDocumento")}
          >
            <MenuItem value={"55"}>55 - (NF-e) Nota Fiscal Eletrônica</MenuItem>
            <MenuItem value={"65"}>
              65 - (NFC-e) Nota Fiscal Eletrônica ao Consumidor
            </MenuItem>
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Select
            id="status"
            name="status"
            label="Status"
            margin="normal"
            fullWidth
            onChange={(event) => onChangeSelect(event, "Status")}
            value={valueInput("status")}
          >
            {listaStatusNf.map((status, index) => (
              <MenuItem key={index} value={status.value}>
                <i
                  className="ph-fill ph-circle"
                  style={{ color: `${status.cor}` }}
                ></i>
                &nbsp;{status.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancadoContador;
