import { DataGrid, Tooltip, IconButton, Grid } from "../../../../components";

const ProdutosVinculoGrid = ({
  buscarProdutos,
  listaProdutos,
  produtoSelecionado,
  setVincularProdutoExistente,
  setTemProdutosNaoCadastrados,
  dadosXML,
  setDadosXML,
}) => {
  const colunas = [
    {
      field: "descricao",
      headerName: "Produto",
      filtrar: true,
      width: 500,
    },
    {
      field: "codigoBarra",
      headerName: "Codigo de Barra",
      width: 250,
    },
    {
      field: "acoes",
      sortable: false,
      width: 100,
      headerAlign: "center",
      disableClickEventBubbling: true,
      renderHeader: () => {
        return (
          <i className="ph-fill ph-note-pencil" style={{ fontSize: 20 }}></i>
        );
      },
      renderCell: (params) => {
        return (
          <Grid container justifyContent="center">
            <Tooltip title="Vincular Produto" placement="right">
              <IconButton onClick={() => handleVincularProduto(params.row)}>
                <i
                  className="ph-fill ph-paper-plane-right"
                  style={{ fontSize: 19, marginLeft: 14 }}
                ></i>
              </IconButton>
            </Tooltip>
          </Grid>
        );
      },
    },
  ];

  const handleVincularProduto = (produto) => {
    const index = dadosXML.itens?.findIndex(
      (item) => item.id === produtoSelecionado.id
    );
    const produtosXmlTemp = [
      ...dadosXML.itens.map((item, _index) =>
        _index === index
          ? {
              ...item,
              ...produto,
              produtoId: produto.id,
              produto: produto.descricao,
              produtoCadastrado: true,
            }
          : item
      ),
    ];
    setDadosXML((prevState) => ({
      ...prevState,
      itens: produtosXmlTemp,
    }));
    setTemProdutosNaoCadastrados(
      produtosXmlTemp.some((produto) => produto.produtoCadastrado == false)
    );
    setVincularProdutoExistente(false);
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      buscarProdutos(props.filtros);
    }
  };

  return (
    <>
      <DataGrid
        columns={colunas}
        rows={listaProdutos}
        disableAcoes
        paginationMode={"server"}
        hideFiltroAvancado
        sendServer={(props) => sendServerDatagrid(props)}
      />
    </>
  );
};

export default ProdutosVinculoGrid;
