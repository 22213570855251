import { useState } from "react";
import {
  Grid,
  Button,
  Typography,
  EnderecosDialogFaturamento,
} from "../../../../../components";
import { formatCelular, formatCpf } from "../../../../../utils";

const NfSaidaDadosClientes = ({
  nfSaidaId,
  nfSaida,
  enderecoAleterado,
  setEnderecoAleterado,
  clienteSelecionado,
  enderecoCliente,
  enderecoClienteDataGrid,
  cidadeId,
  setCidadeId,
  endereco,
  setEndereco,
  salvarEndereco,
  clienteSemCadastro,
  inputErros,
  nfSaidaEntity,
}) => {
  const [openProdutoSimilarDialog, setOpenProdutoSimilarDialog] =
    useState(false);

  const colunas = [
    { field: "descricao", headerName: "Descrição", sortable: false, flex: 500 },
    { field: "cep", headerName: "CEP", sortable: false, flex: 500 },
    {
      field: "logradouro",
      headerName: "Endereço",
      sortable: false,
      flex: 1000,
    },
    { field: "numero", headerName: "Número", sortable: false, flex: 500 },
    { field: "bairro", headerName: "Bairro", sortable: false, flex: 500 },
    { field: "cidade", headerName: "Cidade", sortable: false, flex: 500 },
    {
      field: "padrao",
      headerName: "Padrão",
      sortable: false,
      flex: 500,
      valueGetter: (params) => (params.value == true ? "Sim" : "Não"),
    },
    {
      field: "acoes",
      sortable: false,
      disableClickEventBubbling: true,
      renderHeader: () => {
        return (
          <i
            className="ph-fill ph-note-pencil"
            style={{ fontSize: 20, marginLeft: 14 }}
          ></i>
        );
      },
      renderCell: (params) => {
        return (
          <i
            className="ph-fill ph-paper-plane-right"
            style={{ fontSize: 20, marginLeft: 14 }}
            onClick={() => {
              setOpenProdutoSimilarDialog(false);
              setEnderecoAleterado(params.row);
              nfSaidaEntity.setEnderecoCliente(params.row);
            }}
          ></i>
        );
      },
    },
  ];

  const handleClick = () => {
    setOpenProdutoSimilarDialog(true);
  };

  if (nfSaida?.clienteId || clienteSemCadastro) {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={4} className="d-flex align-items-end mt-4">
            <div
              className="d-flex flex-column align-items-start justify-content-end"
              style={{ marginLeft: 10 }}
            >
              <Typography variant="overline">
                {clienteSemCadastro ? (
                  <h4 style={{ fontWeight: 800, margin: 0 }}>
                    {clienteSelecionado?.nomeRazaoSocial}
                  </h4>
                ) : (
                  <h4 style={{ fontWeight: 800, margin: 0 }}>
                    {clienteSelecionado?.codigo
                      ? `${clienteSelecionado.codigo} - `
                      : ""}
                    {clienteSelecionado?.nomeRazaoSocial}
                  </h4>
                )}
              </Typography>
              {clienteSelecionado?.cnpjCpf?.length == 11 ? (
                <Typography variant="overline">cpf: </Typography>
              ) : (
                <Typography variant="overline">cnpj: </Typography>
              )}
              <Typography variant="overline">
                <h5>{formatCpf(clienteSelecionado?.cnpjCpf)}</h5>
              </Typography>
            </div>
          </Grid>
          {!clienteSemCadastro && (
            <>
              {" "}
              <Grid item xs={4} className="d-flex align-items-end">
                <div
                  className="d-flex flex-column align-items-start"
                  style={{ marginLeft: 10 }}
                >
                  <Typography variant="overline">Telefone:</Typography>
                  <Typography variant="overline">
                    <h5>{formatCelular(clienteSelecionado?.telefone)}</h5>
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={4} className="d-flex align-items-end">
                <div
                  className="d-flex flex-column align-items-start"
                  style={{ marginLeft: 10 }}
                >
                  <Typography variant="overline">Tabela de Vendas:</Typography>
                  <Typography variant="overline">
                    <h5>{"sem informação no momento"}</h5>
                  </Typography>
                </div>
              </Grid>{" "}
            </>
          )}
        </Grid>
        {!clienteSemCadastro && (
          <>
            <Grid>
              <hr className="mt-4 mb-2" />
              <Grid container spacing={3}>
                <Grid item xs={4} className="d-flex align-items-start mt-3">
                  <div
                    className="d-flex flex-column align-items-start justify-content-end"
                    style={{ marginLeft: 10 }}
                  >
                    <Typography variant="overline">endereço:</Typography>
                    {enderecoCliente && (
                      <Typography variant="overline">
                        <h5>
                          {`
												${enderecoCliente?.logradouro}, 
												${enderecoCliente?.numero}, 
												${enderecoCliente?.bairro} / 
												${enderecoCliente?.cidade}, ${enderecoCliente?.estado}
											`}
                        </h5>
                      </Typography>
                    )}
                  </div>
                </Grid>
                <Grid
                  item
                  xs={4}
                  className="d-flex align-items-end mt-3 flex-wrap"
                >
                  <div
                    className="d-flex flex-column align-items-start justify-content-end"
                    style={{ marginLeft: 10 }}
                  >
                    <Typography variant="overline" style={{ marginRight: 5 }}>
                      endereço de entrega:
                    </Typography>
                    {enderecoAleterado ? (
                      <Typography variant="overline">
                        <h5>
                          {`
												${enderecoAleterado.logradouro}, 
												${enderecoAleterado.numero}, 
												${enderecoAleterado.bairro} / 
												${enderecoAleterado.cidade}, ${enderecoAleterado.estado}
											`}
                        </h5>
                      </Typography>
                    ) : enderecoCliente ? (
                      <Typography variant="overline">
                        <h5>
                          {`
												${enderecoCliente?.logradouro}, 
												${enderecoCliente?.numero}, 
												${enderecoCliente?.bairro} / 
												${enderecoCliente?.cidade}, ${enderecoCliente?.estado}
											`}
                        </h5>
                      </Typography>
                    ) : (
                      ""
                    )}
                  </div>
                  <Button
                    color="primary"
                    size="small"
                    ariaHaspopup="menu"
                    variant="contained"
                    disabled={
                      nfSaidaId || !nfSaida?.operacaoFiscalId ? true : false
                    }
                    onClick={() => handleClick()}
                  >
                    {enderecoCliente
                      ? "Alterar endereço"
                      : "Adicionar endereço"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        <EnderecosDialogFaturamento
          openProdutoSimilarDialog={openProdutoSimilarDialog}
          setOpenProdutoSimilarDialog={setOpenProdutoSimilarDialog}
          enderecoClienteDataGrid={enderecoClienteDataGrid}
          colunas={colunas}
          cidadeId={cidadeId}
          setCidadeId={setCidadeId}
          salvarEndereco={salvarEndereco}
          endereco={endereco}
          setEndereco={setEndereco}
          setEnderecoAleterado={setEnderecoAleterado}
          inputErros={inputErros}
          entity={nfSaidaEntity}
        />
      </>
    );
  } else {
    return <Grid></Grid>;
  }
};

export default NfSaidaDadosClientes;
