import { useState, useEffect } from "react";
import { format } from "date-fns";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  CardHeader,
  Dialog,
  Loading,
} from "../../../../components";
import {
  PedidosService,
  OperacaoFiscalService,
  PessoaService,
  MeioPagamentoService,
  history,
  OrigemMercadoriaService,
  notification,
  UnidadeComercialService,
  ProdutosService,
} from "../../../../services";
import { responseErros, formatDate, formatPrice } from "../../../../utils";
import { PedidoStepperContent } from ".";
import { NfSaida, NfSaidaItem } from "../../entities";
import {
  formatProdutosTotalizadoresDto,
  totalVenda,
} from "../../add-nf-saida/add-nf-saida";

const ImportarPedidoDialog = ({
  openImportarPedidoDialog,
  setOpenImportarPedidoDialog,
  filtrosDialog,
  setFiltrosDialog,
}) => {
  const pedidosService = new PedidosService();
  const operacaoFiscalService = new OperacaoFiscalService();
  const pessoaService = new PessoaService();
  const meioPagamentoService = new MeioPagamentoService();
  const unidadeComercialService = new UnidadeComercialService();
  const origemMercadoriaService = new OrigemMercadoriaService();
  const produtosService = new ProdutosService();

  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [pedidos, setPedidos] = useState([]);
  const [pedidosSelecionados, setPedidosSelecionados] = useState([]);
  const [itensSelecionados, setItensSelecionados] = useState([]);
  const [pedidoSelectionModel, setPedidoSelectionModel] = useState([]);
  const [itemSelectionModel, setItemSelectionModel] = useState([]);
  const [itemEditado, setItemEditado] = useState(false);
  const [totalListaPedidos, setTotalListaPedidos] = useState(0);
  const [listaPessoas, setListaPessoas] = useState([]);
  const [listaOperacoesFiscais, setListaOperacoesFiscais] = useState([]);
  const [listaMeiosPagamento, setListaMeiosPagamento] = useState([]);
  const [unidadeList, setUnidadeList] = useState([]);
  const [origensMercadoriaList, setOrigensMercadoriaList] = useState([]);
  const steps = ["Selecionar Pedidos", "Selecionar Ítens e Importar"];
  const colunas = [
    {
      field: "serieDocumento",
      headerName: "Série",
      width: 90,
    },
    {
      field: "numeroDocumento",
      headerName: "Nº Doc",
      width: 140,
    },
    {
      field: "dataEmissao",
      headerName: "Data de Emissão",
      width: 170,
      valueGetter: (params) =>
        format(formatDate.received(params.value), "dd/MM/yyyy"),
    },
    {
      field: "operacaoFiscalId",
      headerName: "Operação Fiscal",
      width: 250,
      valueGetter: (params) =>
        listaOperacoesFiscais.find((item) => item.id == params.value)
          ?.descricao,
    },
    {
      field: "pessoaClienteId",
      headerName: "Cliente",
      width: 170,
      valueGetter: (params) =>
        listaPessoas.find((item) => item.id == params.value)?.nomeRazaoSocial,
    },
    {
      field: "pessoaVendedorId",
      headerName: "Vendedor",
      width: 170,
      valueGetter: (params) =>
        listaPessoas.find((item) => item.id == params.value)?.nomeRazaoSocial,
    },
    {
      field: "itens",
      headerName: "Total do Pedido",
      width: 250,
      valueGetter: (params) => {
        const totalPedido =
          params.value
            .map((item) => item.valorUnitario * item.saldoExportar)
            .reduce((acumulador, total) => acumulador + total, 0) +
          params.row.acrescimo -
          params.row.desconto +
          params.row.despesas;
        return formatPrice(totalPedido);
      },
    },
  ];

  useEffect(() => {
    if (openImportarPedidoDialog) {
      buscarPedidos();
      buscarUnidadesComerciais();
      buscarOrigensMercadoria();
    }
  }, [openImportarPedidoDialog]);

  const buscarPedidos = async (filtro) => {
    setLoading(true);
    const filtros = {
      ...filtro,
      status: ["ABERTO", "PARCIALMENTE_FECHADO"],
    };
    const result = await pedidosService.getAllFiltroAvancado(filtros);
    if (!result.isAxiosError) {
      setTotalListaPedidos(result.data.count);
      setPedidos(result.data.rows);
      buscarRelacionamentosPedido(result.data.rows);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarPedidosAvancado = async (filtros) => {
    setLoading(true);
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filtros,
      status: ["ABERTO", "PARCIALMENTE_FECHADO"],
      restritiva: true,
    };
    if (filtrosAvancadosTemp.dataInicial && filtrosAvancadosTemp.dataFinal) {
      porIntervalo.push({
        coluna: "dataEmissao",
        de: filtrosAvancadosTemp.dataInicial,
        ate: filtrosAvancadosTemp.dataFinal,
      });
    }
    delete filtrosAvancadosTemp.dataInicial;
    delete filtrosAvancadosTemp.dataFinal;
    const result = await pedidosService.getAllFiltroAvancado({
      ...filtrosAvancadosTemp,
      porIntervalo,
    });
    if (!result.isAxiosError) {
      setPedidos(result.data.rows);
      setTotalListaPedidos(result.data.rows.length);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarRelacionamentosPedido = (listaPedido) => {
    const idsPessoas = [];
    const idsOperacoesFiscais = [];
    const idsMeiosPagamento = [];
    const idsCondicoesVenda = [];
    for (const objeto of listaPedido) {
      idsOperacoesFiscais.push(objeto.operacaoFiscalId);
      if (objeto.pessoaClienteId) idsPessoas.push(objeto.pessoaClienteId);
      if (objeto.pessoaVendedorId) idsPessoas.push(objeto.pessoaVendedorId);
      if (objeto.meioPagamentoId)
        idsMeiosPagamento.push(objeto.meioPagamentoId);
      if (objeto.condicaoPagamentoId)
        idsCondicoesVenda.push(objeto.condicaoPagamentoId);
    }
    buscarOperacoesFiscais([...new Set(idsOperacoesFiscais)]);
    buscarPessoas([...new Set(idsPessoas)]);
    buscarMeiosPagamento([...new Set(idsMeiosPagamento)]);
  };

  const buscarOperacoesFiscais = (listaIds) => {
    const filtro = {
      id: listaIds.length > 0 ? listaIds : undefined,
      nonPaginated: true,
    };
    operacaoFiscalService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setListaOperacoesFiscais(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarPessoas = (listaIds) => {
    const filtro = {
      id: listaIds.length > 0 ? listaIds : undefined,
      nonPaginated: true,
    };
    pessoaService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        const listaPessoas = result.data;
        setListaPessoas(listaPessoas);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarMeiosPagamento = (listaIds) => {
    const filtro = {
      id: listaIds.length > 0 ? listaIds : undefined,
      nonPaginated: true,
    };
    meioPagamentoService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setListaMeiosPagamento(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const handleInputChange = (e) => {
    e.persist();
    setFiltrosDialog((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDateChange = (date, fieldName) => {
    setFiltrosDialog((prevState) => ({
      ...prevState,
      [fieldName]: date != "Invalid Date" ? formatDate.toSend(date) : null,
    }));
  };

  const handleImportarPedido = async () => {
    if (!itemSelectionModel?.length)
      return notification.alertaGenericos(
        "Selecione ao menos um produto para importar"
      );
    window.nfSaidaEntity = await createNfSaida(
      pedidosSelecionados[0],
      pedidosSelecionados,
      itensSelecionados
    );
    setOpenImportarPedidoDialog(false);
    resetarDadosImportacao();
    history.push("/faturamento/nf-saida/importar-pedido");
  };

  const getOperacaoFiscal = async (id) => {
    if (!id) return;
    return operacaoFiscalService.getById(id).then((result) => {
      if (!result.isAxiosError) {
        return result.data;
      } else {
        responseErros(result);
        return null;
      }
    });
  };

  const buscarOrigensMercadoria = async () => {
    origemMercadoriaService.getAll().then((res) => {
      if (!res.isAxiosError) {
        setOrigensMercadoriaList(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarUnidadesComerciais = async () => {
    const filtros = {
      nonPaginated: true,
    };
    unidadeComercialService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setUnidadeList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const createNfSaida = async (pedido, pedidos, itens) => {
    const operacaoFiscal = await getOperacaoFiscal(pedido.operacaoFiscalId);
    if (
      operacaoFiscal?.modeloDocumento == "055" ||
      operacaoFiscal?.modeloDocumento == "065"
    ) {
      return notification.alertaGenericos(
        "Operação fiscal sem regra de tributação associada."
      );
    }
    const itensTemp = await buscarDadosProdutos(
      formatProdutosTotalizadoresDto(itens, totalVenda(pedido, itens), pedido)
    );
    const nfSaidaItens = itensTemp
      .filter((item) => itemSelectionModel.indexOf(item.id) > -1)
      .map((item, index) => {
        const nfSaidaItem = new NfSaidaItem(
          item,
          index,
          operacaoFiscal,
          origensMercadoriaList,
          unidadeList
        );
        return {
          ...nfSaidaItem,
          produto: item.produto,
          valor: parseFloat(item.valorUnitario.toFixed(2)),
          subtotal:
            parseFloat(item.valorUnitario.toFixed(2)) * item.saldoExportar,
        };
      });
    const nfSaidaPagamentos =
      pedido?.status === "ABERTO" &&
      itens?.length === itemSelectionModel?.length &&
      !itemEditado &&
      pedidosSelecionados.length === 1
        ? pedido?.pagamentos?.map((pgto, index) => ({
            condicaoPagamentoId: pgto.condicaoPagamentoId,
            meioPagamentoSelecionado: listaMeiosPagamento.find(
              (meioPagamento) => meioPagamento.id == pgto.meioPagamentoId
            ),
            meioPagamentoId: pgto.meioPagamentoId,
            dataVencimento: pgto.vencimento,
            valorTitulo: parseFloat(pgto.valorTitulo.toFixed(2)),
            numeroTitulo: index,
          }))
        : [];
    const informacoesAdicionais = pedidos
      .map((pedido) => {
        return `Importado do pedido ${pedido.serieDocumento ?? ""}/${
          pedido.numeroDocumento ?? ""
        }`;
      })
      .join("\n");
    let cliente = {};
    if (pedido.pessoaClienteId) {
      cliente = listaPessoas.find((item) => item.id == pedido.pessoaClienteId);
    }
    const dadosNfSaida = {
      dataEmissao: formatDate.toSend(new Date()),
      modalidadeFrete: "mfSemFrete",
      freteCompoeNota: false,
      pedidoImport: true,
      informacoesAdicionais,
      pedidoIds: pedidos.map((pedido) => pedido.id),
      clienteId: pedido?.pessoaClienteId,
      clienteEmail: cliente?.email,
      clienteCnpjCpf: cliente?.cnpjCpf,
      clienteNome: cliente?.nomeRazaoSocial,
      vendedorId: pedido?.pessoaVendedorId,
      operacaoFiscalId: operacaoFiscal?.id,
      operacaoFiscal,
      serieDocumento: operacaoFiscal?.serieDocumento,
      modeloDocumento: operacaoFiscal?.modeloDocumento,
      meioPagamentoId: pedido.meioPagamentoId,
      condicaoPagamentoId: pedido.condicaoPagamentoId,
      desconto: pedido.desconto ?? 0,
      acrescimo: pedido.acrescimo ?? 0,
      despesas: pedido.despesas ?? 0,
      nfSaidaItens,
      nfSaidaPagamentos,
      prestadorServicoId: pedido.pessoaProficionalId,
    };
    return new NfSaida(dadosNfSaida, true);
  };

  async function buscarDadosProdutos(itens) {
    const listaIds = itens.map(({ produtoId }) => produtoId);
    const res = await produtosService.getAll({
      id: listaIds,
      nonPaginated: true,
    });
    if (!res.isAxiosError) {
      const produtosMap = new Map(
        res.data.map((produto) => [produto.id, produto])
      );
      return itens.map((item) => ({
        ...item,
        ...produtosMap.get(item.produtoId),
        id: item.id,
      }));
    } else {
      responseErros(res);
    }
  }

  const resetarDadosImportacao = () => {
    setFiltrosDialog({});
    setPedidosSelecionados([]);
    setActiveStep(0);
  };

  const handleCancelarImportacao = () => {
    resetarDadosImportacao();
    setOpenImportarPedidoDialog(false);
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      delete props.filtros.status;
      buscarPedidos(props.filtros);
    } else {
      buscarPedidosAvancado(props.filtros);
    }
  };

  return (
    <Dialog fullWidth maxWidth="xl" open={openImportarPedidoDialog}>
      <Box sx={{ m: 2 }}>
        <CardHeader title="Importar Pedido" />
      </Box>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <PedidoStepperContent
        sendServer={sendServerDatagrid}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        handleImportarPedido={handleImportarPedido}
        handleCancelarImportacao={handleCancelarImportacao}
        handleInputChange={handleInputChange}
        handleDateChange={handleDateChange}
        filtrosDialog={filtrosDialog}
        setFiltrosDialog={setFiltrosDialog}
        colunas={colunas}
        pedidos={pedidos}
        totalListaPedidos={totalListaPedidos}
        pedidosSelecionados={pedidosSelecionados}
        setPedidosSelecionados={setPedidosSelecionados}
        itensSelecionados={itensSelecionados}
        setItensSelecionados={setItensSelecionados}
        buscarPedidosAvancado={buscarPedidosAvancado}
        listaClientes={listaPessoas}
        itemSelectionModel={itemSelectionModel}
        setItemSelectionModel={setItemSelectionModel}
        pedidoSelectionModel={pedidoSelectionModel}
        setPedidoSelectionModel={setPedidoSelectionModel}
        setItemEditado={setItemEditado}
      />
      <Loading loading={loading} />
    </Dialog>
  );
};

export default ImportarPedidoDialog;
