import SiafLiteApiRepository from "../../external/siafLiteApi";

class PessoaService {
  constructor() {
    this.urlBase = "/integracao/pessoas";
  }

  getById(id) {
    return new SiafLiteApiRepository(this.urlBase).getById(id);
  }

  getAll(filter) {
    return new SiafLiteApiRepository(this.urlBase).get(filter);
  }

  getAllFiltroAvancado(body) {
    return new SiafLiteApiRepository(this.urlBase).patch(body);
  }

  getAllFiltroAvancadoSimplificada(body) {
    return new SiafLiteApiRepository(`${this.urlBase}/simplificada`).patch(
      body
    );
  }

  cadastrar(body) {
    return new SiafLiteApiRepository(this.urlBase).post(body);
  }

  atualizar(id, body) {
    return new SiafLiteApiRepository(this.urlBase).put(id, body);
  }

  deletar(id) {
    return new SiafLiteApiRepository(this.urlBase).delete(id);
  }

  cadastrarEndereco(idPessoa, endereco) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/${idPessoa}/enderecos`
    ).post(endereco);
  }

  cadastrarCliente(idPessoa, cliente) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/${idPessoa}/clientes`
    ).post(cliente);
  }

  cadastrarFornecedor(idPessoa, fornecedor) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/${idPessoa}/fornecedores`
    ).post(fornecedor);
  }

  cadastrarPessoaConfirmacao(body) {
    return new SiafLiteApiRepository(`${this.urlBase}/confirmacao`).post(body);
  }

  cadastrarTransportadora(idPessoa, transportadora) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/${idPessoa}/transportadoras`
    ).post(transportadora);
  }

  cadastrarColaborador(idPessoa, colaborador) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/${idPessoa}/colaboradores`
    ).post(colaborador);
  }

  cadastrarPrestadorServico(idPessoa, prestadorServico) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/${idPessoa}/prestador-servico`
    ).post(prestadorServico);
  }

  getEnderecos(idPessoa) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/${idPessoa}/enderecos`
    ).get();
  }

  addAnexo(idPessoa, anexos) {
    return new SiafLiteApiRepository(`${this.urlBase}/${idPessoa}/anexos`).post(
      anexos
    );
  }

  getAnexos(idPessoa) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/${idPessoa}/anexos`
    ).get();
  }

  relatorioAniversario(body) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/relatorios/aniversario`
    ).patch(body);
  }
}

export default PessoaService;
