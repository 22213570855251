import { useState } from "react";
import { RelatoriosLayout } from "../../../layouts";
import { responseErros } from "../../../utils";
import { FormFiltroAvancadoAniversario } from "./components";
import { PessoaService } from "../../../services";
import { PrintAniversario } from "../layouts";
import { PermisoesHelper } from "../../../helpers";
const pessoasService = new PessoaService();

const RelatoriosIntegracao = () => {
  const permissoesHelper = new PermisoesHelper();
  const [loading, setLoading] = useState(false);
  const [filtrosAvancados, setFiltrosAvancados] = useState({});
  const [filtrosAplicadosImpressao, setFiltrosAplicadosImpressao] = useState(
    {}
  );
  const [dadosRelatorio, setDadosRelatorio] = useState({});

  const subgruposRelatoriosIntegracao = [
    {
      subgrupo: "Pessoas",
      propriedades: [
        {
          name: "701 - Aniversarios",
          layoutImpressao: PrintAniversario,
          permissao: permissoesHelper.temPermisao(
            "relatorio-pessoas-aniversario"
          ),
          funcaoBuscarDadosRelatorio: buscarAniversarioPessoas,
          formulario: FormFiltroAvancadoAniversario,
        },
      ],
    },
  ];

  async function buscarAniversarioPessoas(filtros) {
    setLoading(true);
    const result = await pessoasService.relatorioAniversario(filtros);
    if (!result.isAxiosError) {
      setDadosRelatorio(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
    return result;
  }

  return (
    <RelatoriosLayout
      title="Relatório de Cadastros"
      subgruposRelatorios={subgruposRelatoriosIntegracao}
      filtrosAplicadosImpressao={filtrosAplicadosImpressao}
      dadosRelatorioResumido={dadosRelatorio}
      setFiltrosAplicadosImpressao={setFiltrosAplicadosImpressao}
      filtrosAvancados={filtrosAvancados}
      setFiltrosAvancados={setFiltrosAvancados}
      loading={loading}
    />
  );
};

export default RelatoriosIntegracao;
