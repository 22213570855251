import shortid from "shortid";
import { formatDate } from "../../../../utils";
import { calculoDesconto, totalPagamentos, totalPedido } from "../addPedidos";

export class Pedido {
  orcamentoIds = null;
  orcamentoId = null;
  dataEmissao = null;
  operacaoFiscalId = null;
  serieDocumento = null;
  pessoaVendedorId = null;
  pessoaClienteId = null;
  pessoaProficionalId = null;
  pedidoEnderecoCliente = null;
  entrada = null;
  meioPagamentoEntradaId = null;
  quantidadeParcelas = null;
  desconto = null;
  descontoPorcentagem = null;
  acrescimo = null;
  despesas = null;
  informacaoAdicional = null;
  itens = null;
  pagamentos = null;
  vendedorObrigatorio = null;
  totalPagamentos = 0;
  totalPedido = 0;

  constructor(pedido, id = null) {
    if (id) {
      this.operacaoFiscalId = pedido.operacaoFiscalId;
      this.serieDocumento = pedido.serieDocumento;
      this.pessoaVendedorId = pedido.pessoaVendedorId;
      this.pessoaClienteId = pedido.pessoaClienteId;
      this.pessoaProficionalId = pedido.pessoaProficionalId;
      this.pedidoEnderecoCliente = pedido.pedidoEnderecoCliente;
      this.meioPagamentoEntradaId = pedido.meioPagamentoEntradaId;
      this.quantidadeParcelas = pedido.quantidadeParcelas;
      this.informacaoAdicional = pedido.informacaoAdicional;
      this.itens = pedido.itens?.length
        ? pedido.itens?.map((item) => new Item(item))
        : [];
      this.pagamentos = pedido.pagamentos?.length
        ? pedido.pagamentos.map((pagamento) => new Pagameto(pagamento))
        : [];
      this.vendedorObrigatorio = pedido.vendedorObrigatorio;
    }
    this.orcamentoIds = pedido.orcamentoIds;
    this.orcamentoId = pedido.orcamentoId;
    this.dataEmissao = pedido.dataEmissao;
    this.entrada = pedido.entrada;
    this.desconto = pedido.desconto;
    this.descontoPorcentagem = pedido.descontoPorcentagem;
    this.acrescimo = pedido.acrescimo;
    this.despesas = pedido.despesas;
  }

  setHandleChangeInput(name, value) {
    this[name] = value;
  }

  setHandleChangeDate(name, date) {
    this[name] = date != "Invalid Date" ? formatDate.toSend(date) : undefined;
  }

  setHandleChangeAutoComplete(name, value) {
    this[name] = value ? value.id : null;
    if (name === "operacaoFiscalId") {
      this.setOperacaoFiscal(value);
    }
  }

  setChangePagamentos(pagamentos) {
    this.pagamentos = pagamentos?.length
      ? pagamentos.map((pagamento) => new Pagameto(pagamento))
      : [];
    this.totalPagamentos = totalPagamentos(pagamentos);
  }

  setChangeItens(produtos) {
    this.itens = produtos?.length
      ? produtos.map((produto) => new Item(produto))
      : [];
    this.descontoPorcentagem = calculoDesconto(produtos, this, false);
    this.totalPedido = totalPedido(this, produtos);
  }

  setEnderecoCliente(enderecoAlterado, enderecoCliente) {
    if (this.pessoaClienteId) {
      const endereco = enderecoAlterado || enderecoCliente;
      if (endereco) {
        this.pedidoEnderecoCliente = {
          ...endereco,
          pessoaId: this.pessoaClienteId,
        };
        return;
      }
    }
    this.pedidoEnderecoCliente = null;
  }

  setOperacaoFiscal(operacaoFiscal) {
    this.operacaoFiscalId = operacaoFiscal.id;
    this.serieDocumento = operacaoFiscal?.serieDocumento;
    this.modeloDocumento = operacaoFiscal?.modeloDocumento;
  }

  setVendedorObrigatorioPermissao(permissao) {
    this.vendedorObrigatorio = permissao;
  }

  setDescontoPorcentagem(listaPedidosProdutos, porcentagem = false) {
    this[porcentagem ? "descontoPorcentagem" : "desconto"] = calculoDesconto(
      listaPedidosProdutos,
      this,
      false
    );
  }
}

class Item {
  id = null;
  produtoId = null;
  unidade = null;
  quantidade = null;
  codigo = null;
  valorUnitario = null;
  ncm = null;
  tributosIcmsOrigem = null;

  constructor(item) {
    this.id = item.id ? item.id : shortid.generate();
    this.produtoId = item.produtoId;
    this.unidade = item.unidade;
    this.quantidade = item.quantidade;
    this.codigo = item.codigo;
    this.valorUnitario = item.valorUnitario;
    this.ncm = item.ncm;
    this.tributosIcmsOrigem = item.tributosIcmsOrigem;
  }
}

class Pagameto {
  id = null;
  meioPagamentoId = null;
  condicaoPagamentoId = null;
  valorTitulo = null;
  vencimento = null;

  constructor(pagamento) {
    this.id = pagamento.id ? pagamento.id : shortid.generate();
    this.meioPagamentoId = pagamento.meioPagamentoId;
    this.condicaoPagamentoId = pagamento.condicaoPagamentoId;
    this.valorTitulo = pagamento.valorTitulo;
    this.vencimento = pagamento.vencimento;
  }
}
