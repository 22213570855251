import { useState, useEffect } from "react";
import {
  Button,
  Container,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  FormControlLabel,
  Grid,
  Loading,
  Switch,
  TextField,
} from "../../../components";
import { notification, ColecoesService } from "../../../services";
import { InputErros, PermisoesHelper } from "../../../helpers";
import { responseErros } from "../../../utils";
import colecaoValidator from "./middlewares/colecao.validator";

const AddColecao = ({ id, handleCloseDialog }) => {
  const [loading, setLoading] = useState(false);
  const [colecao, setColecao] = useState({
    ativado: true,
  });
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const colecoesService = new ColecoesService();
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("colecoes-visualizar");

  useEffect(() => {
    buscarColecao();
  }, []);

  const buscarColecao = async () => {
    if (!id) return;
    setLoading(true);
    const { data } = await colecoesService.getById(id);
    setColecao(data);
    setLoading(false);
  };

  const handleInputChange = (e) => {
    e.persist();
    setInputErro([]);
    setColecao((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    colecaoValidator
      .validate(colecao, { abortEarly: false })
      .then(async () => {
        if (id) {
          const response = await colecoesService.atualizar(id, colecao);
          setLoading(false);
          if (!response.isAxiosError) {
            notification.alteracaoSucesso();
            handleCloseDialog();
          } else {
            responseErros(response);
          }
        } else {
          const response = await colecoesService.cadastrar(colecao);
          setLoading(false);
          if (!response.isAxiosError) {
            notification.cadastroSucesso();
            handleCloseDialog();
          } else {
            responseErros(response);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        inputErros.set(err);
      });
  };

  return (
    <Container>
      <Card className="p-3">
        <CardHeader
          title={colecao?.id ? "Editar coleção" : "Cadastrar coleção"}
          action={
            <FormControlLabel
              control={
                <Switch
                  checked={colecao?.ativado || false}
                  onChange={() =>
                    setColecao((prevState) => ({
                      ...prevState,
                      ativado: !prevState?.ativado,
                    }))
                  }
                  name="ativado"
                  color="primary"
                />
              }
              label={colecao?.ativado ? "Ativado" : "Desativado"}
            />
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="descricao"
                name="descricao"
                label="Descrição"
                variant="outlined"
                margin="normal"
                value={colecao?.descricao || ""}
                onChange={handleInputChange}
                fullWidth
                required
                error={inputErros.get("descricao")}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            <i
              className="ph-fill ph-check-circle"
              style={{ fontSize: 22, marginRight: 10 }}
            ></i>
            Salvar
          </Button>
          <Button
            color="padrao"
            variant="contained"
            onClick={handleCloseDialog}
          >
            <i
              className="ph-fill ph-x-circle"
              style={{ fontSize: 22, marginRight: 10 }}
            ></i>
            Fechar
          </Button>
        </CardActions>
      </Card>
      <Loading loading={loading} />
    </Container>
  );
};

export default AddColecao;
