import { useState, useEffect, useRef } from "react";
import {
  Autocomplete,
  DatePicker,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from "../../../../components";
import {
  OperacaoFiscalService,
  PessoaService,
  CondicoesPagamentoService,
  MeioPagamentoService,
} from "../../../../services";
import { InputErros } from "../../../../helpers";
import {
  formatDate,
  responseErros,
  timeoutBuscaAutocomplete,
} from "../../../../utils";
import { format } from "date-fns";

const operacaoFiscalService = new OperacaoFiscalService();
const pessoaService = new PessoaService();
const condicaoPagamentoService = new CondicoesPagamentoService();
const meioPagamentoService = new MeioPagamentoService();

const FormFiltroAvancadoNfSaida = ({
  filtrosAvancados,
  setFiltrosAvancados,
  filtrosAplicadosImpressao,
  setFiltrosAplicadosImpressao,
}) => {
  const inputRefCliente = useRef();
  const inputRefProfissional = useRef();
  const [operacaoFiscalList, setOperacaoFiscalList] = useState([]);
  const [filteredOperacaoFiscalList, setFilteredOperacaoFiscalList] = useState(
    []
  );
  const [clienteList, setClienteList] = useState([]);
  const [vendedorList, setVendedorList] = useState([]);
  const [filteredVendedorList, setFilteredVendedorList] = useState([]);
  const [profissionalList, setProfissionalList] = useState([]);
  const [condicaoPagamentoList, setCondicaoPagamentoList] = useState([]);
  const [meioPagamentoList, setMeioPagamentoList] = useState([]);
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);

  const listaStatusNf = [
    {
      label: "Pendente",
      value: "PENDENTE",
      cor: "#EA9D2B",
    },
    {
      label: "Faturada",
      value: "FATURADA",
      cor: "#004780",
    },
    {
      label: "Enviada",
      value: "ENVIADA",
      cor: "#4FBB53",
    },
    {
      label: "Em Contingência",
      value: "CONTINGENCIA",
      cor: "#BFBFBF",
    },
    {
      label: "Cancelada",
      value: "CANCELADA",
      cor: "#DC3545",
    },
    {
      label: "Denegada",
      value: "DENEGADA",
      cor: "#70309F",
    },
  ];

  const listaFinalidade = [
    { label: "NFE NORMAL", value: "NFE_NORMAL" },
    { label: "NFE COMPLEMENTAR", value: "NFE_COMPLEMENTAR" },
    { label: "NFE AJUSTE", value: "NFE_AJUSTE" },
    { label: "NFE DEVOLUCAO", value: "DEVOLUCAO" },
    { label: "NÃO SE APLICA", value: "NAO_SE_APLICA" },
  ];

  const valueAutocomplete = (lista, valueName) => {
    const objeto = lista.find(
      (objeto) => objeto.id === filtrosAvancados[valueName]
    );
    return objeto ? objeto : undefined;
  };

  const valueInput = (valueName) => {
    return filtrosAvancados[valueName] ? filtrosAvancados[valueName] : "";
  };

  const valueDate = (valueName) => {
    return filtrosAvancados[valueName] ? filtrosAvancados[valueName] : null;
  };

  const onChangeInput = (event) => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      [event.target.id]: event.target.value || null,
    });
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value || null,
    }));
  };

  const onChangeAutocomplete = (id, value, name) => {
    const filtrosAvancadosTemp = {
      ...filtrosAvancados,
      [id]: value ? value.id : undefined,
    };
    const filtrosAvancadosImpressaoTemp = {
      ...filtrosAplicadosImpressao,
      [name]: value ? value.nomeRazaoSocial ?? value.descricao : undefined,
    };
    setFiltrosAvancados(filtrosAvancadosTemp);
    setFiltrosAplicadosImpressao(filtrosAvancadosImpressaoTemp);
  };

  const onChangeSelect = (event, name) => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      [event.target.name]: event.target.value ?? null,
    });
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      [name]: event.target.value ?? null,
    }));
  };

  const onChangeDate = (id, date, name) => {
    const isValidDate = (date) => date instanceof Date && !isNaN(date);
    if (!date || !isValidDate(date)) {
      date = "";
    }
    if (
      date &&
      date.getDate() !== undefined &&
      date.getMonth() !== undefined &&
      date.getFullYear() > 2000
    ) {
      const filtroAvancado = {
        ...filtrosAvancados,
        [id]: formatDate.toSend(date),
      };
      const filtroImpressao = {
        ...filtrosAplicadosImpressao,
        [name]: format(date, "dd/MM/yyyy"),
      };
      if (
        id.includes("Start") &&
        (!filtroAvancado[id.replace("Start", "End")] ||
          filtroAvancado[id.replace("Start", "End")] === "")
      ) {
        filtroAvancado[id.replace("Start", "End")] = formatDate.toSend(date);
        filtroImpressao[name.replace("De", "Até")] = format(date, "dd/MM/yyyy");
      }
      if (
        id.includes("End") &&
        (!filtroAvancado[id.replace("End", "Start")] ||
          filtroAvancado[id.replace("End", "Start")] === "")
      ) {
        filtroAvancado[id.replace("End", "Start")] = formatDate.toSend(date);
        filtroImpressao[name.replace("Até", "De")] = format(date, "dd/MM/yyyy");
      }
      filtroAvancado.porIntervalo = [
        {
          coluna: "dataLancamento",
          de: filtroAvancado.dataLancamentoStart,
          ate: filtroAvancado.dataLancamentoEnd,
        },
      ];
      setFiltrosAvancados(filtroAvancado);
      setFiltrosAplicadosImpressao(filtroImpressao);
    }
  };

  useEffect(() => {
    buscarOperacoesFiscais();
    buscarCondicoesPagamento();
    buscarMeiosPagamento();
    buscarVendedores();
  }, []);

  const buscarOperacoesFiscais = () => {
    const filtrosOperacoesFiscais = {
      ativado: true,
      tipoOperacao: "SAIDA",
      restritiva: true,
      porIntervalo: [],
      finalidade: [
        "NAO_SE_APLICA",
        "NFE_NORMAL",
        "NFE_COMPLEMENTAR",
        "NFE_AJUSTE",
      ],
    };
    operacaoFiscalService
      .getAllFiltroAvancado(filtrosOperacoesFiscais)
      .then((result) => {
        if (!result.isAxiosError) {
          setOperacaoFiscalList(result.data.rows);
          setFilteredOperacaoFiscalList(result.data.rows);
        } else {
          responseErros(result);
        }
      });
  };

  const buscarClientes = () => {
    const filtros = {
      nonPaginated: true,
      tiposPessoaId: 1,
    };
    pessoaService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setClienteList(result.data);
      } else {
        responseErros(result);
      }
    });
    setLoadingAutoComplete(false);
  };

  const buscarVendedores = () => {
    const filtro = {
      nonPaginated: true,
      vendedor: true,
      tiposPessoaId: 4,
    };
    pessoaService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setVendedorList(result.data);
        setFilteredVendedorList(result.data);
      } else {
        responseErros(result);
      }
    });
    setLoadingAutoComplete(false);
  };

  const buscarProfissional = () => {
    const filtro = {
      nonPaginated: true,
      tiposPessoaId: 5,
    };
    pessoaService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setProfissionalList(result.data);
      } else {
        responseErros(result);
      }
    });
    setLoadingAutoComplete(false);
  };

  const buscarCondicoesPagamento = () => {
    condicaoPagamentoService.getAll({ ativado: true }).then((result) => {
      if (!result.isAxiosError) {
        setCondicaoPagamentoList(result.data.rows);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarMeiosPagamento = () => {
    meioPagamentoService.getAll({ ativado: true }).then((result) => {
      if (!result.isAxiosError) {
        setMeioPagamentoList(result.data.rows);
      } else {
        responseErros(result);
      }
    });
  };

  const onInputChangePessoa = (event, tiposPessoaId) => {
    const inputRefMap = {
      1: {
        inputRef: inputRefCliente,
        buscar: buscarClientes,
      },
      5: {
        inputRef: inputRefProfissional,
        buscar: buscarProfissional,
      },
    };
    const { inputRef, buscar } = inputRefMap[tiposPessoaId] || {};
    if (
      event.key === "Enter" &&
      inputRef?.current === document.activeElement &&
      event.target?.value?.length > 2
    ) {
      setLoadingAutoComplete(true);
      const filtros = {
        nomeRazaoSocial: event.target.value,
        apelidoFantasia: event.target.value,
      };
      timeoutBuscaAutocomplete(buscar?.(filtros));
    }
  };

  const onChangeMultipleVendedores = (valores) => {
    if (!valores?.length) {
      resetarFiltrosDeVendedores();
      return;
    }
    setFiltrosAvancados((prevState) => ({
      ...prevState,
      int_vendedor_id: valores.map((valor) => valor.id),
      vendedores: valores.map(({ id, nomeRazaoSocial }) => ({
        id,
        nomeRazaoSocial,
      })),
    }));
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      Vendedores: valores.map((valor) => {
        return (
          <div key={valor.id} className="d-flex align-items-center">
            {valor.nomeRazaoSocial}
          </div>
        );
      }),
    }));
    setFilteredVendedorList(
      vendedorList.filter(
        (vendedor) => !valores.map(({ id }) => id).includes(vendedor.id)
      )
    );
  };

  const onChangeMultipleOperacoes = (valores) => {
    if (!valores?.length) {
      resetarFiltrosDeOperacoes();
      return;
    }
    setFiltrosAvancados((prevState) => ({
      ...prevState,
      operacoesFiscais: valores,
      operacaoFiscalId: valores.map((valor) => valor.id),
    }));
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      "Operações-Fiscais": valores.map((valor) => {
        return (
          <div key={valor.id} className="d-flex align-items-center">
            {valor.descricao}
          </div>
        );
      }),
    }));
    setFilteredOperacaoFiscalList(
      operacaoFiscalList.filter(
        (operacao) => !valores.map(({ id }) => id).includes(operacao.id)
      )
    );
  };

  function resetarFiltrosDeVendedores() {
    setFiltrosAvancados((prevState) => {
      const prevStateTemp = { ...prevState };
      delete prevStateTemp.vendedores;
      return prevStateTemp;
    });
    setFiltrosAplicadosImpressao((prevState) => {
      const prevStateTemp = { ...prevState };
      delete prevStateTemp["Vendedores"];
      return prevStateTemp;
    });
    setFilteredVendedorList(vendedorList);
  }

  function resetarFiltrosDeOperacoes() {
    setFiltrosAvancados((prevState) => {
      const prevStateTemp = { ...prevState };
      delete prevStateTemp.operacoesFiscais;
      return prevStateTemp;
    });
    setFiltrosAplicadosImpressao((prevState) => {
      const prevStateTemp = { ...prevState };
      delete prevStateTemp["Operações-Fiscais"];
      return prevStateTemp;
    });
    setFilteredOperacaoFiscalList(operacaoFiscalList);
  }

  return (
    <Grid container spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              multiple
              id="operacoesFiscais"
              name="Operações-Fiscais"
              options={filteredOperacaoFiscalList}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => (option ? option.descricao : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Operação Fiscal"
                  variant="outlined"
                  error={inputErros.get("operacaoFiscalId")}
                />
              )}
              onChange={(_, newValue) => onChangeMultipleOperacoes(newValue)}
              value={filtrosAvancados?.operacoesFiscais || []}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Select
            label="Finalidade"
            id="finalidade"
            name="finalidade"
            margin="normal"
            value={valueInput("finalidade")}
            onChange={(event) => onChangeSelect(event, "finalidade")}
            error={inputErros.get("finalidade")}
          >
            {listaFinalidade.map((finalidade, index) => (
              <MenuItem key={`${index}-finalidade`} value={finalidade.value}>
                {finalidade.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="numeroDocumento"
            name="Número-Documento"
            label="Nº Documento"
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={onChangeInput}
            value={valueInput("numeroDocumento")}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="serieDocumento"
            name="Série-Documento"
            label="Série Documento"
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={onChangeInput}
            value={valueInput("serieDocumento")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="clienteId"
              name="Cliente"
              options={clienteList}
              autoHighlight
              getOptionLabel={(option) => option?.nomeRazaoSocial ?? ""}
              getOptionSelected={(option, value) =>
                option?.nomeRazaoSocial === value?.nomeRazaoSocial
              }
              loading={loadingAutoComplete}
              loadingText="Carregando"
              noOptionsText="Digite e pressione Enter"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Cliente"
                  variant="outlined"
                  onKeyDown={(e) => onInputChangePessoa(e, 1)}
                  inputRef={inputRefCliente}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              onChange={(e, value) =>
                onChangeAutocomplete("clienteId", value, "Cliente")
              }
              value={valueAutocomplete(clienteList, "clienteId")}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              multiple
              id="vendedores"
              name="Vendedores"
              options={filteredVendedorList}
              autoHighlight
              getOptionLabel={(option) => option?.nomeRazaoSocial ?? ""}
              getOptionSelected={(option, value) =>
                option?.nomeRazaoSocial === value?.nomeRazaoSocial
              }
              loading={loadingAutoComplete}
              loadingText="Carregando"
              noOptionsText="Digite e pressione Enter"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Vendedores"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              onChange={(_, newValue) => {
                onChangeMultipleVendedores(newValue);
              }}
              value={filtrosAvancados?.vendedores || []}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="pessoaProficionalId"
              name="Profissional"
              options={profissionalList}
              autoHighlight
              getOptionLabel={(option) =>
                option.id && option.nomeRazaoSocial
                  ? `${option.id} - ${option.nomeRazaoSocial}`
                  : ""
              }
              loading={loadingAutoComplete}
              loadingText="Carregando"
              noOptionsText="Digite e pressione Enter"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Profissional"
                  variant="outlined"
                  onKeyDown={(e) => onInputChangePessoa(e, 5)}
                  inputRef={inputRefProfissional}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              onChange={(e, value) =>
                onChangeAutocomplete(
                  "pessoaProficionalId",
                  value,
                  "Profissional"
                )
              }
              value={valueAutocomplete(profissionalList, "pessoaProficionalId")}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="condicaoPagamentoId"
              name="Condição-de-Pagamento"
              options={condicaoPagamentoList}
              autoHighlight
              getOptionLabel={(option) =>
                option.id && option.descricao
                  ? `${option.id} - ${option.descricao}`
                  : ""
              }
              getOptionSelected={(option, value) =>
                option?.descricao === value?.descricao
              }
              noOptionsText="Sem opções"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Condição de Pagamento"
                  variant="outlined"
                />
              )}
              onChange={(e, value) =>
                onChangeAutocomplete(
                  "condicaoPagamentoId",
                  value,
                  "Condição-de-Pagamento"
                )
              }
              value={valueAutocomplete(
                condicaoPagamentoList,
                "condicaoPagamentoId"
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="meioPagamentoId"
              name="Meio-de-Pagamento"
              options={meioPagamentoList}
              autoHighlight
              getOptionLabel={(option) =>
                option.id && option.descricao
                  ? `${option.id} - ${option.descricao}`
                  : ""
              }
              getOptionSelected={(option, value) =>
                option?.descricao === value?.descricao
              }
              noOptionsText="Sem opções"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Meios de pagamento"
                  variant="outlined"
                />
              )}
              onChange={(e, value) =>
                onChangeAutocomplete(
                  "meioPagamentoId",
                  value,
                  "Meio-de-Pagamento"
                )
              }
              value={valueAutocomplete(meioPagamentoList, "meioPagamentoId")}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            id="dataLancamentoStart"
            name="Data-Lançamento-De"
            label="Data Lançamento De"
            margin
            format="dd/MM/yyyy"
            value={valueDate("dataLancamentoStart")}
            onChange={(date) =>
              onChangeDate("dataLancamentoStart", date, "Data-Lançamento-De")
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            id="dataLancamentoEnd"
            name="Data-Lançamento-Até"
            label="Data Lançamento Até"
            margin
            format="dd/MM/yyyy"
            value={valueDate("dataLancamentoEnd")}
            onChange={(date) =>
              onChangeDate("dataLancamentoEnd", date, "Data-Lançamento-Até")
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Select
            id="status"
            name="status"
            label="Status"
            margin="normal"
            fullWidth
            onChange={(event) => onChangeSelect(event, "Status")}
            value={valueInput("status")}
          >
            {listaStatusNf.map((status, index) => (
              <MenuItem key={index} value={status.value}>
                <i
                  className="ph-fill ph-circle"
                  style={{ color: `${status.cor}` }}
                ></i>
                &nbsp;{status.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancadoNfSaida;
