import { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  DatePicker,
  TextFielDecimalNumbers,
  CurrencyInput,
  Autocomplete,
} from "../../../../components";
import { InputErros, value } from "../../../../helpers";
import {
  formatDate,
  responseErros,
  unformatPrice,
  voltar,
} from "../../../../utils";
import pagamentoValidator from "../middlewares/pagamento.validator";
import Swal from "sweetalert2";
import { ContasReceberService, notification } from "../../../../services";
import { RetirarBaixaDialog } from "./";
import { useImportContext } from "../../../../contexts/import.context";
import {
  calcularValorAPagar,
  converterPorcentagemParaValor,
  converterValorParaPorcentagem,
  getAtrasoDias,
  totalDespesas,
} from "../add-contas-receber";
import { Pagamento } from "../entities";

const DadosPagamento = ({
  id,
  setLoading,
  contaReceber,
  setDadosContaReceber,
  pagamento,
  setPagamento,
  pagadorList,
  listaContas,
  listaMeiosPagamentos,
  dadosPagamentoList,
  setDadosPagamentoList,
  validarStatusCaixa,
  setReciboData,
  handlePrint,
  parametrosFiscais,
  cadastrarCaixa,
  colaborador,
}) => {
  const { initialCollapses } = useImportContext();
  const [inputErro, setInputErro] = useState([]);
  const [openRetirarBaixaDialog, setOpenRetirarBaixaDialog] = useState(false);
  const contasReceberService = new ContasReceberService();
  const inputErros = new InputErros(inputErro, setInputErro);

  useEffect(() => {
    let pagamentoTemp = { ...pagamento };

    if (contaReceber.status === "BAIXADO") {
      const {
        totalDescontos: desconto,
        totalJuros: juros,
        totalMulta: multa,
        total: valor,
      } = totalDespesas(dadosPagamentoList);
      pagamentoTemp = { ...pagamentoTemp, desconto, juros, multa, valor };
    } else {
      pagamentoTemp.valor = contaReceber?.saldoReceber;
    }

    const atraso = getAtrasoDias(
      contaReceber?.dataVencimento,
      pagamento?.dataPagamento
    );

    if (
      atraso <= parametrosFiscais?.carencia ||
      contaReceber?.status === "BAIXADO"
    ) {
      pagamentoTemp.multaPorcentagem = null;
      pagamentoTemp.jurosPorcentagem = null;
    } else {
      pagamentoTemp = calculoPorcentagemMultaJurosPadrao(
        pagamento.multaPorcentagem || parametrosFiscais.multaPorcentagem,
        pagamento.jurosPorcentagem || parametrosFiscais.jurosPorcentagem
      );
    }
    setPagamento(pagamentoTemp);
  }, [contaReceber, dadosPagamentoList]);

  const handleValorPagamentoChange = (event) => {
    const pagamentoTemp = {
      ...pagamento,
      [event.target.name]: unformatPrice(event.target.value),
    };
    pagamentoTemp.desconto = 0;
    pagamentoTemp.descontoPorcentagem = 0;
    setPagamento(pagamentoTemp);
  };

  function calculoPorcentagemMultaJurosPadrao(
    multaPorcentagem,
    jurosPorcentagem
  ) {
    const pagamentoAtualizado = {
      ...pagamento,
      multaPorcentagem,
      jurosPorcentagem,
      multa: converterPorcentagemParaValor(multaPorcentagem, contaReceber),
      juros: converterPorcentagemParaValor(
        jurosPorcentagem,
        contaReceber,
        "jurosPorcentagem"
      ),
    };

    pagamentoAtualizado.valor = calcularValorAPagar(
      pagamentoAtualizado,
      dadosPagamentoList,
      contaReceber
    );

    return pagamentoAtualizado;
  }

  const handleDateChange = (date, fieldName) => {
    if (!date) {
      date = "Invalid Date";
    }
    const dataTemp = date != "Invalid Date" ? formatDate.toSend(date) : null;
    if (fieldName == "dataPagamento" && dataTemp && pagamento.bancoCaixaId) {
      validarStatusCaixa(formatDate.toSend(date), pagamento.bancoCaixaId);
    }
    setPagamento((prevState) => ({
      ...prevState,
      [fieldName]: dataTemp,
    }));
  };

  const onChangeAutocomplete = (name, value) => {
    const valueTemp = value ? value.id : undefined;
    if (name == "bancoCaixaId" && valueTemp && pagamento.dataPagamento) {
      validarStatusCaixa(pagamento.dataPagamento, valueTemp);
    }
    setPagamento((prevState) => ({
      ...prevState,
      [name]: value ? value.id : undefined,
    }));
  };

  const handleInputValorPorcentagem = (event) => {
    const propriedade = event.target.name;
    const valor = Number(unformatPrice(event.target.value));
    if (propriedade === "desconto" && valor > contaReceber?.saldoReceber) {
      return notification.alertaGenericos(
        "Desconto maior que o saldo a receber"
      );
    }
    const chave = propriedade.endsWith("Porcentagem")
      ? `${propriedade.substring(0, propriedade.length - 11)}`
      : `${propriedade}Porcentagem`;
    const converter = {
      multa: converterValorParaPorcentagem,
      juros: converterValorParaPorcentagem,
      desconto: converterValorParaPorcentagem,
      multaPorcentagem: converterPorcentagemParaValor,
      jurosPorcentagem: converterPorcentagemParaValor,
      descontoPorcentagem: converterPorcentagemParaValor,
    };
    const pagamentoAtualizado = {
      ...pagamento,
      [propriedade]: valor,
      [chave]: converter[propriedade](
        valor,
        contaReceber,
        event.target.name,
        pagamento,
        propriedade
      ),
    };
    setPagamento({
      ...pagamentoAtualizado,
      valor: calcularValorAPagar(
        pagamentoAtualizado,
        dadosPagamentoList,
        contaReceber
      ),
    });
  };

  const validarBaixarTitulo = async (event) => {
    event.preventDefault();
    const dadosPagamento = new Pagamento(
      pagamento,
      dadosPagamentoList,
      contaReceber
    );
    const caixaAberto = await validarStatusCaixa(
      pagamento?.dataPagamento,
      dadosPagamento?.bancoCaixaId
    );
    if (!caixaAberto) {
      const caixaSelecionado = listaContas.find(
        (conta) => conta.id === dadosPagamento?.bancoCaixaId
      );
      if (!caixaSelecionado?.aberturaAutomatica) {
        const retornoAlerta = await notification.confirmacao(
          "Abertura de Caixa",
          "Caixa se encontra fechado, confirma a abertura do caixa?"
        );
        if (retornoAlerta.isConfirmed) {
          await cadastrarCaixa(new Date(), dadosPagamento?.bancoCaixaId, true);
        }
      } else {
        await cadastrarCaixa(new Date(), dadosPagamento?.bancoCaixaId, true);
      }
    }
    setInputErro([]);
    pagamentoValidator
      .validate(dadosPagamento, { abortEarly: false })
      .then(() => {
        if (dadosPagamento.validaValor()) {
          cadastrarPagamentos(dadosPagamento);
        }
      })
      .catch((err) => {
        inputErros.set(err);
      });
  };

  const cadastrarPagamentos = async (dadosPagamento) => {
    const cliente = pagadorList.find(
      (item) => item.id == contaReceber.pagadorId
    );
    if (
      dadosPagamento.totalCalculado() !==
        contaReceber.saldoReceber.toFixed(2) &&
      !pagamento.desconto
    ) {
      const retornoAlerta = await notification.confirmacao(
        "Baixar Parcial",
        "Confirma a baixa parcial do título?"
      );
      if (retornoAlerta.isConfirmed) {
        setLoading(true);
        const res = await contasReceberService.cadastrarPagamentos(
          id,
          dadosPagamento
        );
        setLoading(false);
        if (!res.isAxiosError) {
          setDadosPagamentoList((prevState) => [...prevState, res.data]);
          setReciboData({
            cliente,
            titulo: contaReceber,
            pagamentos: [res.data],
          });
          const retornoAlerta = await Swal.fire({
            title: "Baixa parcial feita com sucesso!",
            text: `Número do título: ${contaReceber?.numeroTitulo}`,
            icon: "success",
            confirmButtonText: "OK",
          });
          if (retornoAlerta.isConfirmed) {
            initialCollapses();
            voltar();
            handlePrint();
          }
        } else {
          setLoading(false);
          responseErros(res);
        }
      }
    } else {
      const retornoAlerta = await notification.confirmacao(
        "Baixar Título",
        "Confirma a baixa do título?"
      );
      if (retornoAlerta.isConfirmed) {
        setLoading(true);
        const res = await contasReceberService.cadastrarPagamentos(
          id,
          dadosPagamento
        );
        setLoading(false);
        if (!res.isAxiosError) {
          setDadosPagamentoList((prevState) => [...prevState, res.data]);
          setReciboData({
            cliente,
            titulo: contaReceber,
            pagamentos: [res.data],
          });
          const retornoAlerta = await Swal.fire({
            title: "Título quitado com sucesso!",
            text: `Número do título: ${contaReceber?.numeroTitulo}`,
            icon: "success",
            confirmButtonText: "OK",
          });
          if (retornoAlerta.isConfirmed) {
            initialCollapses();
            voltar();
            handlePrint();
          }
        } else {
          setLoading(false);
          responseErros(res);
        }
      } else {
        setLoading(false);
      }
    }
  };

  const handleOpenRetirarBaixa = () => {
    setOpenRetirarBaixaDialog(true);
  };

  return (
    <>
      <form onSubmit={validarBaixarTitulo}>
        <Grid className="mt-3" container spacing={2}>
          <Grid item xs={3}>
            <DatePicker
              id="dataPagamento"
              name="dataPagamento"
              label="Data de Pagamento"
              format="dd/MM/yyyy"
              maxDate={new Date()}
              variant="outlined"
              disabled={
                contaReceber?.status === "BAIXADO" ||
                contaReceber?.status === "RENEGOCIADO" ||
                contaReceber?.status === "CANCELADO"
              }
              margin
              error={inputErro.indexOf("dataPagamento") != -1 ? true : false}
              fullWidth
              value={pagamento?.dataPagamento}
              onChange={(date) => handleDateChange(date, "dataPagamento")}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="outlined" margin="normal" fullWidth>
              <Autocomplete
                id="bancoCaixaId"
                name="bancoCaixaId"
                required
                options={listaContas}
                autoHighlight
                getOptionLabel={(option) => (option.nome ? option.nome : "")}
                getOptionSelected={(option, value) =>
                  option.nome === value.nome
                }
                noOptionsText="Sem opções"
                disabled={
                  contaReceber?.status === "BAIXADO" ||
                  contaReceber?.status === "RENEGOCIADO" ||
                  contaReceber?.status === "CANCELADO" ||
                  colaborador?.bancoCaixaId
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Banco/Caixa"
                    variant="outlined"
                    disabled={
                      contaReceber?.status === "BAIXADO" ||
                      contaReceber?.status === "RENEGOCIADO" ||
                      contaReceber?.status === "CANCELADO" ||
                      colaborador?.bancoCaixaId
                    }
                    error={inputErros.get("bancoCaixaId")}
                  />
                )}
                onChange={(_, newValue) => {
                  onChangeAutocomplete("bancoCaixaId", newValue);
                }}
                value={value.autoComplete(listaContas, pagamento?.bancoCaixaId)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="outlined" margin="normal" fullWidth>
              <Autocomplete
                id="meioPagamentoId"
                name="meioPagamentoId"
                required
                options={listaMeiosPagamentos}
                autoHighlight
                getOptionLabel={(option) =>
                  option.descricao ? option.descricao : ""
                }
                noOptionsText="Sem opções"
                disabled={
                  contaReceber?.status === "BAIXADO" ||
                  contaReceber?.status === "RENEGOCIADO" ||
                  contaReceber?.status === "CANCELADO"
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Meio de Pagamento"
                    variant="outlined"
                    required
                    disabled={
                      contaReceber?.status === "BAIXADO" ||
                      contaReceber?.status === "RENEGOCIADO" ||
                      contaReceber?.status === "CANCELADO"
                    }
                    error={inputErros.get("meioPagamentoId")}
                  />
                )}
                onChange={(_, newValue) =>
                  onChangeAutocomplete("meioPagamentoId", newValue)
                }
                value={value.autoComplete(
                  listaMeiosPagamentos,
                  pagamento?.meioPagamentoId
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="atraso"
              name="atraso"
              label="Atraso (Dias)"
              variant="outlined"
              margin="normal"
              type="number"
              disabled
              value={getAtrasoDias(
                contaReceber?.dataVencimento,
                pagamento?.dataPagamento
              )}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={2}>
            <TextFielDecimalNumbers
              id="multaPorcentagem"
              name="multaPorcentagem"
              label="Multa (%)"
              variant="outlined"
              disabled={
                contaReceber?.status === "BAIXADO" ||
                contaReceber?.status === "RENEGOCIADO" ||
                contaReceber?.status === "CANCELADO"
              }
              value={pagamento?.multaPorcentagem || 0}
              onBlur={handleInputValorPorcentagem}
            />
          </Grid>
          <Grid item xs={2}>
            <CurrencyInput
              id="multa"
              name="multa"
              label="Multa (R$)"
              margin="normal"
              variant="outlined"
              disabled={
                contaReceber?.status === "BAIXADO" ||
                contaReceber?.status === "RENEGOCIADO" ||
                contaReceber?.status === "CANCELADO"
              }
              value={pagamento?.multa || 0}
              onBlur={handleInputValorPorcentagem}
            />
          </Grid>
          <Grid item xs={2}>
            <TextFielDecimalNumbers
              id="jurosPorcentagem"
              name="jurosPorcentagem"
              label="Juros (%)"
              variant="outlined"
              disabled={
                contaReceber?.status === "BAIXADO" ||
                contaReceber?.status === "RENEGOCIADO" ||
                contaReceber?.status === "CANCELADO"
              }
              value={pagamento?.jurosPorcentagem || 0}
              onBlur={handleInputValorPorcentagem}
            />
          </Grid>
          <Grid item xs={2}>
            <CurrencyInput
              id="juros"
              name="juros"
              label="Juros (R$)"
              margin="normal"
              variant="outlined"
              disabled={
                contaReceber?.status === "BAIXADO" ||
                contaReceber?.status === "RENEGOCIADO" ||
                contaReceber?.status === "CANCELADO"
              }
              value={pagamento?.juros || 0}
              onBlur={handleInputValorPorcentagem}
            />
          </Grid>
          <Grid item xs={2}>
            <TextFielDecimalNumbers
              id="descontoPorcentagem"
              name="descontoPorcentagem"
              label="Desconto (%)"
              variant="outlined"
              value={
                pagamento?.descontoPorcentagem
                  ? pagamento.descontoPorcentagem
                  : ""
              }
              disabled={
                contaReceber?.status === "BAIXADO" ||
                contaReceber?.status === "RENEGOCIADO" ||
                contaReceber?.status === "CANCELADO"
              }
              onBlur={handleInputValorPorcentagem}
            />
          </Grid>
          <Grid item xs={2}>
            <CurrencyInput
              id="desconto"
              name="desconto"
              label="Desconto (R$)"
              margin="normal"
              variant="outlined"
              disabled={
                contaReceber?.status === "BAIXADO" ||
                contaReceber?.status === "RENEGOCIADO" ||
                contaReceber?.status === "CANCELADO"
              }
              value={pagamento?.desconto || 0}
              onBlur={handleInputValorPorcentagem}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className="d-flex justify-content-end">
          <Grid item xs={2}>
            <CurrencyInput
              id="valor"
              name="valor"
              label={
                contaReceber?.status === "BAIXADO" ||
                contaReceber?.status === "RENEGOCIADO" ||
                contaReceber?.status === "CANCELADO"
                  ? "Valor Pago (R$)"
                  : "Valor a Receber (R$)"
              }
              margin="normal"
              variant="outlined"
              disabled={
                contaReceber?.status === "BAIXADO" ||
                contaReceber?.status === "RENEGOCIADO" ||
                contaReceber?.status === "CANCELADO"
              }
              error={inputErros.get("valor")}
              value={pagamento?.valor || 0}
              onBlur={handleValorPagamentoChange}
            />
          </Grid>
          <Grid item xs={2} className="mt-3">
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="success"
              disabled={
                contaReceber?.status === "BAIXADO" ||
                contaReceber?.status === "RENEGOCIADO" ||
                contaReceber?.status === "CANCELADO"
              }
              margin="normal"
              style={{ width: "100%", padding: "13px" }}
            >
              Baixar Título
            </Button>
          </Grid>
          {contaReceber?.status !== "ABERTO" && (
            <Grid item xs={2} className="mt-3">
              <Button
                size="large"
                variant="contained"
                color="padrao"
                margin="normal"
                onClick={handleOpenRetirarBaixa}
                style={{ width: "100%", padding: "13px" }}
              >
                Retirar Baixa
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
      <RetirarBaixaDialog
        id={id}
        open={openRetirarBaixaDialog}
        setOpen={setOpenRetirarBaixaDialog}
        getAtrasoDias={getAtrasoDias}
        listaContas={listaContas}
        contaReceber={contaReceber}
        pagamento={pagamento}
        pagadorList={pagadorList}
        listaMeiosPagamentos={listaMeiosPagamentos}
        dadosPagamentoList={dadosPagamentoList}
        setDadosPagamentoList={setDadosPagamentoList}
        setDadosContaReceber={setDadosContaReceber}
      />
    </>
  );
};

export default DadosPagamento;
