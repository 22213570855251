import { useState, useEffect, useRef } from "react";
import {
  Autocomplete,
  DatePicker,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from "../../../../components";
import {
  ColecoesService,
  EtiquetasService,
  GrupoProdutosService,
  MarcasService,
  ProdutosService,
} from "../../../../services";
import { value } from "../../../../helpers";
import { formatDate, responseErros } from "../../../../utils";
import { format } from "date-fns";

const FormFiltroAvancadoAnaliseProdutosVendidos = ({
  filtrosAvancados,
  setFiltrosAvancados,
  filtrosAplicadosImpressao,
  setFiltrosAplicadosImpressao,
}) => {
  const [grupoProdutoList, setGrupoProdutoList] = useState([]);
  const [listaProdutos, setListaProdutos] = useState([]);
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [etiquetas, setEtiquetas] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [colecoes, setColecoes] = useState([]);
  const colecoesService = new ColecoesService();
  const marcasService = new MarcasService();
  const produtoService = new ProdutosService();
  const etiquetasService = new EtiquetasService();
  const grupoProdutosService = new GrupoProdutosService();

  const inputRef = useRef();
  const listaTipos = [
    {
      label: "Simples",
      value: "simples",
    },
    {
      label: "Grade",
      value: "grade",
    },
    {
      label: "Kit",
      value: "kit",
    },
  ];

  useEffect(() => {
    if (filtrosAvancados?.produtoId) {
      buscarProdutos({ id: filtrosAvancados.produtoId });
    }
    buscarGruposProduto();
    buscarMarcas();
    buscarColecoes();
    buscarEtiquetas();
  }, []);

  const valueAutocomplete = (lista, valueName) => {
    const objeto = lista.find(
      (objeto) => objeto.id === filtrosAvancados[valueName]
    );
    return objeto ? objeto : undefined;
  };

  const valueInput = (valueName) => {
    return filtrosAvancados[valueName] ? filtrosAvancados[valueName] : "";
  };

  const valueDate = (valueName) => {
    return filtrosAvancados[valueName] ? filtrosAvancados[valueName] : null;
  };

  const onChangeSelect = (event, name) => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      [event.target.name]: event.target.value ?? null,
    });
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      [name]: event.target.value ?? null,
    }));
  };

  const onChangeAutocomplete = (id, value, name) => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      [id]: value ? value.id : undefined,
    });
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      [name]: value ? value.descricao : undefined,
    }));
  };

  const onChangeDate = (id, date, name) => {
    const isValidDate = (date) => date instanceof Date && !isNaN(date);
    if (!date || !isValidDate(date)) {
      date = "";
    }
    if (
      date &&
      date.getDate() !== undefined &&
      date.getMonth() !== undefined &&
      date.getFullYear() > 2000
    ) {
      const filtroAvancado = {
        ...filtrosAvancados,
        [id]: formatDate.toSend(date),
      };
      const filtroImpressao = {
        ...filtrosAplicadosImpressao,
        [name]: format(date, "dd/MM/yyyy"),
      };
      if (
        id.includes("Start") &&
        (!filtroAvancado[id.replace("Start", "End")] ||
          filtroAvancado[id.replace("Start", "End")] === "")
      ) {
        filtroAvancado[id.replace("Start", "End")] = formatDate.toSend(date);
        filtroImpressao[name.replace("De", "Até")] = format(date, "dd/MM/yyyy");
      }
      if (
        id.includes("End") &&
        (!filtroAvancado[id.replace("End", "Start")] ||
          filtroAvancado[id.replace("End", "Start")] === "")
      ) {
        filtroAvancado[id.replace("End", "Start")] = formatDate.toSend(date);
        filtroImpressao[name.replace("Até", "De")] = format(date, "dd/MM/yyyy");
      }
      filtroAvancado.porIntervalo = [
        {
          coluna: "data",
          de: filtroAvancado.dataStart,
          ate: filtroAvancado.dataEnd,
        },
      ];
      setFiltrosAvancados(filtroAvancado);
      setFiltrosAplicadosImpressao(filtroImpressao);
    }
  };

  const buscarGruposProduto = () => {
    const filtros = {
      nonPaginated: true,
    };
    grupoProdutosService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setGrupoProdutoList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const onInputChangeProdutos = (event) => {
    if (event.key === "Enter" && inputRef.current === document.activeElement) {
      const eventValue = event.target?.value;
      if (eventValue?.length >= 2) {
        const filtros = {
          codigo: eventValue,
          codigoBarra: eventValue,
          descricao: eventValue,
        };
        setLoadingAutoComplete(true);
        buscarProdutos(filtros);
      }
    }
  };

  const onChangeMultiple = (valores, name) => {
    if (!valores?.length) {
      resetarFiltros(name);
      return;
    }
    setFiltrosAvancados((prevState) => ({
      ...prevState,
      [name]: valores.map(({ id }) => id),
    }));
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      [formatChaveFiltroImpressao(name)]: formatValorFiltroImpressao(
        valores,
        name
      ),
    }));
  };

  function resetarFiltros(name) {
    setFiltrosAvancados((prevState) => {
      const prevStateTemp = { ...prevState };
      delete prevStateTemp[name];
      return prevStateTemp;
    });

    setFiltrosAplicadosImpressao((prevState) => {
      const prevStateTemp = { ...prevState };
      delete prevStateTemp[formatChaveFiltroImpressao(name)];
      return prevStateTemp;
    });
  }

  function formatChaveFiltroImpressao(name) {
    return name.endsWith("Id") ? name.slice(0, -2).toUpperCase() : name;
  }

  function formatValorFiltroImpressao(valores, name) {
    switch (name) {
      case "etiquetasId":
        return valores.map((valor) => renderInputEtiqueta(valor));
      case "marcasId":
        return valores.map((valor) => valor.nome);
      case "colecoesId":
        return valores.map((valor) => valor.descricao);
      default:
        return valores;
    }
  }

  const buscarProdutos = (filtros) => {
    const filtro = {
      ...filtros,
      ativado: true,
      limite: 20,
    };
    produtoService.getAllFiltroAvancado(filtro).then((result) => {
      setLoadingAutoComplete(false);
      if (!result.isAxiosError) {
        setListaProdutos(result.data.rows);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarEtiquetas = async function () {
    const filtros = {
      nonPaginated: true,
      ativado: true,
      tipo: "PRODUTO",
    };
    const result = await etiquetasService.getAll(filtros);
    if (!result.isAxiosError) {
      setEtiquetas(result.data);
    } else {
      responseErros(result);
    }
  };

  const buscarMarcas = async function (filter) {
    const result = await marcasService.getAll(filter);
    if (!result.isAxiosError) {
      setMarcas(result.data.rows);
    } else {
      responseErros(result);
    }
  };

  const buscarColecoes = async function (filter) {
    const result = await colecoesService.getAll(filter);
    if (!result.isAxiosError) {
      setColecoes(result.data.rows);
    } else {
      responseErros(result);
    }
  };

  const renderInputEtiqueta = (option) => {
    if (!option) return;
    return (
      <div className="d-flex align-items-center">
        <i
          className="ph-fill ph-bookmark-simple"
          style={{
            color: option?.cor,
            marginRight: "10px",
            fontSize: "21px",
          }}
        ></i>
        {option?.descricao}
      </div>
    );
  };

  const getMultipleAutocompleteValues = (valores, lista) => {
    if (valores) {
      return valores.map((valor) => lista.find((item) => item.id === valor));
    }
    return [];
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <DatePicker
          id="dataStart"
          name="Data-Start"
          label="Data Inicial"
          format="dd/MM/yyyy"
          value={valueDate("dataStart")}
          onChange={(date) => onChangeDate("dataStart", date, "Data-Start")}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          id="dataEnd"
          name="Data-End"
          label="Data Final"
          format="dd/MM/yyyy"
          value={valueDate("dataEnd")}
          onChange={(date) => onChangeDate("dataEnd", date, "Data-End")}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined" margin="normal" fullWidth>
          <Autocomplete
            id="grupoProdutoId"
            name="grupoProdutoId"
            options={grupoProdutoList}
            noOptionsText="Sem opções"
            autoHighlight
            getOptionLabel={(option) => (option ? option.descricao : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Grupo de Produto"
                variant="outlined"
              />
            )}
            onChange={(_, newValue) => {
              onChangeAutocomplete(
                "grupoProdutoId",
                newValue,
                "Grupo de Produtos"
              );
            }}
            value={valueAutocomplete(grupoProdutoList, "grupoProdutoId")}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined" margin="normal" fullWidth>
          <Autocomplete
            id="produtoId"
            name="produtoId"
            options={listaProdutos}
            autoHighlight
            loading={loadingAutoComplete}
            loadingText="Carregando"
            noOptionsText="Digite e pressione Enter"
            getOptionLabel={(option) =>
              option.descricao ? `${option?.codigo} - ${option?.descricao}` : ""
            }
            filterSelectedOptions
            onChange={(_, newValue) =>
              onChangeAutocomplete("produtoId", newValue, "Produto")
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant={"outlined"}
                label="Produto"
                onKeyDown={(e) => onInputChangeProdutos(e)}
                inputRef={inputRef}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
            value={value.autoComplete(
              listaProdutos,
              filtrosAvancados.produtoId
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Select
          label="Tipo"
          id="tipo"
          name="tipo"
          variant="outlined"
          value={valueInput("tipo")}
          margin="normal"
          onChange={(event) => onChangeSelect(event, "Tipo")}
        >
          <MenuItem value="">
            <p></p>
          </MenuItem>
          {listaTipos.map((tipo) => (
            <MenuItem key={tipo.value} value={tipo.value}>
              {tipo.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          id="etiquetasId"
          name="etiquetasId"
          options={etiquetas}
          noOptionsText="Sem opções"
          autoHighlight
          filterSelectedOptions
          getOptionLabel={(option) => {
            return (
              <div>
                <i
                  className="ph-fill ph-bookmark-simple"
                  style={{ color: `${option?.cor}`, marginRight: "10px" }}
                ></i>
                {option?.descricao}
              </div>
            );
          }}
          onChange={(_, newValue) => onChangeMultiple(newValue, "etiquetasId")}
          value={getMultipleAutocompleteValues(
            filtrosAvancados?.etiquetasId,
            etiquetas
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              margin="normal"
              label="Etiquetas"
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          id="marcasId"
          name="marcasId"
          options={marcas}
          noOptionsText="Sem opções"
          autoHighlight
          filterSelectedOptions
          getOptionLabel={(option) => option?.nome || ""}
          onChange={(_, newValue) => onChangeMultiple(newValue, "marcasId")}
          value={getMultipleAutocompleteValues(
            filtrosAvancados?.marcasId,
            marcas
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              margin="normal"
              label="Marcas"
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          id="colecoesId"
          name="colecoesId"
          options={colecoes}
          noOptionsText="Sem opções"
          autoHighlight
          filterSelectedOptions
          getOptionLabel={(option) => option?.descricao || ""}
          onChange={(_, newValue) => onChangeMultiple(newValue, "colecoesId")}
          value={getMultipleAutocompleteValues(
            filtrosAvancados?.colecoesId,
            colecoes
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              margin="normal"
              label="Coleções"
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancadoAnaliseProdutosVendidos;
