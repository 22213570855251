import shortid from "shortid";
import { value } from "../../../helpers";
import OperacaoFiscal from "./operacao-fiscal.entity";

class NfSaidaItem {
  produtoId = null;
  unidade = null;
  quantidade = null;
  valor = null;
  subtotal = null;
  modeloTributacaoId = null;
  grupoTributacaoId = null;
  descricao = null;
  codigo = null;
  codigoBarra = null;
  ncm = null;
  cfop = null;
  impostoIcmsStReducao = null;
  impostoIcmsStBase = null;
  impostoIcmsStAliquota = null;
  impostoIcmsSt = null;
  unidadeCom = null;
  unidadeTrib = null;
  quantidadeCom = null;
  quantidadeTrib = null;
  valorUnitarioComercial = null;
  valorUnitarioTributavel = null;
  desconto = null;
  acrescimo = null;
  tributosIcmsOrigem = null;
  tributosIcmsCst = null;
  tributosIcmsCsosn = null;
  tributosIcmsBaseCalculoModalidade = null;
  tributosIcmsBaseCalculoModalidadeST = null;
  tributosIcmsBaseCalculoPercentualReducao = null;
  tributosIcmsBaseCalculoValor = null;
  tributosIcmsAliquota = null;
  tributosIcmsValor = null;
  tributosPisCst = null;
  tributosPisBaseCalculoValor = null;
  tributosPisBaseCalculoQuantidade = null;
  tributosPisAliquota = null;
  tributosPisValor = null;
  tributosCofinsCst = null;
  tributosCofinsBaseCalculoValor = null;
  tributosCofinsAliquota = null;
  tributosCofinsValor = null;
  tributosIpiCst = null;
  tributosIpiEnquadramento = null;
  tributosIpiAliquota = null;
  tributosIpiValor = null;
  tributosIpiBaseCalculoValor = null;
  informacaoAdicional = null;
  modeloTributacao = null;
  saldoExportar = null;
  operacaoFiscal = null;

  constructor(item, index, operacaoFiscal, origensMercadoriaList, unidadeList) {
    this.id = item.id ? item.id : shortid.generate();
    this.ordem = index + 1;
    this.produtoId = item?.produtoId;
    this.quantidade = item.quantidade;
    this.saldoExportar = item.saldoExportar;
    this.grupoTributacaoId = item.grupoTributacaoId;
    this.modeloTributacaoId = item.modeloTributacaoId;
    this.codigo = item.codigo;
    this.codigoBarra = item.codigoBarra;
    this.descricao = item.descricao;
    this.desconto = item.desconto;
    this.despesas = item.despesas;
    this.acrescimo = item.acrescimo;
    this.tributosIcmsOrigem =
      (item.tributosIcmsOrigem === undefined ||
        item.tributosIcmsOrigem === null) &&
      origensMercadoriaList?.length
        ? value.autoComplete(origensMercadoriaList, item?.origemMercadoriaId)
            .codigo
        : item.tributosIcmsOrigem;
    this.unidade =
      !item.unidade && unidadeList?.length
        ? unidadeList.find(
            (unidade) =>
              unidade.codigo == item.unidade ||
              unidade.id == item.unidadeComercialVendaId
          )?.codigo
        : item.unidade;
    this.ncm = item.ncmCodigo || item.ncm;
    this.informacaoAdicional =
      item.informacaoAdicional || item.informacaoAdicionalNfe;
    this.valor = item.valor;
    this.subtotal = item.subtotal;
    this.cfop = item.cfop;
    this.impostoIcmsStReducao = item.impostoIcmsStReducao;
    this.impostoIcmsStBase = item.impostoIcmsStBase;
    this.impostoIcmsStAliquota = item.impostoIcmsStAliquota;
    this.impostoIcmsSt = item.impostoIcmsSt;
    this.unidadeCom = item.unidadeCom;
    this.unidadeTrib = item.unidadeTrib;
    this.quantidadeCom = item.quantidadeCom;
    this.quantidadeTrib = item.quantidadeTrib;
    this.valorUnitarioComercial = item.valorUnitarioComercial;
    this.valorUnitarioTributavel = item.valorUnitarioTributavel;
    this.tributosIcmsCst = item.tributosIcmsCst;
    this.tributosIcmsCsosn = item.tributosIcmsCsosn;
    this.tributosIcmsBaseCalculoModalidade =
      item.tributosIcmsBaseCalculoModalidade;
    this.tributosIcmsBaseCalculoModalidadeST =
      item.tributosIcmsBaseCalculoModalidadeST;
    this.tributosIcmsBaseCalculoPercentualReducao =
      item.tributosIcmsBaseCalculoPercentualReducao;
    this.tributosIcmsBaseCalculoValor = item.tributosIcmsBaseCalculoValor;
    this.tributosIcmsAliquota = item.tributosIcmsAliquota;
    this.tributosIcmsValor = item.tributosIcmsValor;
    this.tributosPisCst = item.tributosPisCst;
    this.tributosPisBaseCalculoValor = item.tributosPisBaseCalculoValor;
    this.tributosPisBaseCalculoQuantidade =
      item.tributosPisBaseCalculoQuantidade;
    this.tributosPisAliquota = item.tributosPisAliquota;
    this.tributosPisValor = item.tributosPisValor;
    this.tributosCofinsCst = item.tributosCofinsCst;
    this.tributosCofinsBaseCalculoValor = item.tributosCofinsBaseCalculoValor;
    this.tributosCofinsAliquota = item.tributosCofinsAliquota;
    this.tributosCofinsValor = item.tributosCofinsValor;
    this.tributosIpiCst = item.tributosIpiCst;
    this.tributosIpiEnquadramento = item.tributosIpiEnquadramento;
    this.tributosIpiAliquota = item.tributosIpiAliquota;
    this.tributosIpiValor = item.tributosIpiValor;
    this.tributosIpiBaseCalculoValor = item.tributosIpiBaseCalculoValor;
    this.modeloTributacao = item.modeloTributacao;
    this.operacaoFiscal =
      !item.operacaoFiscal && operacaoFiscal
        ? new OperacaoFiscal(operacaoFiscal)
        : item.operacaoFiscal;
  }

  setDescricao(descricao) {
    this.descricao = descricao;
  }

  setValor(valor) {
    this.valor = parseFloat(valor);
  }

  setSubtotal(quantidade) {
    this.subtotal = this.valor * quantidade;
  }

  setImposto(item) {
    this.tributosIcmsAliquota = item.tributosIcmsAliquota;
    this.tributosIpiAliquota = item.tributosIpiAliquota;
    this.aliquotaMva = item.aliquotaMva;
    this.impostoIcmsStAliquota = item.impostoIcmsStAliquota;
    //this.tributosIcmsValor = nfSaidaTotalTributos.calculaValorIcms(item, {});
  }
}

export default NfSaidaItem;
