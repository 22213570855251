import { formatPrecisaoFloat } from "../../../utils";

export function tabProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export function totalItensLista(listaPedidosProdutos) {
  if (listaPedidosProdutos?.length) {
    const total = listaPedidosProdutos.reduce(
      (prevVal, elem) => prevVal + parseFloat(elem.totalItem),
      0
    );
    return total;
  }
}

export function totalPedido(pedido, listaPedidosProdutos) {
  const total =
    parseFloat(totalItensLista(listaPedidosProdutos) ?? 0) -
    parseFloat(pedido?.desconto ?? 0) +
    parseFloat(pedido?.acrescimo ?? 0) +
    parseFloat(pedido?.despesas ?? 0);
  return parseFloat(total.toFixed(2));
}

export function totalPagamentos(listaPedidosPagamentos) {
  if (listaPedidosPagamentos?.length) {
    const total = listaPedidosPagamentos.reduce(
      (prevVal, elem) => prevVal + parseFloat(elem.valorTitulo),
      0
    );
    return total;
  }
}

export function calcularValorTotal(produto) {
  const total = parseFloat(produto?.valorUnitario) * produto.quantidade;
  return total;
}

export function mapearEndereco(endereco) {
  return {
    ...endereco,
    cidade: endereco.cidade?.nome,
    codigoIbge: endereco.cidade?.codigoIbge,
    estado: endereco.cidade.estado?.sigla,
    estadoId: endereco.cidade?.estadoId,
  };
}

export const mapearEnderecos = (enderecos, estados) => {
  return enderecos.map((endereco) => mapearEndereco(endereco, estados));
};

export function encontrarEnderecoPadrao(enderecos) {
  return enderecos.find((endereco) => endereco.padrao === true);
}

export function compararPagamentosTotalPedidos(pedido, listProdutos) {
  const isEqual =
    Number((pedido.totalPagamentos || 0).toFixed(2)) !==
    Number(totalPedido(pedido, listProdutos));
  return isEqual;
}

export function calculoDesconto(itens, pedido, porCemParaDecimal = true) {
  const total =
    totalItensLista(itens) +
    parseFloat(pedido?.acrescimo ?? 0) +
    parseFloat(pedido?.despesas ?? 0);

  let resultado;
  if (porCemParaDecimal) {
    const descontoPorcentagem = parseFloat(pedido?.descontoPorcentagem ?? 0);
    resultado = (descontoPorcentagem / 100) * total;
    resultado = formatPrecisaoFloat(resultado, 3);
  } else {
    const desconto = parseFloat(pedido?.desconto ?? 0);
    resultado = (desconto / formatPrecisaoFloat(total)) * 100;
    resultado = formatPrecisaoFloat(resultado, 3);
  }
  return resultado;
}
