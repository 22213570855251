import { useState, useEffect } from "react";
import { ListLayout } from "../../../layouts";
import { DataGrid, Dialog, DialogContent } from "../../../components";
import { MarcasService, notification } from "../../../services";
import { responseErros } from "../../../utils";
import AddMarcas from "../addMarcas/addMarcas.view";
import { FormFiltroAvancado } from "./components";
import { PermisoesHelper } from "../../../helpers";

const ListMarcas = () => {
  const [id, setId] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [marcas, setMarcas] = useState([]);
  const colunas = [
    {
      field: "nome",
      headerName: "Nome",
      flex: 1000,
    },
    {
      field: "ativado",
      headerName: "Status",
      flex: 500,
      valueGetter: (params) => (params.value ? "Ativado" : "Desativado"),
    },
  ];
  const marcasService = new MarcasService();
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("marcas-visualizar");

  useEffect(() => {
    buscarMarcas();
  }, [openDialog]);

  const buscarMarcas = async function (filter) {
    setLoading(true);
    const result = await marcasService.getAll(filter);
    if (!result.isAxiosError) {
      setMarcas(result.data.rows);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const handleClickOpenDialog = (id) => {
    setId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onClickDeleteMarca = async function (id) {
    const data = marcas.find((item) => item.id === id);
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir ${data.nome}`
    );
    if (retornoAlerta.isConfirmed) {
      setLoading(true);
      const result = await marcasService.deletar(id);
      if (!result.isAxiosError) {
        buscarMarcas();
        notification.deletadoSucesso();
      } else {
        setLoading(false);
        responseErros(result);
      }
    }
  };

  const buscarPesquisaAvancada = async (filter) => {
    const result = await marcasService.getAllFiltroAvancado({
      ...filter,
      restritiva: true,
      porIntervalo: [],
    });
    if (!result.isAxiosError) {
      setMarcas(result.data.rows);
    } else {
      responseErros(result);
    }
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      buscarMarcas(props.filtros);
    } else {
      buscarPesquisaAvancada(props.filtros);
    }
  };

  return (
    <ListLayout
      title="Marcas"
      loading={loading}
      onClickCadastrar={handleClickOpenDialog}
      permissaoButton={permissoesHelper.temPermisao("marcas-cadastrar")}
    >
      <DataGrid
        rows={marcas}
        columns={colunas}
        onClickEdit={handleClickOpenDialog}
        onClickDelete={onClickDeleteMarca}
        editShow={permissoesHelper.temPermisao("marcas-editar")}
        deleteShow={permissoesHelper.temPermisao("marcas-excluir")}
        paginationMode={"server"}
        sendServer={(props) => sendServerDatagrid(props)}
        FormFiltroAvancado={FormFiltroAvancado}
      />
      <Dialog
        disableEscapeKeyDown
        fullWidth
        PaperComponent="div"
        open={openDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <AddMarcas id={id} handleCloseDialog={handleCloseDialog} />
        </DialogContent>
      </Dialog>
    </ListLayout>
  );
};

export default ListMarcas;
