import { formatPrecisaoFloat } from "../../../utils";

export function tabProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export function totalOrcamento(orcamento, listaOrcamentosProdutos) {
  const total =
    parseFloat(totalItensLista(listaOrcamentosProdutos) ?? 0) -
    parseFloat(orcamento?.desconto ?? 0) +
    parseFloat(orcamento?.acrescimo ?? 0) +
    parseFloat(orcamento?.despesas ?? 0);
  return parseFloat(total.toFixed(2));
}

export function totalItensLista(listaOrcamentosProdutos) {
  if (listaOrcamentosProdutos?.length) {
    const total = listaOrcamentosProdutos.reduce(
      (prevVal, elem) => prevVal + parseFloat(elem.totalItem),
      0
    );
    return total;
  }
}

export function calcularValorTotal(produto) {
  const total = parseFloat(produto?.valorUnitario) * produto?.quantidade;
  return total;
}

export function encontrarEnderecoPadrao(enderecos) {
  return enderecos.find((endereco) => endereco.padrao === true);
}

export function mapearEndereco(estados, endereco) {
  const estado = encontrarEstado(estados, endereco.cidade.estadoId);
  return {
    ...endereco,
    cidade: endereco.cidade.nome,
    estado,
  };
}

export function mapearEnderecos(estados, enderecos) {
  return enderecos.map((endereco) => mapearEndereco(estados, endereco));
}

export function encontrarEstado(estados, estadoId) {
  return estados.find((estado) => estado.id === estadoId)?.sigla;
}

export function calcularPrecoProduto(
  produto,
  preco,
  orcamento,
  tabelaPrecoCliente
) {
  if (
    tabelaPrecoCliente &&
    orcamento.condicaoPagamentoId === tabelaPrecoCliente.condicaoPagamentoId
  ) {
    if (tabelaPrecoCliente.tipoTabela === "Por produto") {
      const tabelaPrecoProduto = tabelaPrecoCliente.tabelaPrecoProdutos.find(
        (item) => item.produtoId === produto.id
      );
      if (tabelaPrecoProduto) return tabelaPrecoProduto.precoTabela;
      return preco;
    }
    if (tabelaPrecoCliente.aplicarDescontoEm === "Percentual") {
      return preco - preco * (tabelaPrecoCliente?.descontoTabela / 100);
    }
    return preco - tabelaPrecoCliente.descontoTabela;
  }
  return preco;
}

export function calculoDesconto(itens, orcamento, porCemParaDecimal = true) {
  const total =
    totalItensLista(itens) +
    parseFloat(orcamento?.acrescimo ?? 0) +
    parseFloat(orcamento?.despesas ?? 0);
  let resultado;
  if (porCemParaDecimal) {
    const descontoPorcentagem = parseFloat(orcamento?.descontoPorcentagem ?? 0);
    resultado = (descontoPorcentagem / 100) * total;
    resultado = formatPrecisaoFloat(resultado, 3);
  } else {
    const desconto = parseFloat(orcamento?.desconto ?? 0);
    resultado = (desconto / formatPrecisaoFloat(total)) * 100;
    resultado = formatPrecisaoFloat(resultado, 3);
  }
  return resultado;
}
