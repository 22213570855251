import { TablePrintResumidoComponent } from "../../../../components";
import { format } from "date-fns";
import { formatDate, formatPrice } from "../../../../utils";

const PrintNfSaidaResumidoContador = ({
  filtrosAplicadosImpressao,
  relatoriosTotais,
  tituloRelatorio,
  print,
}) => {
  const columns = [
    {
      header: "Serie",
      field: "serieDocumento",
    },
    {
      header: "Nº Doc.",
      field: "numeroDocumento",
    },
    {
      header: "Data Lanc.",
      field: "dataLancamento",
      render: ({ dataLancamento }) =>
        format(formatDate.received(dataLancamento), "dd/MM/yyyy"),
    },
    {
      header: "Modelo Doc.",
      field: "modeloDocumento",
    },
    {
      header: "Chave Acesso",
      field: "chaveAcesso",
      render: ({ chaveAcesso }) =>
        chaveAcesso ? chaveAcesso.replace(/\D/g, "") : "",
    },
    {
      header: "Nº Protoc.",
      field: "numProtocolo",
      render: ({ numProtocolo }) =>
        numProtocolo ? numProtocolo.replace(/\D/g, "") : "",
    },
    {
      header: "Total",
      field: "valorTotal",
      fieldTotal: "total",
      render: ({ valorTotal }) => formatPrice(valorTotal, false),
    },
  ];
  return (
    <TablePrintResumidoComponent
      filtrosAplicadosImpressao={filtrosAplicadosImpressao}
      relatoriosTotais={relatoriosTotais.nfSaida}
      totais={relatoriosTotais.totais}
      tituloRelatorio={tituloRelatorio}
      print={print}
      columns={columns}
      itensPorPagina={31}
    />
  );
};

export default PrintNfSaidaResumidoContador;
