import { useState, useEffect } from "react";
import {
  Grid,
  Button,
  CardHeader,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  DialogTitle,
  FormControl,
  Switch,
  FormControlLabel,
  InputAdornment,
  IconButton,
  Autocomplete,
  DataGrid,
  MaskedInput,
  Loading,
} from "..";
import shortid from "shortid";
import { EnderecoService } from "../../services";
import { value } from "../../helpers";
import { responseErros } from "../../utils";
import { notification } from "../../services";

const EnderecosDialogFaturamento = ({
  openProdutoSimilarDialog,
  setOpenProdutoSimilarDialog,
  enderecoClienteDataGrid,
  salvarEndereco,
  colunas,
  cidadeId,
  setCidadeId,
  endereco,
  setEndereco,
  setEnderecoAleterado,
  inputErros,
  entity,
}) => {
  const [loading, setLoading] = useState(false);
  const [cidades, setCidades] = useState([]);
  const [estados, setEstados] = useState([]);
  const [ufId, setUfId] = useState(null);
  const enderecoService = new EnderecoService();

  useEffect(() => {
    buscaEstados();
  }, []);

  const buscaEstados = () => {
    enderecoService.getEstados().then((res) => {
      if (!res.isAxiosError) {
        setEstados(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const handleChangeInput = (event) => {
    setEndereco({
      ...endereco,
      [event.target.name]: event.target.value ? event.target.value : undefined,
    });
  };

  const handleChangeSwitch = (event) => {
    setEndereco({
      ...endereco,
      [event.target.name]: event.target.checked,
    });
  };

  const handleCidadeInput = (e, newValue) => {
    e.persist();
    setCidadeId(newValue.id);
  };

  const handleEstadoUfInput = async (e, newValue) => {
    e.persist();
    setUfId(newValue.id);
    changeCidadesByEstadoId(newValue.id);
    setEndereco((prevState) => ({ ...prevState, estado: newValue?.sigla }));
  };

  const changeCidadesByEstadoId = async (id) => {
    if (id) {
      enderecoService.getCidades(id).then((response) => {
        if (!response.isAxiosError) {
          if (!response.data) {
            notification.erroValidacao("Preencha um estado");
          } else {
            setCidades(response.data);
          }
        } else {
          responseErros(response);
        }
      });
    }
  };

  const handleCompletarEndereco = async () => {
    setLoading(true);
    const res = await enderecoService.getByCep(endereco.cep);
    if (!res.isAxiosError) {
      if (res.data) {
        setUfId(res.data.estado?.id);
        changeCidadesByEstadoId(res.data.estado?.id);
        setCidadeId(res.data.id);
        setEndereco({
          ...endereco,
          id: shortid.generate(),
          bairro: res.data.bairro,
          logradouro: res.data.logradouro,
          cidade: res.data.nome,
          codigoIbge: res.data.codigoIbge,
          estado: res.data.estado?.sigla,
        });
      }
    } else {
      responseErros(res);
      notification.alertaGenericos("CEP não encontrado.");
    }
    setLoading(false);
  };

  const onClickSalvar = (event) => {
    event.preventDefault();
    salvarEndereco().then((resolve) => {
      if (resolve) {
        setEnderecoAleterado(endereco);
        entity.setEnderecoCliente(endereco);
        setOpenProdutoSimilarDialog(false);
        setEndereco({ padrao: false });
        setUfId(null);
        setCidadeId(null);
      }
    });
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={openProdutoSimilarDialog}
        onClose={() => setOpenProdutoSimilarDialog(false)}
      >
        <CardHeader title="ENDEREÇOS" className="m-2" />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DataGrid
                rows={enderecoClienteDataGrid}
                columns={colunas}
                rowCount={enderecoClienteDataGrid.length}
                disableAcoes
              />
            </Grid>
            <form onSubmit={(event) => onClickSalvar(event)}>
              <DialogTitle>Cadastrar Endereço</DialogTitle>
              <DialogContent>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={4}>
                    <MaskedInput
                      mask="99999-999"
                      id="cep"
                      name="cep"
                      label="CEP"
                      required
                      error={inputErros.get("cep")}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Pesquisar CEP"
                              onClick={() => handleCompletarEndereco()}
                            >
                              <i
                                className="ph-bold ph-magnifying-glass"
                                style={{ color: "#494C62", fontSize: 18 }}
                              ></i>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      value={endereco.cep ? endereco.cep : ""}
                      onChange={(event) => handleChangeInput(event)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="logradouro"
                      name="logradouro"
                      label="Endereço"
                      variant="outlined"
                      error={inputErros.get("logradouro")}
                      margin="normal"
                      type="text"
                      value={endereco.logradouro ? endereco.logradouro : ""}
                      onChange={(event) => handleChangeInput(event)}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="numero"
                      name="numero"
                      label="Número"
                      variant="outlined"
                      error={inputErros.get("numero")}
                      value={endereco.numero ? endereco.numero : ""}
                      onChange={(event) => handleChangeInput(event)}
                      margin="normal"
                      type="number"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="bairro"
                      name="bairro"
                      label="Bairro"
                      variant="outlined"
                      required
                      margin="normal"
                      error={inputErros.get("bairro")}
                      value={endereco.bairro ? endereco.bairro : ""}
                      onChange={(event) => handleChangeInput(event)}
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="complemento"
                      name="complemento"
                      label="Complemento"
                      variant="outlined"
                      margin="normal"
                      value={endereco.complemento ? endereco.complemento : ""}
                      onChange={(event) => handleChangeInput(event)}
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl variant="outlined" margin="normal" fullWidth>
                      <Autocomplete
                        required
                        id="estado"
                        name="estado"
                        options={estados}
                        noOptionsText="Sem opções"
                        autoHighlight
                        getOptionLabel={(option) =>
                          option.sigla && option.nome
                            ? `${option.sigla} - ${option.nome}`
                            : ""
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="UF"
                            required
                            variant="outlined"
                            error={inputErros.get("bairro")}
                          />
                        )}
                        onChange={(event, newValue) =>
                          handleEstadoUfInput(event, newValue)
                        }
                        value={value.autoComplete(estados, ufId)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl variant="outlined" margin="normal" fullWidth>
                      <Autocomplete
                        required
                        id="cidade"
                        name="cidade"
                        options={cidades}
                        noOptionsText="Sem opções"
                        autoHighlight
                        getOptionLabel={(option) => option.nome ?? ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Cidade"
                            required
                            variant="outlined"
                            error={inputErros.get("cidade")}
                          />
                        )}
                        onChange={(event, newValue) => {
                          handleCidadeInput(event, newValue);
                        }}
                        value={value.autoComplete(cidades, cidadeId)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="descricao"
                      name="descricao"
                      label="Descrição"
                      variant="outlined"
                      margin="normal"
                      value={endereco?.descricao ? endereco.descricao : ""}
                      onChange={(event) => handleChangeInput(event)}
                      placeholder="APTO, Casa, Trabalho..."
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      label="Padrão"
                      control={
                        <Switch
                          id="padrao"
                          name="padrao"
                          checked={endereco.padrao ? true : false}
                          onChange={handleChangeSwitch}
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className="d-flex flex-row justify-content-start m-3">
                <Button type="submit" variant="contained" color="primary">
                  <i
                    className="ph-fill ph-check-circle"
                    style={{ fontSize: 22, marginRight: 10 }}
                  ></i>
                  Adicionar e Alterar
                </Button>
              </DialogActions>
            </form>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="padrao"
                className="mt-3 mb-3"
                style={{ marginLeft: 10 }}
                onClick={() => setOpenProdutoSimilarDialog(false)}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
          <Loading loading={loading} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EnderecosDialogFaturamento;
