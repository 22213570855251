import { useState, useEffect } from "react";
import {
  Grid,
  Button,
  DialogContent,
  DialogActions,
  TextField,
  DataGrid,
  Loading,
  Autocomplete,
  DatePicker,
} from "../../../../components";
import { InputErros, value } from "../../../../helpers";
import { NfSaidaService, notification } from "../../../../services";
import { formatPrice, responseErros } from "../../../../utils";
import { editarQuantidadeItemDevolucao } from "../list-nf-saida";

const DevolucaoMercadoriaStepperContent = ({
  sendServer,
  activeStep,
  setActiveStep,
  handleDevolverMercadorias,
  handleCancelarDevolucao,
  handleDateChange,
  filtrosDialog,
  setFiltrosDialog,
  operacaoFiscal,
  setOperacaoFiscal,
  listaOperacoesFiscais,
  setListaOperacoesFiscais,
  listaClientes,
  colunas,
  nfSaida,
  documentoSelecionado,
  setDocumentoSelecionado,
  setItensSelecionados,
  loading,
  buscarNfSaidaAvancado,
  documentosSelecionadosIds,
  setDocumentosSelecionadosIds,
  setListaNfSaida,
  handleInputChange,
}) => {
  const [listaItens, setListaItens] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const nfSaidaService = new NfSaidaService();
  const colunasItens = [
    {
      field: "codigo",
      headerName: "Código",
      flex: 150,
    },
    {
      field: "descricao",
      headerName: "Produto",
      flex: 500,
    },
    {
      field: "valor",
      headerName: "Valor (R$)",
      flex: 300,
      valueGetter: (params) => formatPrice(parseFloat(params.value)),
    },
    {
      field: "saldoExportar",
      headerName: "Qtd.",
      flex: 200,
      headerAlign: "start",
      align: "start",
      type: "number",
      editable: true,
      valueParser: (newValue, params) => {
        const maxValue = listaItens?.length
          ? listaItens.find((item) => item.id === params.row?.id)?.saldoExportar
          : params.value;
        const parsedValue = parseFloat(newValue);
        if (parsedValue > maxValue) {
          return maxValue;
        } else return parsedValue;
      },
    },
    {
      field: "totalItem",
      headerName: "Total Item (R$)",
      flex: 300,
      valueGetter: (params) => {
        const total = params.row.valor * params.row.saldoExportar;
        return formatPrice(total);
      },
    },
  ];

  useEffect(() => {
    if (documentoSelecionado && documentoSelecionado.nfSaidaItens) {
      const listaIdItens = documentoSelecionado.nfSaidaItens.map(
        (item) => item.id
      );
      const listaFiltrada = documentoSelecionado.nfSaidaItens.filter(
        (produto) => produto.saldoExportar > 0
      );
      setSelectionModel(listaIdItens);
      setItensSelecionados(listaFiltrada);
      setListaItens(listaFiltrada);
    }
  }, [documentoSelecionado]);

  const filtrarDocumentoSelecionado = (listaNfSaida, nfSaidaIds) => {
    let documentoImportacao = null;
    let itensImportacao = [];
    const documentoFiltrado = listaNfSaida.filter((documento) => {
      if (nfSaidaIds.includes(documento.id)) {
        if (documentoImportacao === null) {
          documentoImportacao = { ...documento };
        }
        const nfSaidaFiltrada = {};
        for (const key in documento) {
          if (
            key === "clienteId" &&
            documentoImportacao[key] !== documento[key]
          ) {
            throw "Documentos devem ser do mesmo cliente";
          }
          if (key === "nfSaidaItens") {
            itensImportacao.push(...documento[key]);
            nfSaidaFiltrada[key] = [...documento[key]];
          } else {
            nfSaidaFiltrada[key] = documentoImportacao[key];
          }
        }
        return nfSaidaFiltrada;
      }
      return documento;
    });
    if (documentoFiltrado[0]?.modeloDocumento === "01") {
      const listaOperacoes = listaOperacoesFiscais.filter(
        (operacao) => operacao.modeloDocumento === "01"
      );
      setListaOperacoesFiscais(listaOperacoes);
    }
    setDocumentoSelecionado(() => ({
      ...documentoFiltrado[0],
      nfSaidaItens: itensImportacao,
    }));
  };

  function buscarItens() {
    if (!documentosSelecionadosIds?.length) {
      return notification.alertaGenericos(
        "Selecione ao menos um documento para importar"
      );
    }
    const filtros = {
      id: documentosSelecionadosIds,
      nonPaginated: true,
    };
    nfSaidaService.getAllFiltroAvancado(filtros).then((result) => {
      if (!result.isAxiosError) {
        try {
          filtrarDocumentoSelecionado(result.data, documentosSelecionadosIds);
          setListaNfSaida(result.data);
        } catch (error) {
          return notification.alertaGenericos(error);
        }
        handleAvancarStep();
      } else {
        responseErros(result);
      }
    });
  }

  const handleAvancarStep = () => {
    if (!selectionModel?.length && activeStep == 1) {
      return notification.alertaGenericos(
        "Selecione ao menos um produto para importar"
      );
    }
    setActiveStep(activeStep + 1);
  };

  const handleVoltarStep = () => {
    setActiveStep(activeStep - 1);
  };

  const filtrarItensSelecionados = (itemIds) => {
    let selecionados = [];
    for (let itemId of itemIds) {
      const itemEncontrado = listaItens.find((item) => item.id === itemId);
      selecionados.push(itemEncontrado);
    }
    setItensSelecionados(selecionados);
  };

  const onChangeAutocomplete = (name, value) => {
    setFiltrosDialog({
      ...filtrosDialog,
      [name]: value ? value.id : undefined,
    });
  };

  switch (activeStep) {
    case 2:
      return (
        <>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  id="operacaoFiscalId"
                  name="operacaoFiscalId"
                  required
                  options={listaOperacoesFiscais}
                  noOptionsText="Sem opções"
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.descricao ? `${option.descricao}` : ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Operação Fiscal"
                      variant="outlined"
                      margin="normal"
                      error={inputErros.get("operacaoFiscalId")}
                      required
                    />
                  )}
                  onChange={(e, value) => {
                    setOperacaoFiscal(value);
                  }}
                  value={value.autoComplete(
                    listaOperacoesFiscais,
                    operacaoFiscal?.id
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="m-3">
            <Button
              onClick={handleCancelarDevolucao}
              color="padrao"
              variant="contained"
            >
              Cancelar
            </Button>
            <Button
              onClick={handleVoltarStep}
              color="primary"
              variant="contained"
            >
              <i
                className="ph-fill ph-caret-left"
                style={{ color: "#fff", fontSize: 18 }}
              ></i>
              Anterior
            </Button>
            <Button
              onClick={handleDevolverMercadorias}
              color="primary"
              variant="contained"
            >
              Devolver
            </Button>
          </DialogActions>
        </>
      );
    case 1:
      return (
        <>
          <DialogContent>
            <DataGrid
              rows={listaItens}
              columns={colunasItens}
              rowCount={listaItens.length}
              checkboxSelection
              selectionModel={selectionModel}
              onRowSelectionModelChange={(selectedIds) => {
                setSelectionModel(selectedIds);
                filtrarItensSelecionados(selectedIds);
              }}
              onCellKeyDown={(params, event) =>
                editarQuantidadeItemDevolucao(
                  params,
                  event,
                  setListaItens,
                  setItensSelecionados
                )
              }
              disableAcoes
            />
          </DialogContent>
          <DialogActions className="m-3">
            <Button
              onClick={handleVoltarStep}
              color="primary"
              variant="contained"
            >
              <i
                className="ph-fill ph-caret-left"
                style={{ color: "#fff", fontSize: 18 }}
              ></i>
              Anterior
            </Button>
            <Button
              onClick={handleAvancarStep}
              color="primary"
              variant="contained"
            >
              Próximo
              <i
                className="ph-fill ph-caret-right"
                style={{ color: "#fff", fontSize: 18 }}
              ></i>
            </Button>
          </DialogActions>
        </>
      );
    default:
      return (
        <>
          <DialogContent>
            <Grid container spacing={2} className="mt-1 mb-4">
              <Grid item xs={2}>
                <DatePicker
                  id="dataEmissaoStart"
                  name="dataEmissaoStart"
                  label="Data Emissão De"
                  format="dd/MM/yyyy"
                  value={
                    filtrosDialog?.dataEmissaoStart
                      ? filtrosDialog.dataEmissaoStart
                      : null
                  }
                  onChange={(date) =>
                    handleDateChange(date, "dataEmissaoStart")
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <DatePicker
                  id="dataEmissaoEnd"
                  name="dataEmissaoEnd"
                  label="Data Emissão Até"
                  format="dd/MM/yyyy"
                  value={
                    filtrosDialog?.dataEmissaoEnd
                      ? filtrosDialog.dataEmissaoEnd
                      : null
                  }
                  onChange={(date) => handleDateChange(date, "dataEmissaoEnd")}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  id="clienteId"
                  name="clienteId"
                  options={listaClientes}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.nomeRazaoSocial
                      ? `${option.id}` + ` - ` + `${option.nomeRazaoSocial}`
                      : ""
                  }
                  getOptionSelected={(option, value) =>
                    option?.nomeRazaoSocial === value?.nomeRazaoSocial
                  }
                  noOptionsText="Sem opções"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cliente"
                      variant={"outlined"}
                      error={inputErros.get("clienteId")}
                    />
                  )}
                  onChange={(e, value) =>
                    onChangeAutocomplete("clienteId", value)
                  }
                  value={value.autoComplete(
                    listaClientes,
                    filtrosDialog?.clienteId
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id="numeroDocumento"
                  name="numeroDocumento"
                  label="Nº Documento"
                  variant="outlined"
                  fullWidth
                  onChange={handleInputChange}
                  value={filtrosDialog?.numeroDocumento}
                />
              </Grid>
              <Grid item xs={2} className="d-flex align-items-center">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  sx={{ height: "51px" }}
                  onClick={() => buscarNfSaidaAvancado(filtrosDialog)}
                >
                  <i
                    className="ph-bold ph-magnifying-glass"
                    style={{ color: "#fff", fontSize: 18, marginRight: 8 }}
                  ></i>
                  Filtrar
                </Button>
              </Grid>
            </Grid>
            <DataGrid
              rows={nfSaida}
              columns={colunas}
              checkboxSelection
              onRowSelectionModelChange={(selectedIds) => {
                setDocumentosSelecionadosIds(selectedIds);
              }}
              paginationMode={"server"}
              sendServer={sendServer}
              disableAcoes
              noFilter
            />
          </DialogContent>
          <DialogActions className="m-3">
            <Button
              onClick={handleCancelarDevolucao}
              color="padrao"
              variant="contained"
            >
              Cancelar
            </Button>
            <Button onClick={buscarItens} color="primary" variant="contained">
              Próximo
              <i
                className="ph-fill ph-caret-right"
                style={{ color: "#fff", fontSize: 18 }}
              ></i>
            </Button>
          </DialogActions>
          <Loading loading={loading} />
        </>
      );
  }
};

export default DevolucaoMercadoriaStepperContent;
