import { useState, useEffect } from "react";
import { format } from "date-fns";
import { ListLayout } from "../../../layouts";
import { DataGrid } from "../../../components";
import {
  history,
  OrcamentosService,
  PessoaService,
  notification,
  OperacaoFiscalService,
} from "../../../services";
import { formatPrice, responseErros } from "../../../utils";
import { FormFiltroAvancado } from "./components";
import { formatDate } from "../../../utils";
import { Grid, Tooltip, Icon, Typography } from "../../../components";
import { filtrosUrl, PermisoesHelper } from "../../../helpers";

const ListOrcamentosView = () => {
  const [loading, setLoading] = useState(false);
  const [orcamentos, setOrcamentos] = useState([]);
  const [listaPessoas, setListaPessoas] = useState([]);
  const [rowCount, setRowCount] = useState(null);
  const [listaOperacoesFiscais, setListaOperacoesFiscais] = useState([]);
  const pessoaService = new PessoaService();
  const orcamentosService = new OrcamentosService();
  const operacaoFiscalService = new OperacaoFiscalService();
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("orcamentos-visualizar");
  const status = [
    {
      id: 1,
      nome: "Aberto",
      cor: "#4FBB53",
    },
    {
      id: 2,
      nome: "Fechado",
      cor: "#004780",
    },
    {
      id: 3,
      nome: "Parcialmente Fechado",
      cor: "#EA9D2B",
    },
    {
      id: 4,
      nome: "Cancelado",
      cor: "#DC3545",
    },
  ];

  const colunas = [
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        switch (params.value) {
          case "ABERTO":
            return (
              <Tooltip title="Aberto" arrow placement="right">
                <Icon>
                  <i
                    className="ph-fill ph-circle"
                    style={{ color: "#4FBB53", fontSize: 18 }}
                  ></i>
                </Icon>
              </Tooltip>
            );
          case "FECHADO":
            return (
              <Tooltip title="Fechado" arrow placement="right">
                <Icon>
                  <i
                    className="ph-fill ph-circle"
                    style={{ color: "#004780", fontSize: 18 }}
                  ></i>
                </Icon>
              </Tooltip>
            );
          case "PARCIALMENTE_FECHADO":
            return (
              <Tooltip title="Parcialmente Fechado" arrow placement="right">
                <Icon>
                  <i
                    className="ph-fill ph-circle"
                    style={{ color: "#EA9D2B", fontSize: 18 }}
                  ></i>
                </Icon>
              </Tooltip>
            );
          case "CANCELADO":
            return (
              <Tooltip title="Cancelado" arrow placement="right">
                <Icon>
                  <i
                    className="ph-fill ph-circle"
                    style={{ color: "#DC3545", fontSize: 18 }}
                  ></i>
                </Icon>
              </Tooltip>
            );
          default:
            return;
        }
      },
    },
    {
      field: "serieDocumento",
      headerName: "Série",
      width: 100,
    },
    {
      field: "numeroDocumento",
      headerName: "Nº Documento",
      flex: 100,
      filtrar: true,
    },
    {
      field: "operacaoFiscalId",
      headerName: "Operação Fiscal",
      flex: 110,
      valueGetter: (params) =>
        listaOperacoesFiscais.find((item) => item.id == params.value)
          ?.descricao,
    },
    {
      field: "dataEmissao",
      headerName: "Data de Emissão",
      width: 150,
      valueGetter: (params) =>
        format(formatDate.received(params.value), "dd/MM/yyyy"),
    },
    {
      field: "pessoaClienteId",
      headerName: "Cliente",
      width: 250,
      sortable: false,
      valueGetter: (params) =>
        listaPessoas.find((item) => item.id == params.value)?.nomeRazaoSocial,
    },
    {
      field: "itens",
      headerName: "Total do Orçamento",
      flex: 200,
      sortable: false,
      valueGetter: (params) => {
        const totalOrcamento =
          params.value
            .map((item) => item.valorUnitario * item.quantidade)
            .reduce((acumulador, total) => acumulador + total, 0) +
          params.row.acrescimo -
          params.row.desconto +
          params.row.despesas;
        return formatPrice(totalOrcamento);
      },
    },
  ];

  useEffect(() => {
    const filtros = filtrosUrl.get();
    buscarOrcamentos(filtros);
    buscarOperacoesFiscais();
  }, []);

  const buscarOrcamentos = async function (filtro) {
    setLoading(true);
    const result = await orcamentosService.getAll(filtro);
    if (!result.isAxiosError) {
      setOrcamentos(result.data.rows);
      setRowCount(result.data.count);
      buscarRelacionamentosOrcamento(result.data.rows);
      filtrosUrl.set(filtro);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarRelacionamentosOrcamento = (listaDocumento) => {
    const idsPessoas = [
      ...new Set(
        listaDocumento
          .map(({ pessoaClienteId }) => (pessoaClienteId ? pessoaClienteId : 0))
          .filter(
            (pessoaClienteId, index, listaDocumento) =>
              listaDocumento.indexOf(pessoaClienteId) === index
          )
      ),
    ];
    buscarPessoas(idsPessoas);
  };

  const buscarPessoas = (listaIds) => {
    if (listaIds.length) {
      const filtro = {
        id: listaIds,
        nonPaginated: true,
      };
      pessoaService.getAll(filtro).then((result) => {
        if (!result.isAxiosError) {
          setListaPessoas(result.data);
        } else {
          responseErros(result);
        }
      });
    }
  };

  const buscarOperacoesFiscais = (filtros) => {
    const filtro = {
      ...filtros,
      nonPaginated: true,
    };
    operacaoFiscalService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setListaOperacoesFiscais(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const onClickCadastrarOrcamentos = function () {
    history.push("/faturamento/orcamentos/create");
  };

  const onClickEditOrcamentos = function (id) {
    history.push(`/faturamento/orcamentos/${id}`);
  };

  const buscarPesquisaAvancada = async (filter) => {
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filter,
    };
    if (
      filtrosAvancadosTemp.dataEmissaoStart &&
      filtrosAvancadosTemp.dataEmissaoEnd
    ) {
      porIntervalo.push({
        coluna: "dataEmissao",
        de: filtrosAvancadosTemp.dataEmissaoStart,
        ate: filtrosAvancadosTemp.dataEmissaoEnd,
      });
    }
    if (filtrosAvancadosTemp.totalStart && filtrosAvancadosTemp.totalEnd) {
      porIntervalo.push({
        coluna: "totalVenda",
        de: parseFloat(filtrosAvancadosTemp.totalStart),
        ate: parseFloat(filtrosAvancadosTemp.totalEnd),
      });
    }
    delete filtrosAvancadosTemp.dataEmissaoStart;
    delete filtrosAvancadosTemp.dataEmissaoEnd;
    delete filtrosAvancadosTemp.totalStart;
    delete filtrosAvancadosTemp.totalEnd;
    setLoading(true);
    const result = await orcamentosService.getAllFiltroAvancado({
      ...filtrosAvancadosTemp,
      restritiva: true,
      porIntervalo,
    });
    if (!result.isAxiosError) {
      setOrcamentos(result.data.rows);
      setRowCount(result.data.count);
      buscarRelacionamentosOrcamento(result.data.rows);
      filtrosUrl.set(filter);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      delete props.filtros.status;
      buscarOrcamentos(props.filtros);
    } else {
      buscarPesquisaAvancada(props.filtros);
    }
  };

  const onClickCancelarOrcamento = async function (id) {
    const orcamento = orcamentos.find((item) => item.id === id);
    if (orcamento) {
      if (orcamento.status === "CANCELADO") {
        return notification.alertaGenericos("Esse orçamento já está cancelado");
      }
      const retornoAlerta = await notification.confirmacao(
        "Cancelar!",
        "Tem certeza que deseja cancelar esse orçamento?"
      );
      if (retornoAlerta.isConfirmed) {
        setLoading(true);
        const response = await orcamentosService.cancelar(orcamento.id);
        setLoading(false);
        if (!response.isAxiosError) {
          notification.alteracaoSucesso();
          buscarOrcamentos();
        } else {
          responseErros(response);
        }
      }
    }
  };

  return (
    <ListLayout
      title="Orçamentos"
      loading={loading}
      onClickCadastrar={onClickCadastrarOrcamentos}
      permissaoButton={permissoesHelper.temPermisao("orcamentos-cadastrar")}
    >
      <Grid container spacing={2}>
        {status.map((status) => (
          <span className="d-flex m-3" key={status.id}>
            <i
              className="ph-fill ph-circle"
              style={{ color: status.cor, fontSize: 18, marginRight: 8 }}
            ></i>
            <Typography className="me-5">{status.nome}</Typography>
          </span>
        ))}
      </Grid>
      <DataGrid
        rows={orcamentos}
        columns={colunas}
        onClickEdit={onClickEditOrcamentos}
        onClickDelete={onClickCancelarOrcamento}
        visualizarShow={permissoesHelper.temPermisao("orcamentos-visualizar")}
        editShow={permissoesHelper.temPermisao("orcamentos-editar")}
        deleteShow={permissoesHelper.temPermisao("orcamentos-excluir")}
        rowCount={rowCount}
        paginationMode={"server"}
        sendServer={sendServerDatagrid}
        FormFiltroAvancado={FormFiltroAvancado}
      />
    </ListLayout>
  );
};

export default ListOrcamentosView;
