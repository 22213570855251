import clsx from "clsx";
import {
  Box,
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Tooltip,
} from "../../../components";
import { PermisoesHelper } from "../../../helpers";
import { SessionService, history, notification } from "../../../services";
import shortid from "shortid";
import { useImportContext } from "../../../contexts/import.context";
import store from "../../../config/store";

export const MenuLateral = ({
  open,
  setOpen,
  currentPathname,
  matches,
  id,
}) => {
  const {
    openCollapse,
    setOpenCollapse,
    openCadastros,
    setOpenCadastros,
    openCompras,
    setOpenCompras,
    openVendas,
    setOpenVendas,
    openFinanceiro,
    setOpenFinanceiro,
    openRelatorios,
    setOpenRelatorios,
    initialCollapses,
  } = useImportContext();
  const permisoesHelper = new PermisoesHelper();
  const sessionService = new SessionService();
  const logoHorizontal =
    "https://public.siaflite.com.br/logo-lite-horizontal.svg";
  const subMenuCadastros = [
    {
      section: {
        title: "Geral",
      },
      title: "Etiquetas",
      path: "/estoque/etiquetas",
      isOpen: openCadastros,
      icon: "ph-fill ph-sticker",
      permission: permisoesHelper.temPermisao("etiquetas-visualizar"),
    },
    {
      section: {
        title: "Pessoas",
      },
      title: "Pessoas",
      path: "/integracao/pessoas",
      isOpen: openCadastros,
      icon: "ph-fill ph-users",
      permission: permisoesHelper.temPermisao("pessoas-visualizar"),
    },
    {
      section: {
        title: "Pessoas",
      },
      title: "Grupo de Pessoas",
      path: "/integracao/grupo-pessoas",
      isOpen: openCadastros,
      icon: "ph-fill ph-users-three",
      permission: permisoesHelper.temPermisao("grupo-pessoas-visualizar"),
    },
    {
      section: {
        title: "Produto",
      },
      title: "Produtos",
      path: "/estoque/produtos",
      isOpen: openCadastros,
      icon: "ph-fill ph-package",
      permission: permisoesHelper.temPermisao("produtos-visualizar"),
    },
    {
      section: {
        title: "Produto",
      },
      title: "Grupos de Produtos",
      path: "/estoque/grupo-produtos",
      isOpen: openCadastros,
      icon: "ph-fill ph-rows",
      permission: permisoesHelper.temPermisao("grupo-produtos-visualizar"),
    },
    {
      section: {
        title: "Produto",
      },
      title: "Saldo Inicial",
      path: "/estoque/saldo-inicial",
      isOpen: openCadastros,
      icon: "ph-fill ph-cube-focus",
      permission: permisoesHelper.temPermisao("saldo-inicial-visualizar"),
    },
    {
      section: {
        title: "Produto",
      },
      title: "Marcas",
      path: "/estoque/marcas",
      isOpen: openCadastros,
      icon: "ph-bold ph-sketch-logo",
      permission: permisoesHelper.temPermisao("marcas-visualizar"),
    },
    {
      section: {
        title: "Produto",
      },
      title: "Coleções",
      path: "/estoque/colecoes",
      isOpen: openCadastros,
      icon: "ph-fill ph-t-shirt",
      permission: permisoesHelper.temPermisao("colecoes-visualizar"),
    },
    {
      section: {
        title: "Produto",
      },
      title: "Modelos de Grade",
      path: "/estoque/modelos-grade",
      isOpen: openCadastros,
      icon: "ph-fill ph-squares-four",
      permission: permisoesHelper.temPermisao("modelos-grade-visualizar"),
    },
    {
      section: {
        title: "Produto",
      },
      title: "Consulta Fiscal em Lote",
      path: "/estoque/consultas-lote",
      isOpen: openCadastros,
      icon: "ph-bold ph-list-magnifying-glass",
      permission: sessionService.getEmpresa()?.autorizarConsultaConfisped,
    },
    {
      section: {
        title: "Faturamento",
      },
      title: "Meios de Pagamento",
      path: "/faturamento/meios-pagamento",
      isOpen: openCadastros,
      icon: "ph-fill ph-credit-card",
      permission: permisoesHelper.temPermisao("meios-pagamento-visualizar"),
    },
    {
      section: {
        title: "Faturamento",
      },
      title: "Condições de Pagamento",
      path: "/faturamento/condicoes-pagamento",
      isOpen: openCadastros,
      icon: "ph-fill ph-credit-card",
      permission: permisoesHelper.temPermisao("condicoes-pagamento-visualizar"),
    },
    {
      section: {
        title: "Financeiro",
      },
      title: "Bancos e Caixas",
      path: "/financeiro/bancos-caixas",
      isOpen: openCadastros,
      icon: "ph-fill ph-bank",
      permission: permisoesHelper.temPermisao("bancos-caixas-visualizar"),
    },
    {
      section: {
        title: "Fiscal",
      },
      title: "Grupos Tributários",
      path: "/fiscal/grupos-tributario",
      isOpen: openCadastros,
      icon: "ph-fill ph-chart-pie",
      permission: permisoesHelper.temPermisao("grupos-tributario-visualizar"),
    },
    {
      section: {
        title: "Fiscal",
      },
      title: "Operações Fiscais",
      path: "/fiscal/operacoes-fiscais",
      isOpen: openCadastros,
      icon: "ph-fill ph-calculator",
      permission: permisoesHelper.temPermisao("operacoes-fiscais-visualizar"),
    },
    {
      section: {
        title: "Fiscal",
      },
      title: "Modelo Tributação",
      path: "/fiscal/modelos-tributacao",
      isOpen: openCadastros,
      icon: "ph-fill ph-chart-bar",
      permission: permisoesHelper.temPermisao("modelos-tributacao-visualizar"),
    },
  ];
  const subMenuCompras = [
    {
      title: "NF Entrada",
      path: "/estoque/nf-entrada",
      isOpen: openCompras,
      icon: "ph-fill ph-receipt",
      permission: permisoesHelper.temPermisao("nf-entrada-visualizar"),
    },
    /* {
      title: "Importação XML",
      path: "/estoque/importacao-xml",
      isOpen: openCompras,
      icon: "ph-fill ph-hand-deposit",
      permission:permisoesHelper.temPermisao("nf-entrada-cadastrar"),
    }, */
    {
      title: "Documento Frete",
      path: "/estoque/documentos-frete",
      isOpen: openCompras,
      icon: "ph-fill ph-truck",
      permission: permisoesHelper.temPermisao("documentos-frete-visualizar"),
    },
  ];
  const subMenuVendas = [
    {
      title: "Orçamentos",
      path: "/faturamento/orcamentos",
      isOpen: openVendas,
      icon: "ph-fill ph-receipt",
      permission: permisoesHelper.temPermisao("orcamentos-visualizar"),
    },
    {
      title: "Pedidos",
      path: "/faturamento/pedidos",
      isOpen: openVendas,
      icon: "ph-fill ph-file-text",
      permission: permisoesHelper.temPermisao("pedidos-visualizar"),
    },
    {
      title: "NF Saída",
      path: "/faturamento/nf-saida",
      isOpen: openVendas,
      icon: "ph-fill ph-scroll",
      permission: permisoesHelper.temPermisao("nf-saida-visualizar"),
    },
  ];
  const subMenuFinanceiro = [
    {
      title: "Contas a Pagar",
      path: "/financeiro/contas-pagar",
      isOpen: openFinanceiro,
      icon: "ph-bold ph-money",
      permission: permisoesHelper.temPermisao("contas-pagar-visualizar"),
    },
    {
      title: "Contas a Receber",
      path: "/financeiro/contas-receber",
      isOpen: openFinanceiro,
      icon: "ph-fill ph-coins",
      permission: permisoesHelper.temPermisao("contas-receber-visualizar"),
    },
    {
      title: "Caixa",
      path: "/financeiro/caixa",
      isOpen: openFinanceiro,
      icon: "ph-fill ph-desktop",
      permission: permisoesHelper.temPermisao("caixa-visualizar"),
    },
  ];
  const subMenuRelatorios = [
    {
      title: "Integração",
      path: "/relatorios-integracao",
      isOpen: openRelatorios,
      icon: "ph-fill ph-file-plus",
      permission: permisoesHelper.temPermisao("pessoas-visualizar"),
    },
    {
      title: "Estoque",
      path: "/relatorios-estoque",
      isOpen: openRelatorios,
      icon: "ph-fill ph-shopping-cart",
      permission: permisoesHelper.temPermisao("produtos-visualizar"),
    },
    {
      title: "Faturamento",
      path: "/relatorios-faturamento",
      isOpen: openRelatorios,
      icon: "ph-fill ph-wallet",
      permission: permisoesHelper.temPermisao("nf-saida-visualizar"),
    },
    {
      title: "Financeiro",
      path: "/relatorios-financeiro",
      isOpen: openRelatorios,
      icon: "ph-fill ph-money",
      permission: permisoesHelper.temPermisao("caixa-visualizar"),
    },
  ];
  const menu = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: "ph-fill ph-house",
      onClick: () => {
        initialContextMenu();
        history.push("/dashboard");
        if (matches) {
          setOpen(true);
          setOpenCollapse(false);
        }
      },
    },
    {
      title: "Cadastros",
      path: null,
      icon: "ph-fill ph-file-plus",
      onClick: () => {
        handleOpenCollapse(openCadastros, setOpenCadastros);
      },
      isOpen: openCadastros,
      setOpen: setOpenCadastros,
      setOpenCollapse: setOpenCadastros,
      subMenu: subMenuCadastros,
    },
    {
      title: "Compras",
      path: null,
      icon: "ph-fill ph-shopping-cart",
      onClick: () => {
        handleOpenCollapse(openCompras, setOpenCompras);
      },
      isOpen: openCompras,
      setOpen: setOpenCompras,
      setOpenCollapse: setOpenCompras,
      subMenu: subMenuCompras,
    },
    {
      title: "Vendas",
      path: null,
      icon: "ph-fill ph-wallet",
      onClick: () => {
        handleOpenCollapse(openVendas, setOpenVendas);
      },
      isOpen: openVendas,
      setOpen: setOpenVendas,
      setOpenCollapse: setOpenVendas,
      subMenu: subMenuVendas,
    },
    {
      title: "Financeiro",
      path: null,
      icon: "ph-fill ph-money",
      onClick: () => {
        handleOpenCollapse(openFinanceiro, setOpenFinanceiro);
      },
      isOpen: openFinanceiro,
      setOpen: setOpenFinanceiro,
      setOpenCollapse: setOpenFinanceiro,
      subMenu: subMenuFinanceiro,
    },
    {
      title: "Relatórios",
      path: null,
      icon: "ph-bold ph-chart-line-up",
      onClick: () => {
        handleOpenCollapse(openRelatorios, setOpenRelatorios);
      },
      isOpen: openRelatorios,
      setOpen: setOpenRelatorios,
      setOpenCollapse: setOpenRelatorios,
      subMenu: subMenuRelatorios,
    },
    {
      title: "Pdv",
      path: null,
      icon: "ph-fill ph-storefront",
      onClick: () => window.open(store.urlPdv, "_blank"),
    },
  ];

  const handleLogout = async () => {
    notification
      .confirmacao("Sair", "Tem certeza que deseja sair?")
      .then(async (result) => {
        if (result.value === true) {
          setOpenCollapse(false);
          initialCollapses();
          sessionService.deslogar();
        }
      });
  };

  const handleOpenCollapse = (openCollapse, setOpenCollapse) => {
    if (open === false) {
      setOpen(true);
      setOpenCollapse(true);
    }
    setOpenCollapse(!openCollapse);
  };

  const verificarCaminhoMenu = (path) => {
    return (
      (currentPathname === path ||
        currentPathname === `${path}/create` ||
        currentPathname === `${path}/${id}`) &&
      path !== ""
    );
  };

  const openSubMenu = (subMenu, item) => {
    history.push(subMenu.path);
    initialContextMenu();
    if (matches) {
      setOpen(true);
      setOpenCollapse(false);
      return;
    }
    if (openCollapse) item.setOpenCollapse(item.isOpen);
  };

  function retornaMenusPermitidos() {
    const menuPermitidos = menu.filter((menuItem) => {
      if (!menuItem?.subMenu) {
        return menuItem;
      }
      let sectionTitle = null;
      const subMenuPermitidos = menuItem.subMenu.filter((subMenuItem) => {
        if (subMenuItem.permission && subMenuItem.section) {
          if (sectionTitle == null) {
            sectionTitle = subMenuItem.section.title;
            return subMenuItem.permission;
          }
          if (subMenuItem.section.title == sectionTitle) {
            delete subMenuItem.section;
          } else {
            sectionTitle = subMenuItem.section.title;
          }
          return subMenuItem.permission;
        }
        return subMenuItem.permission;
      });
      if (subMenuPermitidos.length) {
        menuItem.subMenu = subMenuPermitidos;
        return menuItem;
      }
    });
    return menuPermitidos;
  }

  const initialContextMenu = () => {
    setOpenCadastros(false);
    setOpenCompras(false);
    setOpenVendas(false);
    setOpenFinanceiro(false);
    setOpenRelatorios(false);
  };

  return (
    <Drawer
      color="secondary"
      className={clsx("drwPaper", !open && "drwPaperClose")}
      variant="permanent"
      classes={{
        paper: clsx("drawerPaper", !open && "drawerPaperClose"),
      }}
      open={open}
    >
      <Box className="d-flex flex-wrap justify-content-center align-items-center flex-column w-100 py-3 mainLogo">
        {!open && !matches ? (
          <Collapse in={open} orientation="horizontal" collapsedSize={43}>
            <img src={logoHorizontal} className="mainLogo-icon" />
          </Collapse>
        ) : (
          <img src={logoHorizontal} className="mainLogo-icon" />
        )}
      </Box>
      <List className="px-3" style={{ marginBottom: 84 }}>
        {retornaMenusPermitidos().map((item) => (
          <div key={shortid.generate()}>
            <Tooltip title={item.title} arrow placement="left">
              <ListItemButton
                className={`center itens listItem p-2 my-1`}
                onClick={item.onClick}
              >
                <ListItemIcon className="center">
                  <i
                    className={`${item.icon} ${
                      currentPathname === item.path
                        ? "listItemActive-icon"
                        : "listItem-icon"
                    }`}
                    style={{
                      fontSize: 20,
                    }}
                  ></i>
                </ListItemIcon>
                {open && (
                  <>
                    <ListItemText
                      className={`listItem-text ${
                        currentPathname === item.path
                          ? "listItemActive-text"
                          : "listItem-text"
                      }`}
                      primary={item.title}
                      disableTypography
                    />
                    {item.subMenu && (
                      <ListItemIcon className="center">
                        <i
                          className="ph-bold ph-caret-down"
                          style={{
                            fontSize: 15.5,
                            rotate: item.isOpen ? "180deg" : "0deg",
                            transition: "all 0.3s ease-in-out",
                          }}
                        ></i>
                      </ListItemIcon>
                    )}
                  </>
                )}
              </ListItemButton>
            </Tooltip>
            {item.subMenu &&
              item.subMenu.map((subMenuItem) => (
                <Collapse
                  key={shortid.generate()}
                  inn={subMenuItem.isOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <div className="my-2">
                    {subMenuItem?.section?.title && (
                      <ListItem className="my-2">
                        <Typography
                          style={{ paddingLeft: 20 }}
                          className="subtitle"
                          variant="overline"
                        >
                          {subMenuItem?.section?.title}
                        </Typography>
                      </ListItem>
                    )}
                    <ListItemButton
                      className={`center itens listSubItem ${
                        verificarCaminhoMenu(subMenuItem.path)
                          ? "listItemActive-open"
                          : ""
                      }`}
                      onClick={() => openSubMenu(subMenuItem, item)}
                    >
                      <ListItemIcon className="center">
                        <i
                          className={`${subMenuItem.icon} ${
                            verificarCaminhoMenu(subMenuItem.path)
                              ? "listItemActive-icon"
                              : "listItem-icon"
                          }`}
                        ></i>
                      </ListItemIcon>
                      <ListItemText
                        className={`listSubItem-text`}
                        primary={subMenuItem.title}
                        disableTypography
                      />
                    </ListItemButton>
                  </div>
                </Collapse>
              ))}
          </div>
        ))}
      </List>
      <Box
        className={`${clsx(
          "drwPaper-open",
          !open && "drwPaperClose-exit"
        )} p-3 bg-white`}
      >
        <ListItemButton
          className={`center itens listItem-exit p-2 my-1`}
          onClick={() => {
            handleLogout();
          }}
        >
          <ListItemIcon className="center">
            <i
              className={`ph-bold ph-sign-out listItem-icon`}
              style={{
                fontSize: 20,
                color: "white",
              }}
            ></i>
          </ListItemIcon>
          {open && (
            <>
              <ListItemText
                className={`listItem-text`}
                primary="Sair"
                sx={{
                  color: "white",
                }}
                disableTypography
              />
            </>
          )}
        </ListItemButton>
      </Box>
    </Drawer>
  );
};
