import { DialogActions as DialogActionsMui } from "@mui/material";

const DialogActions = ({
  children,
  classes,
  disableSpacing,
  sx,
  className,
}) => {
  return (
    <DialogActionsMui
      className={className}
      classes={classes}
      disableSpacing={disableSpacing ?? false}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        padding: "1rem 0.5rem !important",
        ...sx,
      }}
    >
      {children}
    </DialogActionsMui>
  );
};

export default DialogActions;
