import { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import shortid from "shortid";
import {
  Autocomplete,
  CurrencyInput,
  DatePicker,
  Grid,
  TextField,
  FormControl,
  Paper,
  Tabs,
  Tab,
  FormControlLabel,
  Switch,
  TabPanel,
  Button,
} from "../../../components";
import { AddLayout } from "../../../layouts";
import {
  history,
  notification,
  ContasPagarService,
  PessoaService,
  //PlanosFinanceirosService,
  //CentroCustoService,
  BancosCaixasService,
  MeioPagamentoService,
  ContasPagarObservacoesService,
  CaixaService,
  UsuarioService,
  SessionService,
  ColaboradorService,
} from "../../../services";
import { value, InputErros, PermisoesHelper } from "../../../helpers";
import {
  userLogs,
  formatDate,
  responseErros,
  formatPrice,
  timeoutBuscaAutocomplete,
  unformatPrice,
  voltar,
} from "../../../utils";
import {
  DadosPagamento,
  Observacoes,
  ButtonMenu,
  DesdobrarTitulo,
  ReciboPrintLayout,
  MenuAddActions,
} from "./components";
import {
  tabProps,
  calcularValorTotal,
  calcularValorTotalAtualizado,
  calcularPagamentosParciais,
} from "./add-conta-pagar";
import contaPagarValidator from "./middlewares/contaPagar.validator";
import { useImportContext } from "../../../contexts/import.context";
import { ContaPagar } from "./entities";

const contasPagarService = new ContasPagarService();
const pessoaService = new PessoaService();
const usuarioService = new UsuarioService();
//const planoFinanceiroService = new PlanosFinanceirosService();
//const centroCustoService = new CentroCustoService();
const bancosCaixasService = new BancosCaixasService();
const meioPagamentoService = new MeioPagamentoService();
const contasPagarObservacoesService = new ContasPagarObservacoesService();
const caixaService = new CaixaService();
const sessionService = new SessionService();
const colaboradorService = new ColaboradorService();

const AddContaPagar = ({ match }) => {
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("contas-pagar-visualizar");
  const { id } = match.params;
  const { initialCollapses } = useImportContext();
  const inputRef = useRef();
  const printComponentRef = useRef(null);
  const [reciboData, setReciboData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [abaValue, setAbaValue] = useState(0);
  const [contaPagar, setContaPagar] = useState({
    pago: false,
    dataLancamento: new Date().toISOString(),
  });
  const [pagamento, setPagamento] = useState({});
  const [recebedorList, setRecebedorList] = useState([]);
  //const [planoFinanceiroList, setPlanoFinanceiroList] = useState([]);
  //const [centroCustoList, setCentroCustoList] = useState([]);
  const [meioPagamento, setMeioPagamento] = useState({});
  const [contaList, setContaList] = useState([]);
  const [observacao, setObservacao] = useState("");
  const [observacaoList, setObservacaoList] = useState([]);
  const [meioPagamentoList, setMeioPagamentoList] = useState([]);
  const [dadosPagamentoList, setDadosPagamentoList] = useState([]);
  const [inputErro, setInputErro] = useState([]);
  const [userLog, setUserLog] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [colaborador, setColaborador] = useState({});
  const dadosUsuario = sessionService.getUsuario();
  const isMenuOpen = Boolean(anchorEl);
  const menuId = "primary-add-actions-menu";
  const inputErros = new InputErros(inputErro, setInputErro);

  async function buscarDadosIniciais() {
    await buscaContaPagar();
    await buscarPessoaVendedor();
    buscarContas();
    buscaMeiosPagamento();
  }

  useEffect(() => {
    buscarDadosIniciais();
  }, []);

  async function buscarPessoaVendedor(filtros) {
    const filtro = {
      ...filtros,
      nonPaginated: true,
      vendedor: true,
    };
    const result = await colaboradorService.getAll(filtro);
    if (!result.isAxiosError) {
      const colaboradorVendedorUsuario = result.data.find(
        (colaborador) => colaborador.usuarioId === dadosUsuario.id
      );
      const permissaoCaixaColaborador = await permissoesHelper.temPermisao(
        "utilizar-caixa-colaborador"
      );
      if (colaboradorVendedorUsuario && permissaoCaixaColaborador) {
        setPagamento((prevState) => ({
          ...prevState,
          bancoCaixaId: colaboradorVendedorUsuario.bancoCaixaId,
        }));
        setColaborador(colaboradorVendedorUsuario);
      }
    } else {
      responseErros(result);
    }
  }

  const buscaContaPagar = async () => {
    if (!id) return;
    setLoading(true);
    const result = await contasPagarService.getById(id);
    if (!result.isAxiosError) {
      if (!result.data) {
        initialCollapses();
        voltar();
        return;
      }
      buscarRecebedor({ id: result.data.pessoaId });
      setDadosContasPagar(result.data);
      setDadosPagamentoList(
        result.data.pagamentos.map((pagamento) => ({
          ...pagamento,
          horaPagamento: pagamento.dataPagamento,
        }))
      );
      setObservacaoList(result.data.observacaoList);
      const listaIdUsuario = result.data.observacaoList.map(
        (observacao) => observacao.insertUserId
      );
      buscaUsuario(listaIdUsuario);
      buscaMeioPagamentoById(result.data.meioPagamentoId);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  async function onClickLog() {
    if ("userCreatedLog" in userLog) return;
    const logs = await userLogs(contaPagar);
    setUserLog(logs);
  }

  const buscaUsuario = async (listaIdUsuario) => {
    if (listaIdUsuario.length) {
      const filtro = {
        nonPaginated: true,
        id: listaIdUsuario,
      };
      usuarioService.getAll(filtro).then((result) => {
        if (!result.isAxiosError) {
          setObservacaoList((prevState) => {
            const observacaoAlterada = prevState.map((observacaoItem) => {
              const usuario = result.data.find(
                (item) => item.id == observacaoItem.insertUserId
              );
              observacaoItem.insertUserId = usuario.nome;
              return observacaoItem;
            });
            return observacaoAlterada;
          });
        } else {
          responseErros(result);
        }
      });
    }
  };

  const buscarRecebedor = async (filtros) => {
    const filtro = {
      ...filtros,
      nonPaginated: true,
      ativado: true,
    };
    pessoaService.getAll(filtro).then((res) => {
      if (!res.isAxiosError) {
        setRecebedorList(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  /* const buscaPlanosFinanceiros = async function () {
    const filtros = {
      nonPaginated: true,
    };
    const result = await planoFinanceiroService.getAll(filtros);
    if (!result.isAxiosError) {
      setPlanoFinanceiroList(result.data);
    } else {
      responseErros(result);
    }
  }; */

  /* const buscaCentrosCusto = async function () {
    const filtros = {
      nonPaginated: true,
    };
    const result = await centroCustoService.getAll(filtros);
    if (!result.isAxiosError) {
      setCentroCustoList(result.data);
    } else {
      responseErros(result);
    }
  }; */

  const buscarContas = async function () {
    const filtros = {
      nonPaginated: true,
      ativado: true,
    };
    const result = await bancosCaixasService.getAll(filtros);
    if (!result.isAxiosError) {
      setContaList(result.data);
    } else {
      responseErros(result);
    }
  };

  const buscaMeiosPagamento = async function () {
    const filtros = {
      nonPaginated: true,
    };
    const result = await meioPagamentoService.getAll(filtros);
    if (!result.isAxiosError) {
      setMeioPagamentoList(result.data);
    } else {
      responseErros(result);
    }
  };

  async function buscaMeioPagamentoById(id) {
    meioPagamentoService.getById(id).then((result) => {
      if (!result.isAxiosError) {
        setMeioPagamento(result.data);
      } else {
        responseErros(result);
      }
    });
  }

  function setDadosContasPagar(contaPagar) {
    setContaPagar({
      ...contaPagar,
      pagamentosParciais: calcularPagamentosParciais(contaPagar.pagamentos),
      valorTotal: calcularValorTotal(contaPagar),
      saldoPagar: calcularValorTotalAtualizado(contaPagar),
    });
    setPagamento({
      dataPagamento: new Date(),
      tipoBaixa: "BAIXA_NORMAL",
      valor: calcularValorTotalAtualizado(contaPagar),
    });
  }

  const onBlurInput = (event) => {
    const contaPagarTemp = {
      ...contaPagar,
      [event.target.name]: unformatPrice(event.target.value),
    };
    contaPagarTemp.valorTotal = calcularValorTotal(contaPagarTemp);
    contaPagarTemp.saldoPagar =
      calcularValorTotal(contaPagarTemp) -
      parseFloat(contaPagarTemp.pagamentosParciais ?? 0);
    setContaPagar(contaPagarTemp);
  };

  const onChangeInput = (event) => {
    setContaPagar({
      ...contaPagar,
      [event.target.name]: event.target.value,
    });
  };

  const onInputChangeRecebedor = (event) => {
    if (event.key === "Enter" && inputRef.current === document.activeElement) {
      const eventValue = event.target?.value;
      if (eventValue?.length >= 2) {
        const filtros = {
          nomeRazaoSocial: eventValue,
          apelidoFantasia: eventValue,
        };
        timeoutBuscaAutocomplete(buscarRecebedor(filtros));
      }
    }
  };

  const handleDateChange = (date, fieldName) => {
    if (!date) {
      date = "Invalid Date";
    }
    setContaPagar((prevState) => ({
      ...prevState,
      [fieldName]: date != "Invalid Date" ? formatDate.toSend(date) : null,
    }));
  };

  const onChangeAutocomplete = (name, value) => {
    setContaPagar({
      ...contaPagar,
      [name]: value ? value.id : undefined,
    });
  };

  const handleChangeObservacao = (e) => {
    setObservacao(e.target.value);
  };

  const handleObservacao = async (event) => {
    event.preventDefault();
    if (id) {
      cadastrarObservacao(id);
    } else {
      setObservacaoList((prevState) => [
        ...prevState,
        {
          id: shortid.generate(),
          observacao,
        },
      ]);
      setObservacao("");
    }
  };

  const cadastrarObservacao = async (id) => {
    setLoading(true);
    const body = {
      observacao,
      contasPagarId: id,
    };
    const res = await contasPagarObservacoesService.cadastrar(body);
    setLoading(false);
    if (!res.isAxiosError) {
      setObservacao("");
      buscarObservacoes(id);
      notification.cadastroSucesso();
    } else {
      responseErros(res);
    }
  };

  const buscarObservacoes = (contasPagarId) => {
    const filter = {
      nonPaginated: true,
      contasPagarId,
    };
    contasPagarObservacoesService.getAll(filter).then((res) => {
      if (!res.isAxiosError) {
        setObservacaoList(res.data);
        const listaIdUsuario = res.data.map(
          (observacao) => observacao.insertUserId
        );
        buscaUsuario(listaIdUsuario);
      } else {
        responseErros(res);
      }
    });
  };

  const removerObservacao = async (id) => {
    setLoading(true);
    const res = await contasPagarObservacoesService.deletar(id);
    setLoading(false);
    if (!res.isAxiosError) {
      const data = observacaoList.filter((item) => item.id !== id);
      setObservacaoList(data);
      notification.deletadoSucesso();
    } else {
      responseErros(res);
    }
  };

  const onClickSalvar = (indiceSelecionado) => {
    const body = {
      ...contaPagar,
      observacaoList,
      bancoCaixaId: meioPagamento?.bancoCaixaId || contaPagar?.bancoCaixaId,
      pagamentos: dadosPagamentoList,
    };
    validarContaPagar(body, indiceSelecionado);
  };

  const validarStatusCaixa = async (dataPagamento, bancoCaixaId) => {
    const result = await caixaService.caixaAberto(dataPagamento, bancoCaixaId);
    if (!result.isAxiosError) {
      return result.data;
    } else {
      responseErros(result);
    }
  };

  const validarContaPagar = (body, indiceSelecionado) => {
    setInputErro([]);
    contaPagarValidator
      .validate(body, { abortEarly: false })
      .then(async () => {
        const dadosContaPagar = new ContaPagar(body, colaborador);
        if (id) {
          atualizarContaPagar(id, dadosContaPagar);
        } else {
          if (dadosContaPagar.baixaAposInclusao) {
            const caixaAberto = await validarStatusCaixa(
              dadosContaPagar.dataLancamento,
              dadosContaPagar.bancoCaixaId
            );
            if (!caixaAberto) {
              const caixaSelecionado = contaList.find(
                (conta) => conta.id === dadosContaPagar?.bancoCaixaId
              );
              if (caixaSelecionado?.aberturaAutomatica) {
                await cadastrarCaixa(
                  new Date(),
                  dadosContaPagar?.bancoCaixaId,
                  true
                );
              } else return notification.alertaGenericos("Caixa Fechado!");
            }
            salvarContasPagar(dadosContaPagar, indiceSelecionado);
          } else {
            salvarContasPagar(dadosContaPagar, indiceSelecionado);
          }
        }
      })
      .catch((err) => {
        inputErros.set(err);
      });
  };

  const salvarContasPagar = async (dadosContaPagar, indiceSelecionado) => {
    const fornecedor = recebedorList.find(
      (item) => item.id == contaPagar.pessoaId
    );
    setLoading(true);
    const res = await contasPagarService.cadastrar(dadosContaPagar);
    setLoading(false);
    if (!res.isAxiosError) {
      if (id || contaPagar.baixaAposInclusao) {
        setReciboData({
          fornecedor,
          titulo: res.data,
          pagamentos: res.data?.pagamentos ? [res.data.pagamentos[0]] : [],
        });
        handlePrint();
      }
      notification.cadastroSucesso();
      if (indiceSelecionado === 0) {
        history.push("/financeiro/contas-pagar");
      } else {
        limparFormularios();
      }
    } else {
      responseErros(res);
    }
  };

  const atualizarContaPagar = async (idContaPagar, dadosContaPagar) => {
    setLoading(true);
    delete dadosContaPagar.observacaoList;
    const res = await contasPagarService.atualizar(
      idContaPagar,
      dadosContaPagar
    );
    if (!res.isAxiosError) {
      notification.alteracaoSucesso();
      history.push("/financeiro/contas-pagar");
    } else {
      const error = res.response ? res.response.data.error : res.message;
      notification.erroValidacao(error);
    }
    setLoading(false);
  };

  const cadastrarCaixa = async (dataCadastro, bancoCaixaId, aberto) => {
    setLoading(true);
    const body = {
      data: dataCadastro,
      bancoCaixaId,
      aberto,
    };
    const result = await caixaService.cadastrar(body);
    if (result.isAxiosError) {
      setLoading(false);
      responseErros(result);
    }
  };

  const handleChangeSwitch = (event) => {
    setContaPagar({
      ...contaPagar,
      [event.target.name]: event.target.checked,
    });
  };

  const limparFormularios = () => {
    setAbaValue(0);
    setInputErro([]);
    setContaPagar({});
  };

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    pageStyle: `
      @page {
        size: auto;
        margin: 8mm;
      }
    `,
  });

  const handleAddActionsOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <AddLayout
      id={id}
      title={
        contaPagar?.id ? "Editar Conta a Pagar" : "Cadastrar Conta a Pagar"
      }
      onClickSalvar={onClickSalvar}
      loading={loading}
      userLog={contaPagar?.id ? userLog : null}
      onClickLog={onClickLog}
      codigo={id ? `Nº Título - ${contaPagar.numeroTitulo}` : null}
      showUpdate={permissoesHelper.temPermisao("contas-pagar-editar")}
      ButtonMenu={
        <ButtonMenu
          contaPagar={contaPagar}
          setContaPagar={setContaPagar}
          handleChangeSwitch={handleChangeSwitch}
        />
      }
      actions={
        <div>
          {!contaPagar?.id ? (
            <FormControlLabel
              control={
                <Switch
                  id="baixaAposInclusao"
                  name="baixaAposInclusao"
                  checked={
                    contaPagar?.baixaAposInclusao
                      ? contaPagar?.baixaAposInclusao
                      : false
                  }
                  onChange={handleChangeSwitch}
                  color="primary"
                />
              }
              label={`Baixa após inclusão`}
            />
          ) : (
            <Button
              aria-label="add action caixa"
              aria-controls={menuId}
              aria-haspopup="true"
              aria-expanded={isMenuOpen ? "true" : "false"}
              onClick={handleAddActionsOpen}
              color="inherit"
              style={{
                borderRadius: 5,
                backgroundColor: "#E5E5E5",
              }}
            >
              <span style={{ fontSize: 10 }}>mais ações</span>
            </Button>
          )}
        </div>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              required
              id="pessoaId"
              name="pessoaId"
              options={recebedorList}
              autoHighlight
              disabled={contaPagar?.id ? true : false}
              getOptionLabel={(option) =>
                option.nomeRazaoSocial
                  ? `${option.id}` + ` - ` + `${option.nomeRazaoSocial}`
                  : ""
              }
              getOptionSelected={(option, value) =>
                option?.nomeRazaoSocial === value?.nomeRazaoSocial
              }
              noOptionsText="Digite e pressione Enter para buscar"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Recebedor"
                  required
                  onKeyDown={onInputChangeRecebedor}
                  inputRef={inputRef}
                  variant={contaPagar?.id ? "filled" : "outlined"}
                  error={inputErros.get("pessoaId")}
                />
              )}
              onChange={(_, newValue) => {
                onChangeAutocomplete("pessoaId", newValue);
              }}
              value={value.autoComplete(recebedorList, contaPagar?.pessoaId)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="numeroTitulo"
            label="Nº Título"
            name="numeroTitulo"
            variant="filled"
            margin="normal"
            disabled
            value={value.text(contaPagar?.numeroTitulo)}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="origem"
            name="origem"
            label="Origem"
            variant="filled"
            margin="normal"
            disabled
            value={value.text(contaPagar?.origem)}
            onChange={onChangeInput}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <DatePicker
            id="dataLancamento"
            name="dataLancamento"
            label="Data de Lançamento"
            format="dd/MM/yyyy"
            variant="filled"
            margin
            disabled={true}
            value={
              contaPagar?.dataLancamento ? contaPagar.dataLancamento : null
            }
          />
        </Grid>
        <Grid item xs={2}>
          <DatePicker
            id="dataVencimento"
            name="dataVencimento"
            label="Data de Vencimento"
            format="dd/MM/yyyy"
            variant={
              id &&
              contaPagar?.origem !== "MANUAL" &&
              contaPagar?.status !== "ABERTO"
                ? "filled"
                : "outlined"
            }
            disabled={
              id &&
              contaPagar?.origem !== "MANUAL" &&
              contaPagar?.status !== "ABERTO"
                ? true
                : false
            }
            margin
            value={
              contaPagar?.dataVencimento ? contaPagar.dataVencimento : null
            }
            onChange={(date) => {
              setInputErro([]);
              handleDateChange(date, "dataVencimento");
            }}
            required
            error={inputErros.get("dataVencimento")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="meioPagamento"
              name="meioPagamento"
              options={meioPagamentoList}
              autoHighlight
              required
              getOptionLabel={(option) =>
                option.descricao
                  ? `${option.id}` + ` - ` + `${option.descricao}`
                  : ""
              }
              noOptionsText="Sem opções"
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Meio de Pagamento"
                  variant="outlined"
                  error={inputErros.get("meioPagamentoId")}
                />
              )}
              onChange={(_, newValue) => {
                onChangeAutocomplete("meioPagamentoId", newValue);
                setMeioPagamento(newValue);
              }}
              value={value.autoComplete(
                meioPagamentoList,
                contaPagar?.meioPagamentoId
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="valor"
            name="valor"
            label="Valor Bruto (R$)"
            variant={
              id &&
              contaPagar?.origem !== "MANUAL" &&
              contaPagar?.status !== "ABERTO"
                ? "filled"
                : "outlined"
            }
            disabled={
              id &&
              contaPagar?.origem !== "MANUAL" &&
              contaPagar?.status !== "ABERTO"
                ? true
                : false
            }
            value={contaPagar?.valor ? contaPagar.valor : ""}
            onBlur={onBlurInput}
            fullWidth
            required
            error={inputErros.get("valor")}
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="acrescimo"
            name="acrescimo"
            label="Acréscimo (R$)"
            variant={id ? "filled" : "outlined"}
            disabled={id ? true : false}
            value={contaPagar?.acrescimo ?? "0"}
            onBlur={onBlurInput}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="desconto"
            name="desconto"
            label="Desconto (R$)"
            variant={id ? "filled" : "outlined"}
            disabled={id ? true : false}
            value={contaPagar?.desconto ?? "0"}
            onBlur={onBlurInput}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="pagamentosParciais"
            name="pagamentosParciais"
            label="Pagamento Parcial (R$)"
            variant="filled"
            value={contaPagar?.pagamentosParciais ?? "0"}
            fullWidth
            disabled
          />
        </Grid>
      </Grid>
      {/* <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              required
              id="planoFinanceiroId"
              name="planoFinanceiroId"
              options={planoFinanceiroList}
              autoHighlight
              getOptionLabel={(option) =>
                option.descricao
                  ? `${option.id}` + ` - ` + `${option.descricao}`
                  : ""
              }
              getOptionSelected={(option, value) =>
                option.descricao === value.descricao
              }
              noOptionsText="Sem opções"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Plano Financeiro"
                  variant="outlined"
                  error={inputErros.get("planoFinanceiroId")}
                />
              )}
              onChange={(_, newValue) => {
                onChangeAutocomplete("planoFinanceiroId", newValue);
              }}
              value={value.autoComplete(
                planoFinanceiroList,
                contaPagar.planoFinanceiroId
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              required
              id="centroCustoId"
              name="centroCustoId"
              options={centroCustoList}
              autoHighlight
              getOptionLabel={(option) =>
                option.descricao
                  ? `${option.id}` + ` - ` + `${option.descricao}`
                  : ""
              }
              getOptionSelected={(option, value) =>
                option.descricao === value.descricao
              }
              noOptionsText="Sem opções"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Centro Custo"
                  variant="outlined"
                  error={inputErros.get("centroCustoId")}
                />
              )}
              onChange={(e, newValue) => {
                e.persist();
                onChangeAutocomplete("centroCustoId", newValue);
              }}
              value={value.autoComplete(
                centroCustoList,
                contaPagar.centroCustoId
              )}
            />
          </FormControl>
        </Grid>
      </Grid> */}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="historico"
            name="historico"
            label="Histórico"
            variant="outlined"
            margin="normal"
            value={contaPagar?.historico ? contaPagar.historico : ""}
            onChange={onChangeInput}
            fullWidth
          />
        </Grid>
        <Grid item xs={3} className="mt-3">
          <Paper
            className="py-2 px-2 text-white text-center"
            elevation={4}
            sx={{ backgroundColor: "#2196F3" }}
          >
            Valor Total
            <br />
            <span className="fs-6">
              {formatPrice(parseFloat(contaPagar?.valorTotal) || 0)}
            </span>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <CurrencyInput
            id="saldoPagar"
            name="saldoPagar"
            label="Saldo a Pagar (R$)"
            variant="filled"
            value={value.text(contaPagar?.saldoPagar) || "0"}
            fullWidth
            disabled
          />
        </Grid>
      </Grid>
      {!id && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DesdobrarTitulo
              contaPagar={contaPagar}
              meioPagamento={meioPagamento}
              dadosPagamentoList={dadosPagamentoList}
              observacaoList={observacaoList}
              inputErros={inputErros}
              setInputErro={setInputErro}
            />
          </Grid>
        </Grid>
      )}
      {contaPagar?.id && (
        <Grid container spacing={2} className="mt-3">
          <Grid item xs={12}>
            <Paper>
              <Tabs
                value={abaValue}
                onChange={(e, value) => {
                  e.persist();
                  setAbaValue(value);
                }}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
              >
                <Tab label="DADOS DO PAGAMENTO" {...tabProps(0)} />
                <Tab label="OBSERVAÇÕES" {...tabProps(1)} />
              </Tabs>
            </Paper>
            <TabPanel value={abaValue} index={0}>
              <DadosPagamento
                id={id}
                contaPagar={contaPagar}
                setContaPagar={setContaPagar}
                setDadosContasPagar={setDadosContasPagar}
                pagamento={pagamento}
                setPagamento={setPagamento}
                recebedorList={recebedorList}
                contaList={contaList}
                meioPagamentoList={meioPagamentoList}
                dadosPagamentoList={dadosPagamentoList}
                setDadosPagamentoList={setDadosPagamentoList}
                setLoading={setLoading}
                validarStatusCaixa={validarStatusCaixa}
                handlePrint={handlePrint}
                setReciboData={setReciboData}
                calcularValorTotalAtualizado={calcularValorTotalAtualizado}
                cadastrarCaixa={cadastrarCaixa}
                colaborador={colaborador}
              />
            </TabPanel>
            <TabPanel value={abaValue} index={1}>
              <Observacoes
                observacao={observacao}
                handleChangeObservacao={handleChangeObservacao}
                handleObservacao={handleObservacao}
                removerObservacao={removerObservacao}
                observacaoList={observacaoList}
              />
            </TabPanel>
          </Grid>
        </Grid>
      )}
      <div style={{ display: "none" }}>
        <div ref={printComponentRef}>
          <ReciboPrintLayout data={reciboData} />
        </div>
      </div>
      <MenuAddActions
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        conta={contaPagar}
        pagamento={pagamento}
        listaMeiosPagamentos={meioPagamentoList}
        dadosPagamentoList={dadosPagamentoList}
        listaContas={contaList}
        recebedorList={recebedorList}
        setReciboData={setReciboData}
        handlePrint={handlePrint}
      />
    </AddLayout>
  );
};

export default AddContaPagar;
