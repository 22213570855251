import { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
} from "../../../../components";
import { SessionService } from "../../../../services";
import {
  formatCpf,
  formatCnpj,
  formatPrice,
  formatDate,
} from "../../../../utils";
import { totalPagamentos } from "../add-conta-pagar";

const ReciboPrint = ({ data }) => {
  const [empresa, setEmpresa] = useState(null);
  const sessionService = new SessionService();

  useEffect(() => {
    const data = sessionService.getEmpresa();
    setEmpresa(data);
  }, []);

  function formatarTipoTitulo(tipoTitulo) {
    const tipo = tipoTitulo.split("_");
    return (
      <>
        {tipo[0]}
        <br />
        {tipo[1]}
        <br />
      </>
    );
  }

  function retornarCnpjCpf(dadosFornecedor) {
    if (dadosFornecedor?.cnpjCpf) {
      return dadosFornecedor?.naturezaSocial == "fisica"
        ? `, identificado pelo CPF ${formatCpf(dadosFornecedor.cnpjCpf)}`
        : `, identificado pelo CNPJ ${formatCnpj(dadosFornecedor.cnpjCpf)}`;
    }
  }

  return data?.titulo && data?.pagamentos.length ? (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h4 style={{ textAlign: "center", fontWeight: "bold", marginBottom: 16 }}>
        RECIBO
      </h4>
      <Box
        style={{ marginBottom: 16 }}
        className={`d-flex justify-content-center`}
      >
        <h6 style={{ textAlign: "center" }}>
          Pagamos a {data?.fornecedor?.nomeRazaoSocial}
          {retornarCnpjCpf(data?.fornecedor)}, a importância de{" "}
          {formatPrice(totalPagamentos(data.pagamentos))}, referente ao(s)
          seguinte(s) título(s):
        </h6>
      </Box>
      <Table size="small" style={{ marginTop: 16 }}>
        <TableHead>
          <TableRow>
            <TableCell variant="head">Título</TableCell>
            <TableCell variant="head">A Pagar</TableCell>
            <TableCell variant="head">Tipo</TableCell>
            <TableCell variant="head">Dt. Venc.</TableCell>
            <TableCell variant="head">Multa</TableCell>
            <TableCell variant="head">Juros</TableCell>
            <TableCell variant="head">Desc.</TableCell>
            <TableCell variant="head">Pago</TableCell>
            <TableCell variant="head">Dt. Pgto</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.pagamentos.map((pagamento, index) => {
            return (
              <TableRow key={index}>
                <TableCell>{index}</TableCell>
                <TableCell>
                  {formatPrice(
                    (pagamento?.valorTitulo || 0) - (pagamento?.valor || 0)
                  )}
                </TableCell>
                <TableCell>{formatarTipoTitulo(data.titulo.tipo)}</TableCell>
                <TableCell>
                  {formatDate
                    .received(data.titulo.dataVencimento)
                    .toLocaleDateString("pt-BR")}
                </TableCell>
                <TableCell>
                  {formatPrice(pagamento?.multa) ?? "R$ 0,00"}
                </TableCell>
                <TableCell>
                  {formatPrice(pagamento?.juros) ?? "R$ 0,00"}
                </TableCell>
                <TableCell>
                  {formatPrice(pagamento?.desconto) ?? "R$ 0,00"}
                </TableCell>
                <TableCell>{formatPrice(pagamento?.valor)}</TableCell>
                <TableCell>
                  {formatDate
                    .received(pagamento?.dataPagamento ?? new Date())
                    .toLocaleString("pt-BR")}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Grid container sx={{ mt: 4 }}>
        <h6>
          {`${empresa.enderecoCidade} - ${empresa.enderecoUF}, ${formatDate
            .received(
              data.pagamentos.length == 1
                ? data.pagamentos[0]?.dataPagamento
                : new Date()
            )
            .toLocaleDateString("pt-BR", {
              dateStyle: "full",
            })}`}
        </h6>
        <h6>{empresa?.cnpj && `CPF/CNPJ ${formatCnpj(empresa.cnpj)}`}</h6>
      </Grid>
    </Box>
  ) : null;
};

export default ReciboPrint;
